const prepareInfo = (info) => {
    const { id, password, coordinates, settings, custom_fields } = info;
    const result = {
        id,
        password,
        coordinates,
        settings,
        custom_fields,
    };
    // console.log({result})
    return result;
};

export default prepareInfo;
