import Honkio from "../Honkio";

const getShopList = (options = {}, errorHandler = {}) => {
    const {
        merchant: query_merchant,
        page = 0,
        query_count = 0,
        appid,
        debug,
        shopId,
    } = options;
    return Honkio().mainShop.userFetch(
        "shoplist",
        {
            debug,
            appid,
            query_merchant,
            query_skip: +page * query_count,
            query_count,
        },
        errorHandler
    );
};

export default getShopList;
