import React from 'react'
import { useFormContext } from 'react-hook-form';

const UploadFileWithPreview = ({ name, className, type, error, disabled, k ,id}) => {
  const { register, watch } = useFormContext();
  return (
    // <butoon className="btn btn-outline-secondary" onClick={}>
    //     Upload Image 
    //   </butoon>

      <a className="btn btn-primary me-2" id="documentsTip" href={`/asset/documents`}>Upload Image for {id}</a>
  );
};

export default UploadFileWithPreview;
