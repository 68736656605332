import Honkio from '../Honkio';

const getScripts = (options = {}, errorHandler = {}) => {
  const { appid, id, debug } = options;
  return Honkio().mainShop.userFetch('merchantreportlist', {
    debug,
    appid,
    id,
  }, errorHandler);
};

export default getScripts;
