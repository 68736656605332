import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from 'react';
import getMerchantInvoices from '../api/utils/getMerchantInvoices';
import useSearchString from '../utils/useSearchString';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Wait from './UI/Wait';

const PaymentsTable = () => {
    const { merchant = null, merchant_name = '' } = useSearchString();
    const pageTitle = `Payments ${merchant_name ? `(${merchant_name})` : ''}`
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        if (merchant) {
            setLoading(true);
            fetchPayments();
        }
    }, [merchant]);

    const fetchPayments = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept' && data.payment_invoices) {
                    setPayments(data.payment_invoices)
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPayments([])
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setPayments([])
            setLoading(false);
        }

        getMerchantInvoices({ merchant }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    }

    const columns = useMemo(
        () => [
            {
                name: '#',
                cell: (_, index) => index + 1,
                grow: 0,
                autoWidth: true,
            },
            {
                name: 'ID',
                selector: 'id',
                sortable: true,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: true,
            },
            {
                name: 'Due date',
                selector: 'due_date',
                sortable: true,
            },
            {
                name: 'Amount',
                selector: 'amount',
                sortable: true,
            },
        ],
        []
    );

    return (<div>
        <NotificationContainer />
        <h2>{pageTitle}</h2>
        <DataTable
            noHeader
            dense
            striped
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            data={payments}
            columns={columns}
        />
    </div>
    );
};

export default PaymentsTable;
