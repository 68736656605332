import { useEffect, useState, useContext } from "react";
import SidebarLink from "./SidebarLink";
import { useSelector } from "react-redux";

const Sidebar = ({ config }) => {
    const { roles, selectedRole } = useSelector((state) => state.roles);
    const [role, setRole] = useState(null);

    useEffect(() => {
        if (selectedRole && roles && roles?.length) {
            const foundRole = roles.find((item) => item?.id == selectedRole);
            setRole(foundRole);
        }
    }, [selectedRole, roles]);

    return (
        <nav className="nav flex-column nav-pills sticky-top p-3 shadow-sm">
            <SidebarLink
                to="/pointsOfSales"
                title="Points of sales"
            ></SidebarLink>
            {role?.name === "superadmin" ||
            role?.name === "merchant_admin" ||
            role?.name === "site_manager_carry" ? (
                <SidebarLink to="/merchant/role" title="User groups" />
            ) : null}

            <SidebarLink to="/assets" title="Trailers"></SidebarLink>
            <SidebarLink to="/orders" title="Reservations"></SidebarLink>
            {/* <SidebarLink to='/settings' title="Settings">
                <SidebarLink to="/ordermodel" title="Order models"></SidebarLink>
                <SidebarLink to="/products/" title="Products"></SidebarLink>
                <SidebarLink to="/products/types" title="Product types"></SidebarLink>
                <SidebarLink to="/servicekeys" title="Service keys"></SidebarLink>
                <SidebarLink to="/password" title="Security"></SidebarLink>
                <SidebarLink to="/messages" title="Messages"></SidebarLink>
                <SidebarLink to='/greylists' title="Grey list"></SidebarLink>
                <SidebarLink to='/velocityRules' title="Velocity Rules"></SidebarLink>              
                <SidebarLink to='/dashboard' title="Dashboard"></SidebarLink> */}
        </nav>
    );
};

export default Sidebar;
