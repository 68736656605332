import { useMemo } from "react";
import DataTable from "react-data-table-component";
import Wait from "./../../UI/Wait";
import { getDistance } from "./helpers/distance";

const AssetSearchResultList = ({
    data,
    shops,
    loading,
    emptyText,
    lat,
    lng,
    onReserveClick = () => {},
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
}) => {
    const columns = useMemo(
        () => [
            { name: "Nimi", grow: 2, cell: ({ name }) => name },
            {
                name: "Sijainti",
                grow: 1,
                cell: (e) => {
                    if (shops) {
                        const shop = shops.find(
                            (shp) => shp?._id === e?.properties?.pos
                        );
                        if (!shop) {
                            return "";
                        }

                        return shop.settings.str_name;
                    }
                    return "";
                },
            },
            {
                name: "Et�isyys",
                grow: 1,
                cell: (row) => {
                    const latE = row.coordinates[0] || 0;
                    const lngE = row.coordinates[1] || 0;
                    return `${getDistance(lat, lng, latE, lngE)} km`;
                },
            },
            {
                name: "Toiminto",
                grow: 2,
                right: true,
                cell: (row) => {
                    const latE = row.coordinates[0] || 0;
                    const lngE = row.coordinates[1] || 0;
                    return (
                        <div>
                            {row?.already_reserved ||
                            !row?.properties?.reservable ? (
                                <button className="btn btn-secondary btn-cus m-1">
                                    Ei saatavilla
                                </button>
                            ) : (
                                <button
                                    onClick={() =>
                                        onReserveClick(
                                            row,
                                            getDistance(lat, lng, latE, lngE)
                                        )
                                    }
                                    className="btn btn-outline-primary m-1"
                                >
                                    Varaa nyt
                                </button>
                            )}
                        </div>
                    );
                },
            },
        ],
        [shops, lat, lng, data]
    );

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            progressPending={loading}
            noDataComponent={emptyText}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default AssetSearchResultList;
