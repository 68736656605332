import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import { getAdminOrder } from './../../api/utils/getOrders';
import { NotificationManager, NotificationContainer } from "react-notifications";

const OrderModal = ({ orderId, show, onHide }) => {
    const [order, setOrder] = useState('-');
    const [progress, done] = useLoader([orderId, order]);
    
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setOrder(JSON.stringify(data.order, null, 2))
                }
            } else {
                setOrder('-')
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setOrder('error')
        }

        if (orderId) {
            getAdminOrder({ id: orderId }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
        }
    }, [orderId]);

    const container = <div className="container">
        <div className="col-8">
            <label className='multiline'>
                {order}
            </label>
        </div>
    </div>

    const onClickHide = () => {
        onHide()
    }

    return done ? (
        <Modal
            show={show}
            onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {order ? container : 'not found'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    )
};

export default OrderModal