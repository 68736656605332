import {
    APPLICATION_ID,
    APP_PACKAGE,
    BACKEND_URL,
    MERCHANT_ID,
} from "../config";
import debugOutput from "./helpers/debugOutput";
import nullFilter from "./helpers/nullFilter";
import undefFilter from "./helpers/undefFilter";
import { NotificationManager } from "react-notifications";
import { post } from "axios";
import store from "../store";

const HonkioAPI = function () {
    const defaultHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const options = {
        method: "POST",
        headers: defaultHeader,
    };
    const defaultErrorHandler = console.log;

    const customFetch = async (command, parameters, errorHandler, args) => {
        const state = store.getState();
        const accessToken = state?.auth?.access_token;
        const selectedMerchant = state?.merchant?.selectedMerchant;
        const selectedRole = state?.roles?.selectedRole;

        parameters["role"] = selectedRole;
        parameters["identity_client"] = `${APP_PACKAGE};web;2.0`;
        parameters["version"] = parameters.version || 1;
        parameters["command"] = command;
        parameters["query_application"] = APPLICATION_ID;
        parameters["application"] = APPLICATION_ID;
        parameters["merchant"] = MERCHANT_ID;
        if (selectedMerchant) parameters["merchant"] = selectedMerchant?._id;

        if (parameters != null) {
            if (args?.auth === true) {
                parameters["access_token"] = accessToken;
                if (localStorage.getItem("device") != null) {
                    parameters["identity_plugindata"] =
                        localStorage.getItem("device");
                }
            }
            if (args?.userShop === true) {
                parameters["shop"] = localStorage.getItem("shop");
                parameters["shop_password"] =
                    localStorage.getItem("shop_password");
            }

            options["body"] = JSON.stringify(parameters);
        }

        let file;
        if (
            ["usersetphoto", "merchantsetphoto"].indexOf(command) >= 0 &&
            parameters &&
            parameters.hasOwnProperty("file")
        ) {
            file = parameters.file;
            delete parameters["file"];
        }

        let response = {};
        let data = {};
        if (file) {
            let formData = new FormData();
            formData.append(
                "message",
                new Blob([JSON.stringify(parameters)], {
                    type: "application/json",
                }),
                "message.json"
            );

            formData.append("file", file, file.name);

            options["headers"] = { "content-type": "multipart/form-data" };
            response = await post(
                BACKEND_URL + "/" + command,
                formData,
                options
            ).catch((err) => {
                defaultErrorHandler(err);
            });
            if (!response || !response.data || response.status !== 200) {
                try {
                    if (errorHandler != null) {
                        let errorHandled = errorHandler(response);
                        if (!errorHandled) {
                            defaultErrorHandler(response);
                        }
                    } else {
                        defaultErrorHandler(response);
                    }
                } catch (e) {
                    defaultErrorHandler(response);
                }
            }
            data = response?.data;
        } else {
            try {
                response = await fetch(
                    BACKEND_URL + "/" + command,
                    options
                ).catch((err) => defaultErrorHandler(err));
                data = await response.json();
            } catch (e) {
                console.log(response, command);
            }
            if (!response || !response.ok) {
                NotificationManager.warning("Unknown error", "Error", 5000);
            }
        }
        if (data?.status !== "accept") {
            try {
                if (errorHandler != null) {
                    let errorHandled = errorHandler(data);
                    if (!errorHandled) {
                        defaultErrorHandler(data);
                    }
                } else {
                    defaultErrorHandler(data);
                }
            } catch (e) {
                defaultErrorHandler(data);
            }
        }
        return data;
    };

    const mainShop = {
        basicfetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler);
        },
        userFetch: function (
            command,
            parameters,
            errorHandler,
            undefOnly = false
        ) {
            if (undefOnly) {
                parameters = undefFilter(parameters);
            } else {
                parameters = nullFilter(parameters);
            }
            parameters = debugOutput(parameters);
            return customFetch(command, parameters, errorHandler, {
                auth: true,
            });
        },
    };

    const userShop = {
        basicfetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler, {
                userShop: true,
            });
        },
        userFetch: function (
            command,
            parameters,
            errorHandler,
            undefOnly = false
        ) {
            if (undefOnly) {
                parameters = undefFilter(parameters);
            } else {
                parameters = nullFilter(parameters);
            }
            parameters = debugOutput(parameters);
            return customFetch(command, parameters, errorHandler, {
                auth: true,
                userShop: true,
            });
        },
    };

    return { mainShop, userShop };
};

export default HonkioAPI;
