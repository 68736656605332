import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#00853F",
            // main: '#12ABDB',
        },
        secondary: {
            main: "#8CC63F",
            // main: '#12ABDB',
        },
    },
    props: {
        MuiAppBar: {
            color: "secondary",
        },
    },
});
