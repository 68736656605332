const mapStateToRequest = ({
  length: { min, max },
  characters: { lowercase, uppercase, digits, symbols },
}) => ({
  min_len: +min,
  max_len: +max || +min * 2,
  min_digits: +digits,
  min_upper: +uppercase,
  min_lower: +lowercase || 0,
  min_symbols: +symbols,
});

export default mapStateToRequest;
