import { authUser } from "../../auth/authUser";
import { Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import useSearchString from "../../utils/useSearchString";
import Wait from "../UI/Wait";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoles } from "../../store/actions/rolesActions";

const AuthCheck = () => {
    const { access_token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [authStatus, setAuthStatus] = useState(false);
    const { code } = useSearchString();
    const { signIn } = useContext(AuthContext);

    const fetchAuth = async (code) => {
        try {
            const result = await authUser(code);

            if (result) {
                setAuthStatus(result);
            }
        } catch (error) {
            console.log("Error authUser: ", error);
        }
    };

    const fetchUserRoles = async () => {
        const result = await dispatch(getUserRoles());
    };

    useEffect(() => {
        if (access_token) {
            fetchUserRoles();
        }
    }, [access_token]);

    useEffect(() => {
        fetchAuth(code);
    }, [code]);

    useEffect(() => {
        authStatus && signIn();
    }, [authStatus, signIn]);

    return authStatus ? <Redirect to="/" /> : <Wait />;
};

export default AuthCheck;
