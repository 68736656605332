import { useEffect, useState } from "react";
import ShopInfoForm from "./ShopInfoForm";
import useSearchString from "../../utils/useSearchString";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import prepareToPublish from "./helpers/prepareToPublish";
import getAdminShop from "./../../api/utils/getAdminShop";
import { adminUpdateShop } from "../../api/utils/setAdminShop";
import { MERCHANT_ID } from "../../config";

const ShopInfo = () => {
    const history = useHistory();
    const [shopInfo, setShopInfo] = useState(null);
    const { merchantid = MERCHANT_ID, shop = null } = useSearchString();
    const merchant = MERCHANT_ID;
    const [addressList, setAddressList] = useState();
    const [dataToSave, setDataToSave] = useState();

    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data?.status === "accept" && data?.shop) {
                    setShopInfo(data.shop);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAdminShop({ id: shop }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    }, [shop]);

    const saveShop = async (data) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        try {
            const res = await adminUpdateShop(
                { ...prepareToPublish(merchant, shop, data) },
                errorHandler
            );
            NotificationManager.success("Shop info saved");
            return true;
        } catch (error) {
            console.log(`error in saveShop:`, error);
            NotificationManager.error("Error saving shop info");
            return false;
        }
    };
    return (
        shopInfo && (
            <div>
                <NotificationContainer />
                <ShopInfoForm
                    saveShop={saveShop}
                    shopInfo={shopInfo}
                    merchantId={merchant}
                />
            </div>
        )
    );
};

export default ShopInfo;
