import HonkioAPI from "./../Honkio";

const reservationCheck = (options = {}, errorHandler = {}) => {
    return HonkioAPI().mainShop.userFetch(
        "orderreservationcheck",
        {
            ...options,
        },
        errorHandler
    );
};

export default reservationCheck;
