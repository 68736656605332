import { NotificationManager } from "react-notifications";
import getRoles from "../../api/utils/getRoles";
import { APPLICATION_ID } from "../../config";
import { SET_AUTH_INFO, SET_SELECTED_ROLE, SET_USER_ROLES } from "../types";
import { getAppMerchantsList } from "./appMerchantActions";

export const getUserRoles = () => async (dispatch) => {
    const errorHandler = (error) => {
        NotificationManager.error(error.description, "Error", 4000);
    };
    try {
        const resultHandler = async (data) => {
            let selectedRole = null;
            if (data && data?.status === "accept" && data?.roles) {
                await dispatch({
                    type: SET_USER_ROLES,
                    payload: data?.roles,
                });
                for (const role of data?.roles) {
                    if (role?.name === "superadmin") {
                        selectedRole = role?.id;
                        break;
                    } else if (role?.name === "admin") {
                        selectedRole = role?.id;
                        break;
                    } else if (role?.name) {
                        selectedRole = role?.id;
                        break;
                    } else {
                        NotificationManager.error("Error setting user role");
                    }
                }
                if (selectedRole)
                    await dispatch({
                        type: SET_SELECTED_ROLE,
                        payload: selectedRole,
                    });
            } else {
                NotificationManager.error("Error");
            }
        };

        const result = await getRoles(errorHandler);
        resultHandler(result);
    } catch (error) {
        console.log("error getting user roles");
        errorHandler(error);
    }
};
