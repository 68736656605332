import Honkio from "../Honkio";

const setAdminRole = ({ delete: del = false, ...rest }, errorHandler = {}) => {
    const params = {
        delete: del,
        ...rest,
    };
    return Honkio().mainShop.userFetch("adminroleset", params, errorHandler);
};

export default setAdminRole;
