import { OAUTH_URL } from "../config";

const LandingPage = () => {
    return (
        <div className="container h-100 d-flex flex-column justify-content-center">
            <div className="row bg-light">
                <div className="col bg-white shadow text-center rounded p-3">
                    <header>
                        <h2 className="my-3">Welcome to Pelican Admin panel</h2>
                    </header>
                    <main>
                        <h1>
                            Please <a href={`${OAUTH_URL}`}>sign in</a>
                        </h1>
                    </main>
                    <footer></footer>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
