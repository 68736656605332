import Honkio from "../Honkio";

const adminConsumerList = (
    { query_role, query_merchant, query_skip, query_count },
    errorHandler = () => {}
) => {
    return Honkio().mainShop.userFetch(
        "adminconsumerlist",
        {
            query_merchant,
            query_role,
            query_skip,
            query_count,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};

export default adminConsumerList;
