import { useFieldArray, useFormContext } from 'react-hook-form';
import Field from './Field';

const Fields = () => {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields',
    });
    return (
        <fieldset>
            <button
                type="button"
                className="btn btn-secondary btn-block my-3"
                onClick={() => append({ id: '', type: 'float', name: '' , mandatory: 'yes'})}
            >
                Add requirement
            </button>
            {fields.map((field, index) => {
                return (
                    <Field
                        key={field.id}
                        field={field}
                        index={index}
                        actions={{ remove }}
                    />
                );
            })}
        </fieldset>
    );
};

export default Fields;
