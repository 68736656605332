import Honkio from "../Honkio";

/**
 *
 * @param {*} shopId - not in use set to `null` in order to call; TODO: remove later
 * @param {*} assetId - id of the order
 * @param {*} errorHandler - callback
 * @returns
 */
const getReservationList = (posId = null, assetId = "", errorHandler = {}) =>
    Honkio().mainShop.userFetch(
        "reservationlist",
        {
            // shop: shopId,
            pos: posId,
            asset_id: assetId,
        },
        errorHandler
    );

export default getReservationList;
