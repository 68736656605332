import QueryString from "qs";
import { useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "../UI/Icon";
import Wait from "../UI/Wait";
import ReactTooltip from "react-tooltip";
import { Box } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const AssetList = ({
    data,
    shops,
    ready,
    selectItem,
    emptyText,
    onMainteinClick = () => {},
    onDeleteClick = () => {},
    onReserveClick = () => {},
    shopname,
    shopId,
}) => {
    const history = useHistory();
    const location = useLocation();

    const goToReceiver = (id, merchant) => {
        history.push(`/assets/edit?asset=${id}&merchant=${merchant}`, {
            data: shops,
        });
    };
    const columns = useMemo(
        () => [
            {
                name: "Name",
                grow: 1,
                cell: (item) => (
                    <Box
                        component="img"
                        sx={{
                            height: 100,
                            width: 100,
                            margin: 1,
                            maxHeight: { xs: 100, md: 100 },
                            maxWidth: { xs: 100, md: 100 },
                        }}
                        alt={item.name}
                        src={item.properties.trailer_image}
                        className="d-flex justify-content-center align-items-center"
                    />
                ),
            },
            { name: "Name", grow: 1, cell: ({ name }) => name },
            {
                name: "Sijainti",
                grow: 1,
                center: true,
                cell: (e) => {
                    if (shops) {
                        const shop = shops.find(
                            (shp) => shp?._id === e?.properties.pos
                        );
                        if (!shop) {
                            return "";
                        }
                        return shop.settings.str_name;
                    }
                    return "";
                },
            },
            {
                name: "Actions",
                grow: 1,
                center: true,
                // right: true,
                cell: ({ _id, merchant, name, properties, pos }) => (
                    <div>
                        <span
                            onClick={() => goToReceiver(_id, merchant)}
                            data-tip
                            data-for="EditTip"
                        >
                            <Icon iconName="bi-pencil" />{" "}
                            <ReactTooltip
                                id="EditTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </span>
                        <Link
                            to={`/assets/trailerWorkingDays?shopId=${pos}&asset=${_id}`}
                            data-tip
                            data-for="calendar"
                        >
                            <CalendarMonthIcon sx={{ color: "#197cff" }} />
                            <ReactTooltip
                                id="calendar"
                                place="top"
                                effect="solid"
                            >
                                Calendar of Trailer
                            </ReactTooltip>
                        </Link>

                        <span
                            onClick={() => {
                                onReserveClick(
                                    _id,
                                    name,
                                    properties.license_number,
                                    properties.pos,
                                    properties.reservable
                                );
                            }}
                            data-tip
                            data-for="reserveTip"
                        >
                            <Icon iconName="btn bi-person-check-fill" />
                            <ReactTooltip
                                id="reserveTip"
                                place="top"
                                effect="solid"
                            >
                                Reserve on behalf of consumer
                            </ReactTooltip>
                        </span>

                        <span
                            onClick={() => {
                                onMainteinClick(
                                    _id,
                                    name,
                                    properties.license_number,
                                    properties.pos,
                                    properties.reservable
                                );
                            }}
                            data-tip
                            data-for="maintainTip"
                        >
                            <Icon iconName="btn bi-tools" />
                            <ReactTooltip
                                id="maintainTip"
                                place="top"
                                effect="solid"
                            >
                                Maintanance
                            </ReactTooltip>
                        </span>

                        <span
                            onClick={() => {
                                onDeleteClick(_id, name);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon iconName="btn bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        [selectItem, shops]
    );

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100]}
            progressPending={!ready}
            noDataComponent={emptyText}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            onRowClicked={({ id }) => {
                const { pathname, search } = location;
                const qsObject = {
                    ...QueryString.parse(search, { ignoreQueryPrefix: true }),
                    parent: id,
                };
                history.push(`${pathname}?${QueryString.stringify(qsObject)}`);
            }}
        />
    );
};

export default AssetList;
