import { useState, useEffect } from 'react';
import getAdminRole from '../../api/utils/getAdminRole';
import useSearchString from '../../utils/useSearchString';

//not used
const RoleOverview = () => {
    const { appid, role_id } = useSearchString();
    const [role, setRole] = useState();
    useEffect(() => {
        getAdminRole({ id: role_id })
            .then(answer => {
                return setRole(answer.role)
            })

    }, [appid, role_id]);

    return role ? (
        <div>
            <h2>Role Overview</h2>
            <div>
                <table className="table mb-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Display Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{role.name}</td>
                            <td>{role.display_name}</td>
                            <td>{role.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
};

export default RoleOverview;
