import { useEffect, useState, useContext } from "react";
import { getAsset } from "../../api/utils/getAssets";
import useSearchString from "../../utils/useSearchString";
import prepareToPublish from "./helpers/prepareToPublish";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";

import UserInfoContext from "../../contexts/UserInfoContext";
import { setAssetData, updateAsset } from "../../api/utils/setAssetData";
import AssetEditForm from "./AssetEditForm";
import getAssetType from "../../api/utils/getAssetType";
import useLoader from "../UI/helpers/useLoader";
import LoadingBar from "../UI/LoadingBar";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setAssetOrderDetailsUpdateMsg } from "../../api/utils/setAssetOrderDetailsUpdateMsg";

const DEFAULT_MESSAGE_ID = "6364c8584334c41e47f98b6c";

const AssetEdit = () => {
    const [selectedAssetType, setSelectedAssetType] = useState([]);

    const [assetInfo, setAssetInfo] = useState({});
    const { asset: assetId } = useSearchString();
    const [progress, done] = useLoader([assetInfo, selectedAssetType]);
    const [orderOwnerEmail, setOrderOwnerEmail] = useState([]);

    const [mailList, setMailList] = useState([]);

    const location = useLocation();
    const userInfo = useContext(UserInfoContext);
    const appid = userInfo.app ? userInfo.app.id : null;
    let isMounted = true;
    const history = useHistory();

    const [reservation, setReservation] = useState([]);
    const [allOrders, setAllOrders] = useState([]);

    const [updateState, setUpdateState] = useState({
        data: false,
        code: false,
    });

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (assetInfo?.type_reference) fetchAssetType();
    }, [assetInfo]);

    const fetchAssetType = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept" && data.asset_type) {
                    setSelectedAssetType(data.asset_type);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAssetType({ id: assetInfo.type_reference }, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    useEffect(() => {
        if (updateState.data && updateState.code) {
            if (
                location?.state?.shopDetails?.shopId &&
                location?.state?.shopDetails?.shopname
            ) {
                history.push(
                    `/assets?shop=${location?.state?.shopDetails?.shopId}&shopname=${location?.state?.shopDetails?.shopname}`
                );
            } else {
                history.push(`/assets`);
            }
        }
        return () => {
            // setUpdateState({ data: false, code: false });
        };
    }, [updateState]);

    const handleSendKeboxCodeMsgs = (data) => {
        if (
            data?.properties.keybox_code !== assetInfo?.properties?.keybox_code
        ) {
            const options = {
                message_id: DEFAULT_MESSAGE_ID,
                order_ids: allOrders,
            };
            const resultHandler = (data) => {
                setUpdateState({ ...updateState, code: true });

                if (data && data?.status === "accept") {
                    NotificationManager.success("Key code updated ");
                } else {
                    NotificationManager?.error("Error");
                }
                if (
                    location?.state?.shopDetails?.shopId &&
                    location?.state?.shopDetails?.shopname
                ) {
                    history.push(
                        `/assets?shop=${location?.state?.shopDetails?.shopId}&shopname=${location?.state?.shopDetails?.shopname}`
                    );
                } else {
                    history.push(`/assets`);
                }
            };

            const errorHandler = (error) => {
                NotificationManager?.error(error.description, "Error", 4000);
            };
            setAssetOrderDetailsUpdateMsg(options, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        }
    };

    const onSave = (data) => {
        if (data?.pos) {
            var match = data.pos;
            var nameArr = match.split(",");
            const pos = nameArr[0];
            const co = [nameArr[1], nameArr[2]];

            data.coordinates = co;
            data.properties.pos = pos;
            delete data.pos;
            data.pos = pos;
        }
        const resultHandler = (result_data) => {
            setUpdateState({ ...updateState, data: true });

            if (result_data && result_data.status === "accept") {
                NotificationManager.success("Asset saved", "Success", 4000);
                handleSendKeboxCodeMsgs(data);
            } else {
                NotificationManager.error("Asset is not saved", "Error", 300);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const assetData = prepareToPublish(assetInfo, data);

        if (!assetData?.asset?.properties?.pos) {
            NotificationManager.error(
                "You must select point of sales",
                "Error",
                4000
            );
            return;
        }
        updateAsset(
            assetData.asset,
            assetData.merchant,
            appid,
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const mapAllOrderIds = (res) => {
        const arrOfArrsOfIds = res.map((item) => {
            if (item?.reservations?.length) {
                return item.reservations.map((inner) => inner?.order_id);
            }
        });
        return [...new Set(arrOfArrsOfIds.flat())];
    };

    useEffect(() => {
        if (reservation.length) {
            const orders = mapAllOrderIds(reservation);
            setAllOrders(orders);
        }
    }, [reservation]);

    useEffect(() => {
        fetchAsset();
    }, [assetId]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetInfo(data.asset);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetInfo();
        };
        if (assetId) {
            getAsset({ id: assetId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetInfo();
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>Trailer edit</h2>
            {done ? (
                assetInfo ? (
                    <AssetEditForm
                        onSubmit={onSave}
                        defaultValues={assetInfo}
                        assetType={selectedAssetType}
                        shopName={location?.state?.data}
                    />
                ) : (
                    "not found"
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default AssetEdit;
