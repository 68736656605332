import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import UserInfoContext from "../../contexts/UserInfoContext";
import Selector from "./Selector";
import useSearchString from "../../utils/useSearchString";
import { Modal, Button } from "react-bootstrap";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import getAdminRolesList from "../../api/utils/getAdminRolesList";
import setAdminRole from "../../api/utils/setAdminRole";
import RoleModal from "./RoleModal";
import { MERCHANT_ID, MERCHANT_NAME, APPLICATION_ID } from "../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const MerchantRolesList = ({ language = "en" }) => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const [roles, setRoles] = useState(null);
    const [loading, setLoading] = useState(false);

    const appId = APPLICATION_ID;

    const merchantId = MERCHANT_ID;
    const merchantName = MERCHANT_NAME;

    const pageTitle = `User groups`;

    const [showConfirmRemoveRole, setShowConfirmRemoveRole] = useState(false);
    const handleShowConfirmRemoveRole = () => setShowConfirmRemoveRole(true);
    const handleCloseConfirmRemoveRole = () => setShowConfirmRemoveRole(false);
    const [roleToRemove, setRoleToRemove] = useState({});

    const [showRolePreview, setShowRolePreview] = useState(false);
    const handleShowRolePreview = () => setShowRolePreview(true);
    const handleCloseRolePreview = () => setShowRolePreview(false);
    const [currentRole, setCurrentRole] = useState();
    const history = useHistory();

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (merchantId) {
            setLoading(true);
            fetchRoles();
        }
    }, [merchantId, selectedMerchant, perPage]);

    const fetchRoles = async (page = 1) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept" && data.roles) {
                    setRoles(data.roles);
                    setTotalRows(data?.total);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setTotalRows(0);
                    setRoles([]);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setRoles([]);
            setLoading(false);
        };

        getAdminRolesList(
            {
                appid: appId,
                merchant: selectedMerchant ? selectedMerchant?._id : merchantId,
                query_skip: (+page - 1) * perPage,
                query_count: perPage,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const onDeleteClick = (id, name) => {
        setRoleToRemove({ id, name });
        handleShowConfirmRemoveRole();
    };

    const handlePageChange = (page) => {
        fetchRoles(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const deleteRole = () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Role removed", "Success", 4000);
                handleCloseConfirmRemoveRole();
                fetchRoles();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        setAdminRole(
            {
                id: roleToRemove.id,
                delete: true,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveRole);
    };

    let onRowClick = (row) => {
        //setCurrentRole(row)
        //handleShowRolePreview()
        history.push(`/merchant/users?role=${row._id}&title=${row.name}`);
    };

    return (
        <div>
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {appId && (
                <div className="m-1">
                    {/* <Link
                        to={`/role/create?appid=${appId}${merchantId !== -1 ? `&merchant=${merchantId}` : ''}`}
                        className="btn btn-outline-primary m-1"
                    >
                        <i className="bi-plus-square ml-1 mr-2" /> {merchantId !== -1 ? `Create role for ${merchantName}` : 'Create template role'}
                    </Link> */}
                    {/*    <Link
                        to={`/role/create/full?appid=${app.id}${merchantId  !== -1 ? `&merchant=${merchantId}` : ''}`}
                        className="btn btn-outline-danger m-1"
                    >
                        <i className="bi-exclamation-square ml-1 mr-2" /> Create admin role
                    </Link> */}
                </div>
            )}
            <Selector
                loading={loading}
                items={roles}
                appid={appId}
                onDeleteClick={onDeleteClick}
                onRowClicked={onRowClick}
                language={language}
                rowsCountPerPage={perPage}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
            />

            {currentRole && (
                <RoleModal
                    roleId={currentRole._id}
                    show={showRolePreview}
                    onHide={handleCloseRolePreview}
                ></RoleModal>
            )}
            <Modal
                show={showConfirmRemoveRole}
                onHide={handleCloseConfirmRemoveRole}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove role{" "}
                        <b>{roleToRemove ? roleToRemove.name : ""}</b>?{" "}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveRole}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteRole()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MerchantRolesList;
