import { useEffect, useMemo, useState, useRef } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import LoadingBar from "./../../UI/LoadingBar";
import useLoader from "./../../UI/helpers/useLoader";
import getAsset from "../../../api/utils/getAsset";
import getShop from "../../../api/utils/getShop";
import SmartForm from "./../../Form/SmartForm";
import InputFieldWithValidation from "./../../Form/elements/InputFieldWithValidation";

const AssetInfoModal = ({
    assetId,
    show,
    onHide,
    onSubmit,
    start_date,
    end_date,
    distance,
}) => {
    const [asset, setAsset] = useState();
    const [properties, setProperties] = useState([]);
    const [shop, setShop] = useState();
    const [progress, done] = useLoader([asset]);
    const buttonRef = useRef(null);
    const [dateValues, setDateValues] = useState({});

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        setDateValues({
            start_date: start_date,
            end_date: end_date,
        });
    }, [start_date, end_date]);
    useEffect(() => {
        if (assetId) {
            fetchAsset();
        }
    }, [assetId]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAsset(data.asset);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAsset({ id: assetId }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const fetchShop = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setShop(data.shop);
                } else {
                    setShop({});
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setShop({});
        };

        getShop({ id: asset.properties.shop }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        if (asset?.properties) {
            setProperties(asset.properties);
            if (asset.properties?.shop) {
                fetchShop();
            }
        } else {
            setProperties([]);
        }
    }, [asset]);

    const handleSubmitClick = () => {
        buttonRef.current.click();
    };
    const container = (
        <div className="container">
            <div className="">
                <h2>{asset?.name}</h2>
                <div className="row mt-2">
                    <div className="font-weight-bold col-13 ml-3">
                        <label className="font-weight-bold mr-3">{`Etäisyys: ${
                            distance || "-"
                        } km `}</label>
                    </div>
                </div>
            </div>
            <SmartForm onSubmit={onSubmit} defaultValues={dateValues}>
                <div className="row">
                    <InputFieldWithValidation
                        className="col-md-5"
                        name="start_date"
                        label="Nouto"
                        required={true}
                        type="date"
                        additional="tämä päivä tarvitaan"
                        readOnly={true}
                    />
                    <InputFieldWithValidation
                        className="col-md-5"
                        name="end_date"
                        label="Palautus"
                        type="date"
                        required={true}
                        additional="tämä päivä tarvitaan"
                        readOnly={true}
                    />
                    <button
                        color="secondary"
                        type="submit"
                        ref={buttonRef}
                        style={{ display: "none" }}
                    ></button>
                </div>
            </SmartForm>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    return done ? (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Trailer</Modal.Title>
            </Modal.Header>
            <Modal.Body>{asset ? container : "not found"}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSubmitClick}>
                    Varaa nyt
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default AssetInfoModal;
