import HonkioAPI from "./../Honkio";

const orderCreate = (options = {}, errorHandler = {}) => {
    return HonkioAPI().mainShop.userFetch(
        "ordercreate",
        {
            ...options,
            version: 3,
            action: "create",
        },
        errorHandler
    );
};

export default orderCreate;
