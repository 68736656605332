import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function DeleteConfirmModel({ modal,onModalClose,handleCloseConfirmDelete}) {
  return (
    <div>
        {modal && (
        <Modal show={true} >
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure? You want to Remove this? </p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary"  onClick={onModalClose}>Close</Button>
                <Button variant="danger" onClick={() => handleCloseConfirmDelete()}>Remove</Button>
            </Modal.Footer>
        </Modal>
        )}
  </div>
  )
}
