import React, { Component } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ALLOWED_DOC_EXTENSIONS, UPLOAD_URL } from "../../../config";
import { post } from "axios";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import HonkioAPI from "../../../api/Honkio";

export default class DocumentUploadModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(files) {
        this.setState({
            files: files,
        });
    }

    handleButtonUpload = () => {
        let itemsProcessed = 0;
        let that = this;
        let allowedExtensions = [
            ...ALLOWED_DOC_EXTENSIONS,
            "png",
            "jpg",
            "doc",
        ];

        this.state.files.forEach((file) => {
            console.log(file);
            console.log("FILE");
            let filename = file.name.split(".")[0];
            let filepath = file.path;
            let filetype = that.state.doc_type;

            let inArray = allowedExtensions.includes(file.name.split(".")[1]);
            if (inArray) {
                that.setState({ loading: true });

                let savefile = async function () {
                    let params = {
                        title: filename,
                        file: filepath,
                        asset: that.state.asset,
                        type: filetype,
                    };

                    let message = {
                        file: file,
                        "content-type": "multipart/form-data",
                        object: that.props.objectId,
                        object_type: that.props.objectType,
                        access: "private",
                        file_type: "document",
                        extension: file.name.split(".")[1],
                        display_name: filename,
                        metadata: { document_type: filetype },
                    };
                    let url, thumb;
                    let r = await HonkioAPI()
                        .userShop.userFetch("fileupload", message)
                        .then(
                            (uploadFile) => {
                                url = uploadFile.file.url;
                                thumb = uploadFile.file.url;
                            },
                            (error) => {
                                // NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error.description}`);
                                that.setState({ loading: false });
                            }
                        );
                    itemsProcessed++;
                    // NotificationManager.success(`${i18n.t(k.UPLOAD_SUCCESS)} ${filename}`, i18n.t(k.SUCCESS));
                    if (itemsProcessed === that.state.files.length) {
                        that.setState({ loading: false });
                        that.props.onMediaUploaded();
                    }
                };
                savefile();
            } else {
                itemsProcessed++;
                // NotificationManager.warning(`${i18n.t(k.UNSUPPORTED_EXTENSION)} ${filename}`, i18n.t(k.DOCUMENT_UPLOAD_FAILED));
                if (itemsProcessed === that.state.files.length) {
                    that.setState({ loading: false });
                    that.props.onMediaUploaded();
                }
            }
        });
    };

    handleChangeType(e = null) {
        this.setState({ doc_type: e.value });
    }

    render() {
        let that = this;
        let button = (
            <button
                className="btn btn-primary"
                onClick={this.handleButtonUpload}
            >
                UPLOAD IMAGE
            </button>
        );
        if (this.state.loading) {
            button = <CircularProgress size={35} />;
        }

        let options = [
            { value: "FINANCIAL_STATEMENTS", label: "FINANCIAL_STATEMENTS1" },
            { value: "ACTION_REPORT", label: "ACTION_REPORT1" },
            { value: "BUDGET", label: "BUDGET1" },
            { value: "AUDITORS_REPORT", label: "AUDITORS_REPORT1" },
            {
                value: "BOARD_OF_DIRECTORS_REPORT",
                label: "BOARD_OF_DIRECTORS_REPORT1",
            },
            { value: "ENERGY_CERTIFICATE", label: "ENERGY_CERTIFICATE1" },
            { value: "RULES_OF_PROCEDURE", label: "RULES_OF_PROCEDURE1" },
            {
                value: "ARTICLES_OF_ASSOCIATION",
                label: "ARTICLES_OF_ASSOCIATION1",
            },
        ];

        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                size="lg"
                onRequestClose={this.props.onRequestClose}
                className="w-100"
            >
                <ModalHeader>UPLOAD IMAGE</ModalHeader>
                <ModalBody>
                    <DropzoneArea
                        dropzoneText={
                            "Drag and drop the Media file here or click"
                        }
                        onChange={this.handleChange.bind(this)}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        maxFileSize={100000000}
                        useChipsForPreview
                        filesLimit={10}
                        previewGridProps={{
                            container: { spacing: 1, direction: "row" },
                        }}
                        previewText="Selected media files"
                        showAlerts={false}
                        styles={{
                            "MuiDropzoneArea-root": {
                                maxHeight: 150,
                                minHeight: 150,
                            },
                        }}
                    />

                    <hr />
                    <Select
                        options={options}
                        defaultValue={{
                            value: 3,
                            label: "PLEASE_SELECT_A_DOCUMENT_TYPE",
                        }}
                        onChange={(e) => {
                            that.handleChangeType(e);
                        }}
                    />
                    <div className="form-group mt-3">{button}</div>
                </ModalBody>
            </Modal>
        );
    }
}
