import { useState, useEffect, useCallback } from 'react';

const useDashboard = (mainScreen, loadingScreen) => {
  const [node, setNode] = useState(null);
  const [screen, setScreen] = useState(loadingScreen());
  const nodeRef = useCallback((node) => {
    node && setNode(node);
  }, []);
  useEffect(() => {
    setScreen(node ? mainScreen(node) : loadingScreen(node));
  }, [node, loadingScreen, mainScreen]);
  return [nodeRef, screen];
};

export default useDashboard;
