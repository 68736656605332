import {
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_REDIRECT_URL,
    OAUTH_TOKEN_URL,
    OAUTH_REVOKE_TOKEN_URL,
} from "../config";
import getRoles from "../api/utils/getRoles";
import { NotificationManager } from "react-notifications";
import store from "../store";
import { setAuthInfo } from "../store/actions/authActions";
import { getUserRoles } from "../store/actions/rolesActions";

async function authUser(code) {
    try {
        const redirect_uri = encodeURIComponent(OAUTH_REDIRECT_URL);

        let response = await fetch(
            `${OAUTH_TOKEN_URL}?grant_type=authorization_code&code=${code}&client_id=${OAUTH_CLIENT_ID}&client_secret=${OAUTH_CLIENT_SECRET}&redirect_uri=${redirect_uri}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) {
            throw new Error("An Error has occurred, please try again.");
        }
        let json = await response.json();

        localStorage.clear();

        await store.dispatch(setAuthInfo(json));

        return true;
    } catch (err) {
        console.warn("Auth problems");
        return false;
    }
}

async function revokeToken() {
    try {
        const formData = new FormData();

        const state = store.getState();
        const access_token = state?.auth?.access_token;
        const refresh_token = state?.auth?.refresh_token;
        if (!refresh_token) return;

        formData.append("token", refresh_token);
        formData.append("token_type_hint", "refresh_token");
        formData.append("client_id", OAUTH_CLIENT_ID);
        formData.append("client_secret", OAUTH_CLIENT_SECRET);

        const response = await fetch(OAUTH_REVOKE_TOKEN_URL, {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            }),
            body: formData,
        });

        if (!response.ok) {
            throw new Error(
                "An Error occurred during token revocation, please try again."
            );
        }
    } catch (error) {
        console.error("Error revoking token: ", error);
    }
}

function LogoutAction() {
    return function (dispatch) {
        dispatch({ type: "LOGOUT" });
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token_expires_in");
        localStorage.removeItem("role");
        return Promise.resolve();
    };
}

export { authUser, LogoutAction, revokeToken };
