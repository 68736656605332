import { useEffect, useState } from 'react';
import {getAssets, getAsset} from '../../api/utils/getAssets';
import useSearchString from '../../utils/useSearchString';
import ExpressForm from '../Form/ExpressForm';
import { setAssetData } from '../../api/utils/setAssetData';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { MERCHANT_ID } from '../../config';

const AssetViewRaw = () => {
  const [info, setInfo] = useState({});
  const { asset, merchantid } = useSearchString();
  const merchant = MERCHANT_ID;
  const onSave = (data) => {
    setAssetData(data, errorHandler)
      .then((data) => {
        return resultHandler(data)
      })
  };

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      NotificationManager.success("Trailer saved", "Success", 4000)
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, "Error", 4000)
  }

  useEffect(() => {
    getAssets({ merchant, id: asset })
      .then(({ assets: [asset] }) => asset)
      .then(setInfo);
  }, [asset, merchant]);

  return  <div>
    <NotificationContainer />
    <ExpressForm
      data={info}
      forbiddenFields={[]}
      onSave={onSave} />
  </div>
};

export default AssetViewRaw;
