import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import languages from '../../__fixtures__/languages';
import AnySelector from '../UI/AnySelector';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import SmartForm from '../Form/SmartForm';
import DangerAction from './DangerAction';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';
import DynamicList from './DynamicList';
import PasswordChange from './PasswordChange';
import clsx from 'clsx';
import Fields from './Fields';
import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from 'react';
import Icon from '../UI/Icon';

const colClass = clsx('col p-2');

const AppInfoForm = ({
    appInfo,
    setAppInfo,
    editorData,
    setEditorData,
    language,
    setLanguage,
    onSubmit,
    onRequirementsClick = () => { }
}) => {
    const [registrationRequirements, setRegistrationRequirements] = useState();
    const [oauthAllowedURIList, setOauthAllowedURIList] = useState([])
    const [oauthRedirectURIList, setOauthredirectURIList] = useState([])
    useEffect(() => {
        if (appInfo?.settings?.merchant_registration_requirements) {
            const data = appInfo.settings.merchant_registration_requirements
            setRegistrationRequirements(Object.keys(data).reduce((array, key) => {
                return [...array, { id: key, ...data[key] }]
            }, [])
            )
            setOauthAllowedURIList(appInfo.settings?.list_oauth_allowed_uri || [])
            setOauthredirectURIList(appInfo.settings?.list_oauth_redirect_uri || [])
        }
    }, [appInfo]);

    const columns = useMemo(
        () => [
            {
                name: 'Is used',
                grow: 1,
                cell: (row) => {
                    const iconName = row.required ? 'bi-check2-square' : 'bi-square'
                    return <div onClick={() => {
                        const new_row = Object.assign({}, row, { required: !row.required })
                        return onRequirementsClick(new_row)
                    }
                    } data-tip data-for="selectTip">
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                }
            },
            {
                name: 'Information',
                grow: 3,
                cell: (e) => {
                    return e.name
                }
            },
            {
                name: 'Is mandatory',
                grow: 1,
                cell: (row) => {
                    const iconName = row.mandatory ? 'bi-check2-square' : 'bi-square'
                    return <div onClick={() => {
                        const new_row = Object.assign({}, row, { mandatory: !row.mandatory })
                        return onRequirementsClick(new_row)
                    }
                    } data-tip data-for="selectTip">
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                }
            },
        ]
    );

    const onSubmit1 = (data) => {
        onSubmit(data, registrationRequirements, oauthAllowedURIList, oauthRedirectURIList);
    };

    const onOauthAllowedURIList = (list) => {
        setOauthAllowedURIList(list)
    }
    const onOauthRedirectURIList = (list) => {
        setOauthredirectURIList(list)
    }

    return (
        <SmartForm defaultValues={appInfo} onSubmit={onSubmit1}>
            <CheckboxSwitch name="active" label="Active" />
            <h4>Common info</h4>
            <div className="form-row row-cols-2">
                <div className="form-group col">
                    <InputFieldWithValidation className="p-2" label="Name" name="name" />
                    <InputFieldWithValidation
                        className="p-2"
                        label="Package (class)"
                        name="classname"
                    />
                    <InputFieldWithValidation
                        className="p-2"
                        label="Administrative merchant"
                        name="admin_merchant"
                    />
                    <SelectFieldWithValidation
                        className="p-2"
                        label="Default shop"
                        disabled
                    />
                    <SelectFieldWithValidation
                        className="p-2"
                        label="Default Order model"
                        disabled
                    />
                    <InputFieldWithValidation
                        className="m-2"
                        label="Admin Email"
                        name="email"
                    />
                    <InputFieldWithValidation
                        className="m-2"
                        label="GDRP Support Email"
                        name="settings.str_gdpr_support_email"
                    />
                    
                </div>
                <div className="form-group col d-flex flex-column justify-content-between">
                    <UploadFileWithPreview
                        className="h-75"
                        name="settings.str_logo_large"
                    />
                    <UploadFileWithPreview
                        className="h-25"
                        name="settings.str_logo_small"
                    />
                </div>
            </div>
            <h4>Security</h4>
            <div className="form-row row-cols-2">
                <div className="col-7 p-2">
                    <InputFieldWithValidation
                        className="m-3"
                        label="Application Password"
                        name="settings.str_password"
                    />
                </div>
                <div className="col-5 p-2">
                    <PasswordChange
                        setState={setAppInfo}
                        actionDescription="Change password"
                    />
                </div>
            </div>
            <h4>Minimum versions</h4>
            <div className="form-row row-cols-3">
                <div className={colClass}>
                    <InputFieldWithValidation
                        className="m-2"
                        label="iOS"
                        name="settings.dict_min_version.ios"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        className="m-2"
                        label="Android"
                        name="settings.dict_min_version.android"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        className="m-2"
                        label="Windows Phone"
                        name="settings.dict_min_version.wp"
                    />
                </div>
            </div>
            <h5>Push notifications key</h5>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <InputFieldWithValidation
                        label="Android API Key"
                        name="settings.android_push_api_key"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        label="iOS Credentials (Key id, Team Id, Package)"
                        name="settings.ios_api_credentials"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        label="iOS Key"
                        name="settings.ios_api_key"
                    />
                </div>
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Sandbox mode"
                        name="settings.bool_ios_sandbox"
                    />
                </div>
            </div>
            <h4>OAuth Settings</h4>
            <div className="form-row row-cols-2 g-2">
                <div className="col p-2">
                    <InputFieldWithValidation
                        label="OAuth ID"
                        name="settings.str_oauth_id"
                    />
                </div>
                <div className="col p-2">
                    <InputFieldWithValidation
                        label="OAuth Secret"
                        name="settings.str_oauth_secret"
                    />
                </div>
                <div className="col p-2">
                    <DynamicList
                        label="OAuth Redirect URI list (first one is default)"
                        name="settings.list_oauth_redirect_uri"
                        onListChange={onOauthRedirectURIList}
                        items={appInfo?.settings.list_oauth_redirect_uri}
                    />
                </div>
                <div className="col p-2">
                    <DynamicList
                        label="OAuth allowed URI list"
                        name="settings.list_oauth_allowed_uri"
                        onListChange={onOauthAllowedURIList}
                        items={appInfo?.settings.list_oauth_allowed_uri}
                    />
                </div>
                <div className="col p-2">
                    <CheckboxSwitch
                        label="OAuth SMS Verify"
                        name="settings.shopbool_oauth_sms_verify"
                    />
                </div>
            </div>
            <h4>Misc</h4>
            <div className="form-row row-cols-2">
                <div className={colClass}>
                    <InputFieldWithValidation
                        className="m-2"
                        label="File Storage"
                        name="settings.str_upload_url"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        className="m-2"
                        label="URL to Web version"
                        name="settings.str_app_url"
                    />
                </div>
            </div>

            <h4>Telia</h4>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Enable telia plugin"
                        name="settings.plugins.telia" />
                </div>
            </div>

            <h4>Payment API</h4>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Enable Deposit API"
                        name="settings.plugins.deposit" />
                </div>
            </div>

            <h4>Merchant manual verification</h4>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Enable merchant manual verification"
                        name="settings.plugins.merchant_manual_validation" />
                </div>
            </div>

            <h4>Passport verification plugin</h4>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Enable passport verification plugin"
                        name="settings.bool_plugin_passport_verify" />
                </div>
            </div>
            <hr />
            <DataTable
                dense
                striped
                title='Merchant registration requirements'
                noDataComponent="no data"
                highlightOnHover
                data={registrationRequirements}
                columns={columns}
            />
            <Fields />
            <div className="col p-2">
                <CheckboxSwitch
                    label="Register under the main merchant"
                    name="settings.register_under_main_merchant"
                />
            </div>
            <hr />
            <h4>Terms of use (TOU)</h4>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <CheckboxSwitch
                        label="Enable plugin"
                        name="settings.bool_plugin_tou"
                    />
                </div>
            </div>
            <div className="form-row row-cols-2">
                <div className={colClass}>
                    <InputFieldWithValidation
                        label="Version"
                        name="settings.str_tou_version"
                    />
                </div>
                <div className={colClass}>
                    <InputFieldWithValidation
                        label="TOU URL"
                        name="settings.str_tou_url"
                    />
                </div>
            </div>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <AnySelector
                        items={languages}
                        nameSelector="name"
                        valueSelector="value"
                        label="Language"
                        defaultValue={language}
                        setItem={setLanguage}
                    />
                </div>

                <div className={colClass}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={editorData}
                        onChange={(_, editor) => {
                            setEditorData(editor.getData());
                        }}
                    />
                </div>
            </div>
            <div className="form-row row-cols-1">
                <div className={colClass}>
                    <button className="btn btn-primary btn-block btn-lg">
                        Save changes
                    </button>
                </div>
                <div className={colClass}>
                    <DangerAction
                        actionDescription="Delete application"
                        label="Do you want to delete this application?"
                    />
                </div>
            </div>
        </SmartForm>
    );
};

export default AppInfoForm;
