import Honkio from "../Honkio";
const setMerchantUserRole = async function (
    { user_id, role_id, action },
    errorHandler
) {
    const options = {
        user_id,
        role_id,
        action,
        version: 3,
    };
    return Honkio().mainShop.userFetch(
        "userroleset",
        {
            ...options,
        },
        errorHandler
    );
};

export const addToGroup = async (data, errorHandler = () => {}) => {
    return setMerchantUserRole({ ...data, action: "create" }, errorHandler);
};

export const removeFromGroup = async (data, errorHandler = () => {}) => {
    return setMerchantUserRole({ ...data, action: "delete" }, errorHandler);
};
