import { useEffect, useState, useContext } from 'react';
import getScripts from '../../api/utils/getScripts';
import useSearchString from '../../utils/useSearchString';
import ScriptList from './ScriptList';
import UserInfoContext from '../../contexts/UserInfoContext';

const Scripts = () => {
  const { appid, appname = null } = useSearchString();
  const [scriptList, setScriptList] = useState([]);
  const userInfo = useContext(UserInfoContext);
  const pageTitle = appname ? `Scripts (${appname})` : (appid ? 'Scripts' : (userInfo.app?.name ? `Scripts (${userInfo.app.name})` : 'Merchants'))
 
  useEffect(() => {
    getScripts({ appid })
      .then(({ reports }) => reports)
      .then(setScriptList)
      .catch(console.error);
  }, [appid]);
  return (
    <div>
      <h2>{pageTitle}</h2>
      <ScriptList data={scriptList} />
    </div>
  );
};

export default Scripts;
