import { useEffect, useState } from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useSearchString from "../../../utils/useSearchString";
import { getAssetList } from "../../../api/utils/getAssetList";
import mapDataToGeoJSON from "./helpers/mapDataToGeoJSON";
import DynamicMap from "../../Map/DynamicMap";
import AssetSearchResultList from "./AssetSearchResultList";
import getShopList from "./../../../api/utils/getShopList";
import AssetInfoModal from "./AssetInfoModal";
import AssetSearch from "./AssetSearch";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import {
    MERCHANT_API_KEY,
    MERCHANT_ID,
    OAUTH_CLIENT_SECRET,
    ORDER_MODEL_RESERVE_BY_CUSTOMER,
} from "./../../../config";
import orderCreate from "../../../api/utils/orderCreate";
import prepareToPublishMaintenanceOrder from "../../Orders/helpers/prepareToPublishMaintenanceOrder";
import shuffleArray from "../../../api/helpers/shuffleArray";

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const AssetSearchResults = () => {
    const history = useHistory();
    const [assetList, setAssetList] = useState([]);
    const { latitude, longitude, address, date1, date2, available } =
        useSearchString();

    const [searchParams, setSearchParams] = useState({
        latitude: latitude,
        longitude: longitude,
        address: address,
        date1: date1,
        date2: date2,
        onlyAvailable: available,
    });

    const [markersData, setMarkersData] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [shopList, setShopList] = useState([]);
    const [maintenanceOrder, setMaintenanceOrder] = useState();
    const [showAssetPreview, setShowAssetPreview] = useState(false);
    const handleShowAssetPreview = () => setShowAssetPreview(true);
    const handleCloseAssetPreview = () => setShowAssetPreview(false);
    const [currentAsset, setCurrentAsset] = useState();

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        fetchShops();
        fetchAssets();
    }, [searchParams, perPage]);

    const fetchShops = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setShopList(data.shops);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        if (isMounted) {
            getShopList(
                {
                    page: 0,
                    query_count: 100,
                    merchant: MERCHANT_ID,
                },
                errorHandler
            ).then((data) => {
                return resultHandler(data);
            });
        }
    };

    const groupAjacentTrailers = (trailers = []) => {
        const result = trailers.reduce(function (prev, curr) {
            if (prev.length && curr.pos === prev[prev.length - 1][0].pos) {
                prev[prev.length - 1].push(curr);
            } else {
                prev.push([curr]);
            }
            return prev;
        }, []);
        return result;
    };

    const comparePosIds = (a, b) => {
        if (a.pos < b.pos) {
            return -1;
        }
        if (a.pos > b.pos) {
            return 1;
        }
        return 0;
    };

    const shuffleTrailers = (allTrailers = []) => {
        try {
            // Sort by `pos`
            const sortedByPos = allTrailers.sort(comparePosIds);

            // Group items with the same `pos`
            const groupedByPos = groupAjacentTrailers(sortedByPos);

            // Shuffle grouped items
            const shuffledGrouped = groupedByPos.map((item) =>
                shuffleArray(item)
            );

            if (window?.debug) {
                // Set window.debug = true in order to see output in production
                console.log("sortedByPos == ", sortedByPos);
                console.log("groupedByPos == ", groupedByPos);
                console.log("shuffledGrouped == ", shuffledGrouped);
            }

            // Flutten array of Trailers
            return shuffledGrouped.flat();
        } catch (error) {
            console.log("error shuffling items: ", error);
        }

        // Return list of unshuffled Trailers as a fallback
        return allTrailers;
    };

    const fetchAssets = async (page = 1) => {
        setLoadingList(true);
        const resultHandler = (data) => {
            setLoadingList(false);
            if (data && data.status === "accept") {
                if (isMounted) {
                    const assets = data.assets;
                    if (assets && assets.length > 0) {
                        setAssetList(shuffleTrailers(assets));
                        setTotalRows(data?.total || 0);
                    } else {
                        setAssetList([]);
                        setTotalRows(0);
                    }
                }
            } else {
                NotificationManager.error("Error");
                setAssetList([]);
                setTotalRows(0);
            }
        };

        const errorHandler = (error) => {
            setLoadingList(false);
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList([]);
            setTotalRows(0);
        };
        getAssetList(
            {
                query_skip: (+page - 1) * perPage,
                query_count: perPage,
                merchant: MERCHANT_ID,
                query_search_latitude: searchParams.latitude,
                query_search_longitude: searchParams.longitude,
                query_distance: 50000,
                query_pickup_date: searchParams.date1,
                query_return_date: searchParams.date2,
                query_only_available: searchParams.onlyAvailable,
                query_visible: true,
                query_reservable: false,
                role: 123,
                // Required for api_key auth
                merchant_api_key: MERCHANT_API_KEY,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    useEffect(() => {
        if (assetList && assetList.length > 0) {
            setMarkersData(mapDataToGeoJSON(assetList));
        } else {
            setMarkersData([]);
        }
    }, [assetList]);

    let onReserveClick = (row, distance) => {
        setCurrentAsset({
            asset: row,
            distance: distance,
        });
        handleShowAssetPreview();
    };
    useEffect(() => {
        setMaintenanceOrder({
            title: `Reserve Trailer ${currentAsset?.asset?.name}`,
            status: "initialized",
            description: "",
            order_type: "Reservation",
            model: ORDER_MODEL_RESERVE_BY_CUSTOMER,
            merchant: currentAsset?.asset?.merchant,
            products: [],
            custom_fields: {
                asset: currentAsset?.asset?._id,
                asset_name: currentAsset?.asset?.name,
                asset_license_number:
                    currentAsset?.asset?.properties?.license_number,
            },
            shop_id: currentAsset?.asset?.pos,
            point_of_sales: currentAsset?.asset?.pos,
        });
    }, [currentAsset]);

    let onSubmit = (formData) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data.status === "accept") {
                    if (data) {
                        NotificationManager.success("Saved", 4000);
                        setMaintenanceOrder();
                        openReservationPage(
                            formData?.start_date,
                            formData?.end_date,
                            data?.order._id
                        );
                    } else {
                    }
                } else {
                    NotificationManager.error("Error", 4000);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newOrder = prepareToPublishMaintenanceOrder(
            maintenanceOrder,
            formData
        );

        orderCreate(newOrder, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };
    const openReservationPage = (pickUpDate, returnDate, id) => {
        history.push(
            `/asset/reservation?asset=${currentAsset.asset._id}&pickUpDate=${pickUpDate}&returnDate=${returnDate}&id=${id}`
        );
    };

    const handlePageChange = (page) => {
        fetchAssets(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    let onValuesChanged = (data) => {
        setSearchParams({
            latitude: data.latitude,
            longitude: data.longitude,
            onlyAvailable: data.only_available,
            address: data.formatted_address,
            date1: data.date1,
            date2: data.date2,
        });
    };
    const calcCenter = (locations) => {
        var minX, maxX, minY, maxY;
        for (var i = 0; i < locations.length; i++) {
            minX =
                locations[i].lat < minX || minX == null
                    ? locations[i].lat
                    : minX;
            maxX =
                locations[i].lat > maxX || maxX == null
                    ? locations[i].lat
                    : maxX;
            minY =
                locations[i].lng < minY || minY == null
                    ? locations[i].lng
                    : minY;
            maxY =
                locations[i].lng > maxY || maxY == null
                    ? locations[i].lng
                    : maxY;
        }
        return { lat: (minX + maxX) / 2, lng: (minY + maxY) / 2 };
    };
    let getMapCenter = (data) => {
        if (data.length > 0) {
            const locations = data.map((e) => ({
                lat: e.geometry.coordinates[1],
                lng: e.geometry.coordinates[0],
            }));
            const result = calcCenter(locations);
            return result;
        } else {
            return { lat: 60.169336, lng: 24.952342 };
        }
    };

    return (
        <Box
            sx={{
                flexDirection: "column",
                height: "75%",
            }}
        >
            <NotificationContainer />
            <AssetSearch
                address={searchParams.address}
                pickUpDate={searchParams.date1}
                returnDate={searchParams.date2}
                onlyAvailable={searchParams.onlyAvailable}
                onSearch={onValuesChanged}
            />
            <div className="d-flex h-100 ">
                <div className="w600px">
                    <AssetSearchResultList
                        data={assetList}
                        loading={loadingList}
                        shops={shopList}
                        lat={searchParams.latitude}
                        lng={searchParams.longitude}
                        onReserveClick={onReserveClick}
                        emptyText={"The trailer list is empty"}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                    />
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    {!loadingList ? (
                        <div className="h-100">
                            <DynamicMap
                                defaultZoom={10}
                                defaultCenter={getMapCenter(markersData)}
                                data={markersData}
                            />
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </div>
            {currentAsset && (
                <AssetInfoModal
                    assetId={currentAsset.asset._id}
                    start_date={searchParams.date1}
                    end_date={searchParams.date2}
                    distance={currentAsset.distance}
                    show={showAssetPreview}
                    onSubmit={onSubmit}
                    onHide={handleCloseAssetPreview}
                ></AssetInfoModal>
            )}
        </Box>
    );
};

export default AssetSearchResults;
