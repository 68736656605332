import moment from "moment";

const prepareToPublishReservationOnBehalfOrder = (order, formData) => {
    const { description, start_date, end_date, custom_fields, customer_info } =
        formData;
    const result = {
        ...order,
        start_date: start_date
            ? moment(start_date).format("YYYY-MM-DD").toLocaleString()
            : "",
        end_date: end_date
            ? moment(new Date(end_date)).format("YYYY-MM-DD").toLocaleString()
            : "",
        description,
        custom_fields: {
            ...order.custom_fields,
            ...custom_fields,
        },
        customer_info,
    };

    return result;
};

export default prepareToPublishReservationOnBehalfOrder;
