import clsx from 'clsx';
import { useCallback } from 'react';
import { greatPlaceStyle } from './helpers/style';

const commonClass = clsx('position-absolute');
const groupClass = (size) =>
    clsx(commonClass, 'bg-warning rounded', `p-${size}`);
const pointClass = () => clsx(commonClass, 'text-danger');

const getSize = (estimatee, degree = 4, min = 1, max = 5) => {
    const reduced = Math.floor(Math.log(estimatee) / Math.log(degree));
    return reduced > max ? max : reduced < min ? min : reduced;
};

const Marker = ({ cluster, supercluster, mapRef, lat, lng }) => {
    const zoomAction = useCallback(() => {
        const expansionZoom = Math.min(
            supercluster.getClusterExpansionZoom(cluster.id),
            20
        );
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({ lat, lng });
    }, [cluster.id, lat, lng, mapRef, supercluster]);

    const { properties } = cluster;
    const { cluster: isCluster } = properties;

    const displayLabel = isCluster ? (
        <div style={greatPlaceStyle}>{properties.point_count}</div>
    ) : (
        <div style={greatPlaceStyle}>T</div>
    );
    const size = getSize(properties?.point_count ?? 0);
    const markerClass = isCluster ? groupClass(size) : pointClass();
    const onClick = isCluster ? zoomAction : () => { };

    return (
        <div
            className={markerClass}
            onClick={onClick}>
            {displayLabel}
        </div>
    );
};

export default Marker;
