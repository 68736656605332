import { useEffect, useState, useContext } from 'react';
import getConsumerList from '../../api/utils/getConsumerList';
import UsersTable from './UsersTable';
import InlineSearch from '../UI/InlineSearch';
import AnySelector from '../UI/AnySelector';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';
import { NotificationManager, NotificationContainer } from "react-notifications";
import useSearchString from '../../utils/useSearchString';
import UserInfoModal from './UserInfoModal';

const Users = () => {
    const [consumerList, setConsumerList] = useState();
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('');
    const {
        app: { id: appid }
    } = useContext(UserInfoContext);
    const userInfo = useContext(UserInfoContext);
    const { merchant = null } = useSearchString();
    const merchantId = merchant ? merchant : (userInfo.merchant ? userInfo.merchant.id : null)
    //todo if merchant we have to ger merchant name someware to put it in title
    const pageTitle = merchant ? 'Users' : (userInfo.merchant?.name ? `Users (${userInfo.merchant.name})` : 'Users')

    const [loading, setLoading] = useState(false);

    const [showUserPreview, setShowUserPreview] = useState(false);
    const handleShowUserPreview = () => setShowUserPreview(true);
    const handleCloseUserPreview = () => setShowUserPreview(false);
    const [currentUser, setCurrentUser] = useState();

    let onRowClick = (row) => {
        setCurrentUser(row)
        handleShowUserPreview()
    }

    useEffect(() => {
        setLoading(true);
        const resultLoadingHandler = (data) => {
            if (data && data.status === 'accept') {
                setConsumerList(data.consumers.map((consumer) => ({
                    ...consumer,
                    active: consumer.active.toString(),
                })))

                setLoading(false);
            } else {
                setConsumerList([])
                setLoading(false);
            }
        };

        const errorLoadingHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setConsumerList([])
            setLoading(false);
        }

        getConsumerList({ merchantId, appid }, errorLoadingHandler)
            .then(data => {
                return resultLoadingHandler(data)
            })
    }, []);

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
                <div>
                    <AnySelector
                        label="Filter by state"
                        items={[
                            { name: 'Active only', value: 'true' },
                            { name: 'Disabled only', value: 'false' },
                        ]}
                        resetItem={{ name: 'Show all users', value: '' }}
                        nameSelector="name"
                        valueSelector="value"
                        setItem={setType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <UsersTable
                        data={consumerList}
                        keyword={keyword}
                        type={type}
                        loading={loading}
                        filterSearch={searchFilterIfConsist}
                        filterType={typifyIfEquals}
                        onRowClicked={onRowClick}
                    />
                </div>
            </div>
            {currentUser && <UserInfoModal
                consumerId={currentUser.id}
                show={showUserPreview}
                onHide={handleCloseUserPreview}>
            </UserInfoModal>
            }
        </div>
    );
};

export default Users;
