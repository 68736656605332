import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';

const ScriptList = ({ data }) => {
  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      { name: 'Name', grow: 1, cell: ({ name }) => name },
      {
        name: 'Active',
        grow: 0,
        cell: ({ active }) => (active ? 'Yes' : 'No'),
      },
      { name: 'Debug', grow: 0, cell: ({ debug }) => (debug ? 'Yes' : 'No') },
      {
        name: 'Actions',
        grow: 1,
        cell: ({ _id }) => (
          <div>
            <Link to={`/scripts/edit?script=${_id}`}>
              <Icon iconName="bi-pen" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <DataTable dense striped highlightOnHover data={data} columns={columns} />
  );
};

export default ScriptList;
