import { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import "./App.css";
import AuthCheck from "./components/Auth/AuthCheck";
import AuthContext from "./contexts/AuthContext";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";

import { checkAuthState, signOutAction } from "./auth/authActions";
import { APPLICATION_ID, OAUTH_URL } from "./config";
import AdminView from "./components/AdminView/AdminView";
import AuthorizedContainer from "./components/Auth/AuthorizedContainer";
import GuestContainer from "./components/Auth/GuestContainer";
import LandingPage from "./components/LandingPage";
import Viewport from "./layouts/Viewport";
import UserInfoContext from "./contexts/UserInfoContext";
import {
    createStore,
    StateMachineProvider,
    useStateMachine,
} from "little-state-machine";
import AssetSearch from "./components/Assets/Search/AssetSearch";
import AssetSearchResults from "./components/Assets/Search/AssetSearchResults";
import AssetReservation from "./components/Assets/Search/AssetReservation";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./ThemeOptions";
import ThankYou from "./components/Assets/Search/Thankyou";
import { getAppMerchantsList } from "./store/actions/appMerchantActions";

createStore(
    {
        app: {},
        merchant: {},
    },
    {
        name: "app_merchant_info",
        middleWares: [console.log],
        storageType: localStorage,
    }
);

const update = (state, payload) => {
    return { ...state, ...payload };
};

const View = () => {
    const dispatch = useDispatch();
    const { selectedRole } = useSelector((state) => state.roles);

    useEffect(() => {
        if (selectedRole)
            dispatch(
                getAppMerchantsList({
                    query_application: APPLICATION_ID,
                    page: 0,
                    query_count: 10000,
                })
            );
    }, [selectedRole]);

    const { actions, state } = useStateMachine({ update });
    const [authContext, setAutnContext] = useState({
        isAuth: checkAuthState(),
        authURL: OAUTH_URL,
        signIn: () => {
            setAutnContext({ ...authContext, isAuth: true });
        },
        signOut: () => {
            signOutAction();
            setAutnContext({ ...authContext, isAuth: false });
        },
    });
    const [userInfoContext, setUserInfoContext] = useState({
        ...state,
        setInfo: (info) => {
            actions.update(info);
            setUserInfoContext((state) => ({ ...state, ...info }));
        },
    });
    return (
        <Viewport>
            <Router>
                <AuthContext.Provider value={authContext}>
                    <GuestContainer>
                        <Switch>
                            <Route path="/dashboard" component={AdminView} />
                            <Route
                                path="/asset/search_results"
                                component={AssetSearchResults}
                            />
                            <Route
                                path="/asset/reservation"
                                component={AssetReservation}
                            />
                            <Route exact path="/" component={AssetSearch} />
                            <Route path="/authorized" component={AuthCheck} />
                            <Route path="/adminlogin" component={LandingPage} />
                            <Route path="/thankYou" component={ThankYou} />
                            {/* <Route path="">
                                <Redirect to="/" />
                            </Route> */}
                        </Switch>
                    </GuestContainer>
                    <AuthorizedContainer>
                        <UserInfoContext.Provider value={userInfoContext}>
                            <AdminView />
                        </UserInfoContext.Provider>
                    </AuthorizedContainer>
                </AuthContext.Provider>
            </Router>
        </Viewport>
    );
};

const App = () => (
    <Provider store={store}>
        <StateMachineProvider>
            <ThemeProvider theme={theme}>
                <View />
            </ThemeProvider>
        </StateMachineProvider>
    </Provider>
);

export default App;
