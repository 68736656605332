import { useFormContext } from 'react-hook-form';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import './styles.css';

const Editor = ({ language = 'javascript', name }) => {
  const { register, watch } = useFormContext();
  const textareaContent = watch(name, '...type your code here');
  return (
    <ScrollSync>
      <div className="h-50 position-relative">
        <ScrollSyncPane>
          <textarea
            ref={register}
            name={name}
            id={name}
            className="h-100 w-100 text-monospace text-white"
            spellCheck="false"
          />
        </ScrollSyncPane>
        <ScrollSyncPane>
          <SyntaxHighlighter
            language={language}
            style={docco}
            className="position-absolute d-block w-100 h-100 bg-transparent highlight"
          >
            {textareaContent}
          </SyntaxHighlighter>
        </ScrollSyncPane>
      </div>
    </ScrollSync>
  );
};

export default Editor;
