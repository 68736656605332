import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { getAdminOrder } from "../../api/utils/getOrders";
import getSSN from "../../api/utils/getSSN";
export default function ReservationDeleteModel({
    id,
    modal,
    onModalClose,
    handleCloseConfirmDelete,
}) {
    const [orderDetails, setOrderDetails] = useState();
    const [ssn, setSSN] = useState("");

    const [showConfirmSSNModal, setShowConfirmSSNModal] = useState(false);

    useEffect(() => {
        const params = {
            id: id,
        };
        const resultHandler = (data) => {
            setOrderDetails(data?.order);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAdminOrder(params, errorHandler).then((data) => {
            return resultHandler(data);
        });
    }, [id]);

    const renderOrderOwner = () => {
        if (!orderDetails) return "";
        const email =
            orderDetails?.consumer?.email ||
            orderDetails?.user_info?.email ||
            orderDetails?.user_owner;

        let fullname = "";
        const firstname = orderDetails?.consumer?.settings?.str_firstname;
        const lastname = orderDetails?.consumer?.settings?.str_lastname;
        if (firstname && lastname) {
            fullname = `${firstname} ${lastname}`;
        }

        if (fullname) {
            return fullname;
        } else {
            return email;
        }
    };

    const fetchSSN = async () => {
        const resultHandler = (data) => {
            if (data?.ssn) {
                setSSN(data.ssn);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const res = await getSSN({ order_id: id }, errorHandler);

        if (res && res.status === "accept") {
            resultHandler(res);
        }
    };

    const handleReqeustSSN = async () => {
        fetchSSN();
    };

    const handleCloseModal = () => {
        setSSN(null);
        onModalClose();
    };

    return (
        <div>
            {modal && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Order Details{" "}
                            {orderDetails?.custom_fields?.asset_name
                                ? ` - ${orderDetails?.custom_fields?.asset_name}`
                                : null}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ssn ? (
                            <p className="text-danger">
                                <b>SSN</b>: {ssn}
                            </p>
                        ) : null}
                        <p>Order Id: {id}</p>
                        <p title={orderDetails?.user_owner}>
                            Order Owner: {renderOrderOwner()}
                        </p>
                        <p>creation date: {orderDetails?.creation_date}</p>
                        <p>start date: {orderDetails?.start_date}</p>
                        <p>end date: {orderDetails?.end_date}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        {!ssn ? (
                            <Button
                                className="mr-auto"
                                variant="secondary"
                                onClick={() => setShowConfirmSSNModal(true)}
                            >
                                Show SSN
                            </Button>
                        ) : null}
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => handleCloseConfirmDelete()}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <Modal
                show={showConfirmSSNModal}
                onHide={() => setShowConfirmSSNModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Show user owner SSN number</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleReqeustSSN();
                            setShowConfirmSSNModal(false);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowConfirmSSNModal(false);
                        }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
