import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';

const MainScreen = ({ config }) => (
  <Switch>
    {config.map(({ path, component }) => (
      <Route key={path} path={path} component={component} />
    ))}
  </Switch>
);

export default MainScreen;
