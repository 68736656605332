import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import setOrderStatus from "../../../api/utils/setOrderStatus";

export default function DescriptionModal({
  modal,
  onModalClose,
  setShowDescribModal,
  id,
  status,
  setModal
}) {
  const [des,setDescription]= useState();
  const handleChange = (e)=>{
    setDescription(e?.target?.value)
  }
  const handleSubmit=()=>{
    const resultHandler = (data) => {
      setModal(true)
      NotificationManager.success(`Status set to returned ${status}`, "Success", 4000);
      window.location.reload();
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, "Error", 4000);
    };
    setOrderStatus(id,status,des,
    errorHandler).then((data) => {
      return resultHandler(data);
    });


    // setOrderStatus(id,status,des)
    setShowDescribModal(false);
  }
  return (
    <div>
      {modal && (
        <Modal show={true}>
          <Modal.Header >
            <Modal.Title>Describtion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              rows="3"
              cols="35"
              placeholder="Please add your issue here"
              onChange={handleChange}
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="danger" onClick={onModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleSubmit()}>
              Submit
            </Button>
            
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
