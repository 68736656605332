import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import DescriptionModal from "../modals/DescriptionModal";
import { useEffect, useState } from "react";
import setOrderStatus from "../../../api/utils/setOrderStatus";
import { getAdminOrder } from "../../../api/utils/getOrders";
import { NotificationManager } from "react-notifications";

const OrderReservedByOperatorForm = ({ order, id, shop }) => {
    const [showDescribModal, setShowDescribModal] = useState(false);
    const [status, setStatus] = useState();
    const [orderData, setOrderData] = useState();
    const [modal, setModal] = useState(false);
    const handleShowConfirmModal = () => {
        setShowDescribModal(true);
    };

    const onBtnClick = (Status) => {
        setStatus(Status);
        handleShowConfirmModal();
        if (modal === true) {
            // alert(modal)
            fetchOrder();
        }
    };

    const fetchOrder = async () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setOrderData(data.order);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        const params = {
            id: id,
        };
        getAdminOrder(params, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };
    useEffect(() => {
        fetchOrder();
    }, [id, status]);

    const onReturnClick = () => {
        const resultHandler = (data) => {
            setStatus("returned");
            NotificationManager.success(
                "Status set to returned",
                "Success",
                4000
            );
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        setOrderStatus(order?._id, "returned", errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const onBtnClickOnUse = () => {
        const resultHandler = (data) => {
            setStatus("on_use");
            NotificationManager.success(
                "Status set to on use",
                "Success",
                4000
            );
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        setOrderStatus(order?._id, "on_use", errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const renderFullname = (order) => {
        if (order?.custom_fields?.customer_fullname)
            return order?.custom_fields?.customer_fullname;

        let fullname = "";
        if (order?.custom_fields?.customer_firstname)
            fullname += order?.custom_fields?.customer_firstname;
        if (order?.custom_fields?.customer_lastname)
            fullname = fullname + " " + order?.custom_fields?.customer_lastname;

        if (!fullname.trim()) fullname = "Anonymous";
        return fullname.trim();
    };
    return (
        <div>
            {/* <h2>{order.title}</h2> */}
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Order ID : </b>
                </Col>
                <Col md={9}>{orderData?._id}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Point Of Sales : </b>
                </Col>
                <Col md={9}>{shop?.settings?.str_name}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Status: </b>
                </Col>
                <Col md={9}>{orderData?.status}</Col>
            </Row>

            {/* Customer info */}
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer first name: </b>
                </Col>
                <Col md={9}>{orderData?.custom_fields?.customer_firstname}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer last name: </b>
                </Col>
                <Col md={9}>{orderData?.custom_fields?.customer_lastname}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer phone: </b>
                </Col>
                <Col md={9}>{orderData?.custom_fields?.customer_phone}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer email: </b>
                </Col>
                <Col md={9}>{orderData?.custom_fields?.customer_email}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer ssn: </b>
                </Col>
                <Col md={9}>{orderData?.custom_fields?.customer_ssn}</Col>
            </Row>

            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Description: </b>
                </Col>
                <Col md={9}>{orderData?.description}</Col>
            </Row>

            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Start date: </b>
                </Col>
                <Col md={9}>
                    {moment(orderData?.start_date).format("YYYY-MM-DD")}
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>End date: </b>
                </Col>
                <Col md={9}>
                    {moment(orderData?.end_date).format("YYYY-MM-DD")}
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>consumer name: </b>
                </Col>
                <Col md={9}>{renderFullname(orderData)}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>email: </b>
                </Col>
                <Col md={9}>{orderData?.user_info?.email}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>order creation day: </b>
                </Col>
                <Col md={9}>
                    {moment(orderData?.start_date).format("YYYY-MM-DD")}
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>phone: </b>
                </Col>
                <Col md={9}>{orderData?.user_info?.phone}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>person ID: </b>
                </Col>
                <Col md={9}>{orderData?.HETU}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Customer Issue : </b>
                </Col>
                <Col md={9}>{orderData?.customer_issue_description}</Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col md={3}>
                    <b>Owner Issue : </b>
                </Col>
                <Col md={9}>{orderData?.owner_issue_description}</Col>
            </Row>

            {orderData?.status === "telia_authentication_succesful" ? (
                <button
                    type="button"
                    className="btn btn-secondary btn-block "
                    onClick={onBtnClickOnUse}
                    style={{ marginRight: "8px" }}
                >
                    On Use
                </button>
            ) : orderData?.status === "on_use" ? (
                <>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block "
                        onClick={() => onBtnClick("issued_by_customer")}
                        style={{ marginRight: "8px" }}
                    >
                        Customer report issue
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block "
                        onClick={() => onBtnClick("issued_by_owner")}
                        style={{ marginRight: "8px" }}
                    >
                        Report issue
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block "
                        onClick={onReturnClick}
                        style={{ marginRight: "8px" }}
                    >
                        Returned
                    </button>
                </>
            ) : null}
            <DescriptionModal
                modal={showDescribModal}
                onModalClose={() => {
                    setShowDescribModal(false);
                }}
                setShowDescribModal={setShowDescribModal}
                id={orderData?._id}
                status={status}
                setModal={setModal}
            />
        </div>
    );
};

export default OrderReservedByOperatorForm;
