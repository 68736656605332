import React, { Component } from 'react';

class CardWrapperComponent extends Component {
  render() {
    let header, footer;
    if (this.props.header) {

      header = <div className="card-header border-bottom bg-light d-flex">
                <h6 className="text-muted mb-0 text-uppercase">{this.props.card_title}</h6>
                <div className="col">{this.props.rightIconPre}{this.props.rightIcon}</div>
            </div>;
    } else {
      header = <></>;
    }
    if (this.props.footer) {
      footer = <div className="card-footer border-top bg-light">
                {this.props.card_footer}
            </div>;
    } else {
      footer = <></>;
    }
    return (
      <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={`card ${this.props.add_class}`}>
                            {header}
                            <div className="card-body">
                                {this.props.children}
                            </div>
                            {footer}
                        </div>
                    </div>
                </div>
            </div>);

  }}


export default CardWrapperComponent;