import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button} from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getAdminRole from './../../api/utils/getAdminRole';
import DataTable from 'react-data-table-component';

const RoleModal = ({ roleId, show, onHide }) => {
    const [role, setRole] = useState();
    const [progress, done] = useLoader([roleId, role]);
    const [accesses, setAcesses] = useState([]);
    const columns = useMemo(
        () => [
            {
                name: '#',
                width: '50px',
                grow: 0,
                right: true,
                cell: (_, index) => index + 1,
            },
            {
                name: 'access', grow: 3, cell: (e) => {
                    return e
                }
            },
        ]
    );

    useEffect(() => {
        if (roleId) {
            getAdminRole({ id: roleId })
                .then(({ role }) => role)
                .then(setRole);
        }
    }, [roleId]);

    useEffect(() => {
        if (role) {
            const access = Object.entries(role.object_access).map(e => {
                return `${e[0]} (${e[1].join(',')})`
            })
            setAcesses(access)
        }
    }, [role]);

    const container = <div className="container">
        <div className="col-8">
            <label className="font-weight-bold mr-2" >Name</label>
            <label>{role?.name}</label>
            <br />
            <label className="font-weight-bold mr-3" >Description</label>
            <label>{role?.description || '-'}</label>
            <br />
            <label className="font-weight-bold mr-3" >Accesses</label>
            <DataTable
                dense
                striped
                noHeader
                noDataComponent="no permissions" 
                highlightOnHover
                data={accesses}
                columns={columns}
            />
        </div>
    </div>

    const onClickHide = () => {
        onHide()
    }

    return done ? (
        <Modal
            show={show}
            onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>User group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {role ? container : 'not found'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    )
};

export default RoleModal