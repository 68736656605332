import getMerchantList from "../../api/utils/getMerchantList";
import { SET_SELECTED_APP_MERCHANT, SET_APP_MERCHANTS } from "../types";

export const getAppMerchantsList =
    ({ query_application, page, query_count }) =>
    async (dispatch) => {
        try {
            const result = await getMerchantList({
                query_application,
                page,
                query_count,
            });
            if (result && result?.status == "accept") {
                dispatch({
                    type: SET_APP_MERCHANTS,
                    payload: result.merchants,
                });
            } else {
                dispatch({
                    type: SET_APP_MERCHANTS,
                    payload: [],
                });
            }
        } catch (error) {
            console.log("Error getting app merchants list: ", error);
            dispatch({
                type: SET_APP_MERCHANTS,
                payload: [],
            });
        }
    };

export const setAppSelectedMerchant = (selectedMerchant) => ({
    type: SET_SELECTED_APP_MERCHANT,
    payload: selectedMerchant,
});
