import { useMemo } from 'react';
import InputFieldArray from '../Form/elements/FieldArray/InputFieldArray';
import SelectFieldArray from '../Form/elements/FieldArray/SelectFieldArray';

const Field = ({ field = {}, index, actions = {}, control }) => {
    const types = useMemo(() => ['str', 'float', 'int', 'bool', 'document', 'picture'], []);
    const mandatory = useMemo(() => ['yes', 'no'], []);

    return (
        <div className="form-row align-items-end mb-2">
            <InputFieldArray
                label={index === 0 ? 'ID' : null}
                className="col-3"
                name={`fields.${index}.id`}
                defaultValue={field.id}
            />
            <SelectFieldArray
                label={index === 0 ? 'Type' : null}
                className="col-2"
                name={`fields.${index}.type`}
                defaultValue={field.type}
                options={types}
            />
            <InputFieldArray
                label={index === 0 ? 'Name (description)' : null}
                className="col-4"
                name={`fields.${index}.name`}
                defaultValue={field.name}
            />
            <SelectFieldArray
                label={index === 0 ? 'Mandatory' : null}
                className="col-0"
                name={`fields.${index}.mandatory`}
                defaultValue={field.mandatory}
                options={mandatory}
            />
            <div className="ml-auto mr-2 col-1">
                <button
                    className="btn btn-light"
                    onClick={() => {
                        actions.remove(index);
                    }}
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default Field;
