import Honkio from '../Honkio';

const updateMerchantData = async function (merchant, errorHandler) {
    let consumer = await Honkio().mainShop.userFetch('consumerget', {}, errorHandler);
    if (consumer && consumer.status === 'accept') {
        return Honkio()
            .mainShop.userFetch('merchantupdate', {
                ...merchant,
                login_identity: consumer.login_identity,
            }, errorHandler)
    } else {
        return
    }

};

export default updateMerchantData;
