import clsx from 'clsx';

const Stub = ({ className, children }) => {
  const classSet = clsx(
    'bg-info',
    'text-light',
    'w-100',
    'h-100',
    'd-inline-block',
    'rounded',
    'd-flex',
    'justify-content-center',
    'align-items-center',
    className
  );
  return <div className={classSet}>{children ?? '-=STUB=-'}</div>;
};

export default Stub;
