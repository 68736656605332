import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const CheckboxSwitch = ({ className, name, value, label, defaultChecked, onChange }) => {
  const { register } = useFormContext();
  return (
    <div className={clsx(className, 'custom-control custom-switch mb-3')}>
      <input
        ref={register}
        name={name}
        checked={value}
        className="custom-control-input"
        type="checkbox"
        id={name}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxSwitch;
