import Honkio from "../Honkio";

const getAdminShopList = (options = {}, errorHandler = {}) => {
    const {
        merchant: query_merchant,
        page = 0,
        query_count = 0,
        query_asset_type_count,
    } = options;
    return Honkio().mainShop.userFetch(
        "adminshoplist",
        {
            query_merchant,
            query_asset_type_count,
            query_skip: +page * query_count,
            query_count,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};

export default getAdminShopList;
