const searchFilterIfConsist = (data = [], cols = [''], keyword = '') => {
  const re = new RegExp(keyword.toLowerCase());
  return data.filter((row) =>
    cols
      .map((col) => {
        if (row[col] == null) return 0;
        return row[col].toLowerCase().search(re) + 1;
      })
      .some(Boolean)
  );
};

export default searchFilterIfConsist;
