import { GOOGLE_API_KEY } from '../../config';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import useSupercluster from 'use-supercluster';
import {  useRef, useEffect, useState } from 'react';

const DynamicMap = ({
    defaultCenter = {
        lat: 60,
        lng: 30,
    },
    defaultZoom = 1,
    data = [],
}) => {
    const mapRef = useRef();
    const [zoom, setZoom] = useState()
    const [bounds, setBounds] = useState()
    const [items, setItems] = useState([])
    /*const { clusters, supercluster } = useSupercluster({
        points: state.items,
        bounds: state.bounds,
        zoom: state.zoom,
        options: { radius: 100, maxZoom: 20 },
    });*/
    const { clusters, supercluster } = useSupercluster({
        points: items,
        bounds: bounds,
        zoom: zoom,
        options: { radius: 100, maxZoom: 20 },
    });

    useEffect(() => {
        //dispatch({ type: 'reset', payload: { items: data } });
        setItems(data)
    }, [data]);

    /*const onChange = ({ zoom, bounds }) => {
        dispatch({
            type: 'change',
            payload: {
                zoom,
                bounds: [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
            },
        });
    };*/

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat
                ]);
            }}
        >
            {clusters.map((cluster,index) => {
                const [lng, lat] = cluster.geometry.coordinates;
                // const { id } = cluster;/
                return (
                    <Marker
                        key={index}
                        supercluster={supercluster}
                        mapRef={mapRef}
                        cluster={cluster}
                        lng={lng}
                        lat={lat}
                    />
                );
            })}
        </GoogleMapReact>
    );
};

export default DynamicMap;
