import Honkio from '../Honkio';

const getPasswordPolicy = (options = {}, errorHandler = {}) => {
  const { debug } = options;
  return Honkio()
    .mainShop.userFetch('merchantpasswordpolicy', {
      debug,
    }, errorHandler)
    .catch(console.log);
};

export default getPasswordPolicy;
