import store from "../store";
import { revokeToken } from "./authUser";

const signOutAction = async () => {
    // Revoke token so noone could use it
    const res = await revokeToken();

    localStorage.clear();
    window.location.href = "/";
};

const checkAuthState = () => {
    const state = store.getState();
    const accessToken = state?.auth?.access_token;

    return !!accessToken;
};

export { checkAuthState, signOutAction };
