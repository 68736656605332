import { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import SmartForm from "./../../Form/SmartForm";
import InputFieldWithValidation from "./../../Form/elements/InputFieldWithValidation";
import Honkio from "../../../api/Honkio";

const ReserveOnBehalfModal = ({ order, show, onHide, onSubmit, loading }) => {
    const buttonRef = useRef(null);

    const [info, setInfo] = useState(null);

    const handleSubmitClick = () => {
        if (!info)
            NotificationManager.error("User info is not set", "Error", 4000);
        buttonRef.current.click();
    };

    const onClickHide = () => {
        onHide();
    };

    useEffect(() => {
        const options = {};
        const handleAuthError = () => {
            NotificationManager.error(
                "Error getting current user info",
                "Error",
                4000
            );
        };
        Honkio()
            .mainShop.userFetch("consumerget", options, handleAuthError)
            .then((res) => setInfo(res));
    }, []);

    useEffect(() => {
        console.log(`info = `, info);
    }, [info]);

    return (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Reservation on behalf of customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SmartForm onSubmit={onSubmit} defaultValues={order}>
                    <label className="multiline">{order.title}</label>
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="customer_info.firstname"
                        label="First name"
                        required={true}
                        type="text"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="customer_info.lastname"
                        label="Last name"
                        required={true}
                        type="text"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="customer_info.phone"
                        label="Phone"
                        required={true}
                        type="text"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="customer_info.email"
                        label="Email"
                        required={true}
                        type="email"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="customer_info.ssn"
                        label="SSN"
                        title="Social Security Number"
                        required={true}
                        type="text"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="start_date"
                        label="Start of reservation"
                        required={true}
                        type="date"
                        additional="this date is required"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="end_date"
                        label="End of reservation"
                        type="date"
                        required={true}
                        additional="this date is required"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="description"
                        label="Description"
                        required={true}
                        type="text"
                    />
                    <button
                        color="secondary"
                        type="submit"
                        ref={buttonRef}
                        style={{ display: "none" }}
                    ></button>
                </SmartForm>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={loading}
                    onClick={handleSubmitClick}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReserveOnBehalfModal;
