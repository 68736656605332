import Honkio from "../Honkio";

const getSSN = (options = {}, errorHandler = {}) => {
    const { order_id, debug } = options;
    return Honkio().mainShop.userFetch(
        "ordergetssn",
        {
            debug,
            order_id,
        },
        errorHandler
    );
};
export default getSSN;
