import Honkio from '../Honkio';

const getConsumerList = (options = {}, errorHandler = {}) => {
  const { debug, query_skip, query_count, appid, merchantId } = options;
  return Honkio().mainShop.userFetch('consumerlist', {
    debug,
    appid,
    query_merchant: merchantId,
    query_count,
    query_skip,
  }, errorHandler);
};

export default getConsumerList;
