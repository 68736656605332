import Honkio from '../Honkio';

const setMerchantData = async function ({
    id,
    merchant,
    adminuser,
    appid,
    delete: del,
}, errorHandler = {}) {
    const options = {
        id,
        merchant: merchant,
        adminuser: adminuser,
        app_id: appid,
        delete: del,
    };
    let consumer = await Honkio().mainShop.userFetch('consumerget', {}, errorHandler);
    if (consumer && consumer.status === 'accept') {
        return Honkio()
            .mainShop.userFetch('merchantset', {
                ...options,
                login_identity: consumer.login_identity,
            }, errorHandler)
    } else {
        return
    }

};

export default setMerchantData;
