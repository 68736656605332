import genPasswordByPolicy from '../../utils/genPassword';
import SmartForm from '../Form/SmartForm';
import InputRange from './InputRange';

const PasswordPolicyEdit = ({
  state,
  onChange,
  className,
  onSubmit,
  readyToSubmin,
}) => {
  return (
    <SmartForm onSubmit={onSubmit} className={className}>
      <div className="form-group">
        <InputRange
          name="length.min"
          min="0"
          max="64"
          step="1"
          value={state.length.min}
          label={() => `Password length (${state.length.min})`}
          onChange={onChange('length', 'min')}
        />
        <InputRange
          name="characters.uppercase"
          min="0"
          max="16"
          step="1"
          value={state.characters.uppercase}
          label={() =>
            `Uppercase characters required (${state.characters.uppercase})`
          }
          onChange={onChange('characters', 'uppercase')}
        />
        <InputRange
          name="characters.digits"
          min="0"
          max="16"
          step="1"
          value={state.characters.digits}
          label={() => `Digits required (${state.characters.digits})`}
          onChange={onChange('characters', 'digits')}
        />
        <InputRange
          name="characters.symbols"
          min="0"
          max="16"
          step="1"
          value={state.characters.symbols}
          label={() =>
            `Special characters required (${state.characters.symbols})`
          }
          onChange={onChange('characters', 'symbols')}
        />
      </div>
      <div className="d-flex flex-column align-items-center bg-light rounded shadow-sm m-3">
        <h4>Password example</h4>
        <div className="h5 text-monospace">
          {genPasswordByPolicy(state) || (
            <span className="text-danger">
              No way, password shall not be empty!
            </span>
          )}
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-block btn-primary"
        disabled={!readyToSubmin}
      >
        Save
      </button>
    </SmartForm>
  );
};

export default PasswordPolicyEdit;
