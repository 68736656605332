import { applyMiddleware, compose, configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/rootReducer";
import { SET_APP_MERCHANTS } from "./types";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["test"],
};

const DEVTOOLS_ENABLED = true;

const composeEnhancers =
    (process.env.NODE_ENV !== "production" &&
        DEVTOOLS_ENABLED &&
        typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore(
    {
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    },
    composeEnhancers(applyMiddleware())
);

persistStore(store);

export default store;
