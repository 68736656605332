import { useCallback, useEffect, useReducer } from 'react';
import getScripts from '../../api/utils/getScripts';
import useSearchString from '../../utils/useSearchString';
import Editor from './Editor';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';

const init = (initialState) => initialState;

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'update':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const ScriptEdit = () => {
  const { script } = useSearchString();
  const [state, dispatch] = useReducer(reducer, {}, init);
  const onSubmit = useCallback((data) => console.log(data), []);
  useEffect(() => {
    getScripts({ id: script })
      .then(({ report }) => report)
      .then((report) => {
        const { text, name, type, active, debug, applications } = report;
        dispatch({
          type: 'update',
          payload: { text, name, type, active, debug, applications },
        });
      })
      .catch(console.error);
  }, [script]);

  return (
    <SmartForm
      defaultValues={state}
      className="container h-100"
      onSubmit={onSubmit}
    >
      <Editor name="text" />
      <fieldset className="container p-3">
        <div className="row">
          <div className="col-5">
            <InputFieldWithValidation name="name" type="text" label="Name" />
          </div>
          <div className="col-5">
            <SelectFieldWithValidation
              name="type"
              label="Type"
              options={[
                { name: 'Scheduler script', value: 'scheduler' },
                { name: 'Report', value: 'report' },
                { name: 'API event', value: 'event' },
              ]}
            />
          </div>
          <div className="col-2">
            <div className="row row-cols-1">
              <CheckboxSwitch name="active" label="Active" />
              <CheckboxSwitch name="debug" label="Debug" />
            </div>
          </div>
        </div>
      </fieldset>
      <button type="submit" className="btn btn-block btn-primary mt-3">
        Save
      </button>
    </SmartForm>
  );
};

export default ScriptEdit;
