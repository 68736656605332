import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timegrid from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Col, Container, Row } from "shards-react";
import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import "moment/locale/fi";
import CardWrapperComponent from "../../utils/CardWrapperComponent";
import getReservationList from "../../api/utils/getReservationList";
import ReservationDeleteModel from "../Model/ReservationDeleteModel";
import deleteReservation from "../../api/utils/deleteReservation";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import LoadingBar from "../UI/LoadingBar";
import useLoader from "../UI/helpers/useLoader";
import { useLocation, useHistory } from "react-router-dom";
import getAsset from "../../api/utils/getAsset";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import getAdminShop from "../../api/utils/getAdminShop";
import Select from "react-select";
import { MERCHANT_ID } from "../../config";
import getAdminShopList from "../../api/utils/getAdminShopList";
import ReactTooltip from "react-tooltip";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TrailerWorkingDays = () => {
    const history = useHistory();
    const location = useLocation();
    let query = useQuery();

    const [assetId, setAssetId] = useState();
    const [posId, setPosId] = useState();

    const [trailer, setTrailer] = useState();
    const [pos, setPos] = useState();
    const [posList, setPosList] = useState();

    const [reservation, setReservation] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [orderId, setOrderId] = useState();

    const [loaded, setLoaded] = useState(true);
    const [reservationsLoaded, setReservationsLoaded] = useState(false);
    const [progress, done, setDone] = useLoader([loaded, reservationsLoaded]);

    useEffect(() => {
        if (moment) window.moment = moment;
    }, []);

    useEffect(() => {
        if (!loaded || !reservationsLoaded) setDone(false);
    }, [loaded, reservationsLoaded]);

    useEffect(() => {
        const asset_id = query.get("asset");
        const pos_id = query.get("shopId");

        if (asset_id) setAssetId(asset_id);
        if (pos_id) setPosId(pos_id);
    }, [query]);

    useEffect(() => {
        if (assetId) fetchTrailer(assetId);
    }, [assetId]);

    useEffect(() => {
        if (posId) fetchPos(posId);
        if (!assetId) fetchPosList();
    }, [posId, assetId]);

    const fetchTrailer = async (id) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setReservationsLoaded(true);
        };

        const res = await getAsset({ id }, errorHandler);
        if (res && res?.status === "accept") {
            setTrailer(res.asset);
        }
    };

    const fetchPos = async (id) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const res = await getAdminShop({ id }, errorHandler);
        if (res && res?.status === "accept") {
            setPos(res.shop);
        }
    };

    const fetchPosList = async () => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const res = await getAdminShopList(
            {
                page: 0,
                query_count: 100,
                merchant: MERCHANT_ID,
            },
            errorHandler
        );
        if (res && res?.status === "accept") {
            setPosList(res.shops);
        }
    };

    const handleCloseConfirmDelete = async () => {
        setShowConfirmModal(false);
        setLoaded(false);
        setReservationsLoaded(false);

        const resultHandler = () => {
            NotificationManager.success("Reservation deleted");
            setLoaded(true);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setLoaded(true);
        };

        const res = await deleteReservation(orderId, errorHandler);

        if (res && res?.status === "accept") {
            await initAreaReservationsSource();

            resultHandler();
        }
    };

    const initAreaReservationsSource = async () => {
        setReservationsLoaded(false);

        const resultHandler = (data) => {
            setReservationsLoaded(true);

            const allReservations = data?.reservation
                .map((item) => {
                    try {
                        let res_reservations = [...item.reservations];

                        res_reservations = res_reservations.map((r) => ({
                            ...r,
                            asset: item?.asset,
                        }));
                        return res_reservations;
                    } catch (error) {
                        console.log("error: ", error);
                        return item?.reservations || [];
                    }
                })
                .flat(1);

            const eventsData = allReservations.map((item) => ({
                title: item.order_id,
                start: moment
                    .unix(item?.start_time)
                    .utc()
                    .format("YYYY-MM-DD HH:mm")
                    .toLocaleString(),
                end: moment
                    .unix(item?.end_time)
                    .utc()
                    .format("YYYY-MM-DD HH:mm")
                    .toLocaleString(),
                asset: item?.asset,
                user_info: item?.user_info,
            }));
            setReservation(eventsData);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setReservationsLoaded(true);
        };

        const res = await getReservationList(posId, assetId, errorHandler);
        if (res && res?.status === "accept") {
            resultHandler(res);
        }
    };
    const getEventTimeFormat = () => {
        return {
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
        };
    };

    const onEventClick = (e) => {
        const current_order_id = e?.event?._def?.title;
        setOrderId(current_order_id);
        setShowConfirmModal(true);
    };

    useEffect(() => {
        initAreaReservationsSource();
    }, [assetId, posId]);

    const handleLoadAllForPos = () => {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has("asset")) {
            queryParams.delete("asset");
            history.replace({
                search: queryParams.toString(),
            });
            setAssetId(null);
            setTrailer(null);
        }
    };

    const handleChangePos = (val) => {
        history.push(`/assets/trailerWorkingDays?shopId=${val?._id}`);
    };

    function renderEventContent(eventInfo) {
        return (
            <>
                <div
                    className="overflow-hidden"
                    data-tip
                    data-for={`event-item-${eventInfo?.event?._def?.title}-${eventInfo?.order_id}`}
                >
                    <b>{eventInfo?.timeText}</b>{" "}
                    <i>
                        {eventInfo?.event?._def?.extendedProps?.asset?.name ||
                            eventInfo?.event?._def?.title}
                    </i>
                </div>
                <ReactTooltip
                    id={`event-item-${eventInfo?.event?._def?.title}-${eventInfo?.order_id}`}
                    place="top"
                    effect="solid"
                >
                    <ListGroup variant="flush">
                        <ListGroup.Item
                            style={{ backgroundColor: "transparent" }}
                            className="text-white"
                        >
                            {eventInfo?.event?._def?.extendedProps?.asset
                                ?.name || eventInfo?.event?._def?.title}
                        </ListGroup.Item>
                        <ListGroup.Item
                            style={{ backgroundColor: "transparent" }}
                            className="text-white"
                        >
                            {
                                eventInfo?.event?._def?.extendedProps?.user_info
                                    ?.firstname
                            }{" "}
                            {
                                eventInfo?.event?._def?.extendedProps?.user_info
                                    ?.lastname
                            }
                        </ListGroup.Item>
                        <ListGroup.Item
                            style={{ backgroundColor: "transparent" }}
                            className="text-white"
                        >
                            {
                                eventInfo?.event?._def?.extendedProps?.user_info
                                    ?.email
                            }
                        </ListGroup.Item>
                        <ListGroup.Item
                            style={{ backgroundColor: "transparent" }}
                            className="text-white"
                        >
                            {
                                eventInfo?.event?._def?.extendedProps?.user_info
                                    ?.phone
                            }
                        </ListGroup.Item>
                    </ListGroup>
                </ReactTooltip>
            </>
        );
    }

    return (
        <>
            <NotificationContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">
                        {trailer ? (
                            <h2
                                title={`Licence plate: ${trailer?.properties?.license_number}`}
                            >
                                {trailer?.name}
                            </h2>
                        ) : null}
                        {!trailer && pos ? (
                            <h2>{pos?.settings?.str_name} - All</h2>
                        ) : null}
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                        {pos && trailer ? (
                            <Button
                                onClick={handleLoadAllForPos}
                                disabled={!trailer}
                            >
                                Show all for {pos?.settings?.str_name}
                            </Button>
                        ) : null}
                        {!trailer && posList?.length ? (
                            <Select
                                options={posList}
                                getOptionLabel={(option) =>
                                    option?.settings?.str_name
                                }
                                getOptionValue={(option) => option?._id}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 2,
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        width: "max-content",
                                        minWidth: "100%",
                                        position: "absolute",
                                        right: 0,
                                    }),
                                }}
                                value={pos}
                                onChange={handleChangePos}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {done ? (
                <CardWrapperComponent>
                    <FullCalendar
                        locales={allLocales}
                        locale={actualLocale}
                        defaultView="dayGridMonth"
                        header={{
                            left: "prev,next",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        plugins={[dayGridPlugin, timegrid, interactionPlugin]}
                        eventTimeFormat={getEventTimeFormat()}
                        events={reservation}
                        eventClick={onEventClick}
                        displayEventEnd={true}
                        eventContent={renderEventContent}
                    />
                    <Container className="w-100 m-3 p-3">
                        <Row>
                            <Col></Col>
                        </Row>
                    </Container>
                </CardWrapperComponent>
            ) : (
                <LoadingBar progress={progress} />
            )}
            {orderId ? (
                <ReservationDeleteModel
                    id={orderId}
                    modal={showConfirmModal}
                    onModalClose={() => {
                        setShowConfirmModal(false);
                    }}
                    handleCloseConfirmDelete={handleCloseConfirmDelete}
                />
            ) : null}
        </>
    );
};

export default TrailerWorkingDays;
