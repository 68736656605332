import { useState, useEffect } from 'react';
import clsx from 'clsx';
import GridLayout from 'react-grid-layout';
import Stub from '../UI/Stub';
import Widget from './Widget';
import RevenueByMusicFormat from './widgets/RevenueByMusicFormat';
import dataset from '../../__fixtures__/data.csv';
import BarChart from './widgets/BarChart';
import getMerchantsInvoicesReport from '../../api/utils/getMerchantsInvoicesReport';

const classes = clsx('card bg-white shadow-sm');
const Grid = ({ rows = 6, cols = 6, width = 1200, height = 900 }) => {
  const [payments, setPayments] = useState();
  useEffect(() => {
    getMerchantsInvoicesReport()
      .then((data) => {
        const { payment_invoices: payments, merchants } = data;
        return {
          merchants,
          payments: payments.map((period) => {
            const { timestamp } = period;
            const filtered = Object.entries(period)
              .filter(([key]) => key !== 'timestamp')
              .filter(([_key, { sum }]) => sum !== 0)
              .filter(([_key, { type }]) => type === 'subscription')
              .reduce((acc, [key, { sum }]) => ({ ...acc, [key]: sum }), {});
            // .reduce((acc, key) => acc + period[key].sum, 0);
            const total = Object.values(filtered).reduce(
              (sum, item) => sum + item,
              0
            );
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth();

            return {
              ...filtered,
              timestamp: `${year}/${month}`,
              total,
            };
          }),
        };
      })
      .then(setPayments)
      .catch(() => setPayments(null));
  }, []);

  return (
    <GridLayout
      className="layout"
      rows={rows}
      cols={cols}
      width={width}
      height={height}
    >
      <div className={classes} key="a" data-grid={{ x: 0, y: 0, w: 1, h: 2 }}>
        <Stub>Hello, widget!</Stub>
      </div>
      <div
        className={classes}
        key="b"
        data-grid={{ x: 1, y: 0, w: 4, h: 2, minW: 2, maxW: 4 }}
      >
        {payments?.payments?.length ? (
          <Widget
            data={payments}
            presentation={BarChart}
            options={{ margin: { top: 30, bottom: 30, left: 70, right: 30 } }}
          />
        ) : (
          <div>
            <div className="alert alert-info m-0">Bar chart</div>
            <div className="alert alert-warning m-0">No data to display</div>
          </div>
        )}
      </div>
      <div className={classes} key="c" data-grid={{ x: 0, y: 2, w: 6, h: 2 }}>
        <Widget data={dataset} presentation={RevenueByMusicFormat} />
      </div>
      {/* <div className={classes} key="d" data-grid={{ x: 0, y: 4, w: 4, h: 2 }}>
        <Widget data={dataset_2} presentation={StackedAreaChart} />
      </div> */}
    </GridLayout>
  );
};

export default Grid;
