import useSearchString from '../../utils/useSearchString';
import { useEffect, useState } from 'react';
import getAppInfo from '../../api/utils/getAppInfo';
import setServerAppInfo from '../../api/utils/setServerAppInfo';
import AppInfoForm from './AppInfoForm';
import { NotificationManager, NotificationContainer } from "react-notifications";
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import fillAppInfoRegistrationRequirements from './helpers/fillAppInfoRegistrationRequirements';

const ApplicationInfo = () => {
    const [appInfo, setAppInfo] = useState();
    const [language, setLanguage] = useState('en');
    const [editorData, setEditorData] = useState('');
    const [multiLanguageEditorData, setMultiLanguageEditorData] = useState({});
    const { appid } = useSearchString();
    const [progress, done] = useLoader([appInfo]);
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        fetchAppInfo()
    }, [appid]);

    const setAppInfoData = (appInfoData) => {
        const appInfo = fillAppInfoRegistrationRequirements(appInfoData)
        setAppInfo(appInfo)
    }

    const fetchAppInfo = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setAppInfoData(data.application)
                }
            } else {
                setAppInfo({})
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setAppInfo({})
        }

        if (appid) {
            getAppInfo({ appid }, errorHandler)
                .then(data => {
                    return resultHandler(data)
                })
                .catch(console.error);
        } else {
            setAppInfo({})
        }
    }

    useEffect(() => {
        if (appInfo && language) {
            setEditorData(
                multiLanguageEditorData[language] ??
                appInfo?.settings?.['str_tou_template__' + language] ??
                ''
            );
        }
    }, [appInfo, language]); // should use useReducer() here

    useEffect(() => {
        if (language && editorData) {
            setMultiLanguageEditorData((data) => ({
                ...data,
                [language]: editorData,
            }));
        }
    }, [editorData]); // should use useReducer() here

    const onSubmit = (data, registrationRequirements, oauthAllowedURIList, oauthRedirectURIList) => {
        const { fields: fields1, ...restAppInfo } = appInfo //remove all fields from appInfo
        const { fields, ...rest } = data
        const custom_registration_requirements = fields ? fields.map(e => {
            return {
                id: e.id,
                name: e.name,
                mandatory: e.mandatory === 'yes' ? true : false,
                type: e.type,
                required: true
            }
        }) : []


        const tou_arr = Object.entries(multiLanguageEditorData).map(([key, value]) => (
            {
                key: 'str_tou_template__' + key,
                value: value
            }
        ));
        const tou = tou_arr.reduce(
            (obj, item) => ({
                ...obj,
                [item.key]: item.value
            }),
            {}
        );
        const appInfoData = {
            ...restAppInfo,
            ...rest,
            settings: {
                ...rest.settings,
                ...tou,
                list_oauth_redirect_uri: oauthRedirectURIList,
                list_oauth_allowed_uri: oauthAllowedURIList,
                merchant_registration_requirements: [...registrationRequirements, ...custom_registration_requirements]
            },
        }

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAppInfoData(data.application)
                NotificationManager.success("Application saved", "Success", 4000)

            } else {
                NotificationManager.error("Unknown error", "Error", 4000)
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        setServerAppInfo({ id: appid, ...appInfoData }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    };

    const onChangeRegistrationRequirements = (data) => {
        const appInfoRR = appInfo.settings.merchant_registration_requirements
        const newAppInFoRR = appInfoRR.map(e => {
            if (e.id === data.id) {
                return {
                    id: data.id,
                    required: data.required,
                    name: data.name,
                    mandatory: data.mandatory,
                    type: data.type
                }
            } else {
                return e
            }
        })
        const setting = Object.assign({}, appInfo.settings, { merchant_registration_requirements: newAppInFoRR })
        setAppInfo(Object.assign({}, appInfo, { settings: setting }))
    };

    return (
        <div>
            <NotificationContainer />
            <h2>Application Info</h2>
            {done ? (
                appInfo?._id ?
                    <AppInfoForm
                        appInfo={appInfo}
                        setAppInfo={setAppInfo}
                        editorData={editorData}
                        setEditorData={setEditorData}
                        language={language}
                        setLanguage={setLanguage}
                        onSubmit={onSubmit}
                        onRequirementsClick={onChangeRegistrationRequirements}
                    /> : 'not found'
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default ApplicationInfo;
