import { APPLICATION_ID } from "../../config";
import { SET_AUTH_INFO } from "../types";
import { getAppMerchantsList } from "./appMerchantActions";

export const setAuthInfo = (info) => async (dispatch) => {
    dispatch({
        type: SET_AUTH_INFO,
        payload: info,
    });
};
