import { useEffect, useState } from 'react';
import { extraPropertiesToFields } from './helpers/form';
import Render from './Render';

const RoleForm = ({ role, appid, language }) => {
    const [commonPropertiesFields, setCommonPropertiesFields] = useState();
    const [publicPropertiesFields, setPublicPropertiesFields] = useState();
    const [privatePropertiesFields, setPrivatePropertiesFields] = useState();
    const [access, setAccess] = useState();
    const [defaultValues, setDefaultValues] = useState();

    useEffect(() => {
        if (!role) return;
        const {
            name,
            property,
            private_properties,
            public_properties,
            object_access,
        } = role;
        const [display_name, description] = language
            ? [role['display_name__' + language], role['description__' + language]]
            : [role['display_name'], role['description']];
        setAccess(object_access);
        setCommonPropertiesFields({ name, description, display_name, property });
        setPublicPropertiesFields(extraPropertiesToFields(public_properties));
        setPrivatePropertiesFields(extraPropertiesToFields(private_properties));
    }, [role, language]);

    useEffect(() => {
        if (
            [
                commonPropertiesFields,
                publicPropertiesFields,
                privatePropertiesFields,
                access,
            ].every(Boolean)
        ) {
            setDefaultValues({
                ...commonPropertiesFields,
                __public: publicPropertiesFields,
                __private: privatePropertiesFields,
                access,
            });
        }
    }, [
        commonPropertiesFields,
        publicPropertiesFields,
        privatePropertiesFields,
        access,
    ]);

    return defaultValues ? (
        <Render
            defaultValues={defaultValues}
            language={language}
            role={role}
            appid={appid}
        />
    ) : null;
};

export default RoleForm;
