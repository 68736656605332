import { useEffect, useMemo, useState, useContext } from "react";
import moment from "moment";
import { getAssets } from "../../api/utils/getAssets";
import useSearchString from "../../utils/useSearchString";
import Map from "../Map/Map";
import AnySelector from "../UI/AnySelector";
import searchFilterIfConsist from "../UI/helpers/searchFilterIfConsist";
import typifyIfEquals from "../UI/helpers/typifyIfEquals";
import AssetList from "./AssetList";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MERCHANT_ID } from "../../config";
import getAdminShopList from "../../api/utils/getAdminShopList";
import { deleteAsset } from "../../api/utils/setAssetData";
import { Modal, Button } from "react-bootstrap";
import orderCreate from "./../../api/utils/orderCreate";
import prepareToPublishMaintenanceOrder from "../Orders/helpers/prepareToPublishMaintenanceOrder";
import prepareToPublishReservationOnBehalfOrder from "../Orders/helpers/prepareToPublishReservationOnBehalfOrder";
import MaintenanceModal from "../Orders/modals/MaintenanceModal";
import ReserveOnBehalfModal from "../Orders/modals/ReserveOnBehalfModal";
import AssetTypesListModal from "./AssetTypesListModal";
import getAssetTypeList from "../../api/utils/getAssetTypeList";
import UserInfoContext from "../../contexts/UserInfoContext";
import { useLocation } from "react-router-dom";
import reservationCreate from "../../api/utils/reservationCreate";
import reservationCheck from "../../api/utils/reservationCheck";
import reserveOnBehalf from "../../api/utils/reserveOnBehalf";
import { useSelector } from "react-redux";

const colsToSearch = ["name"];
const typedCol = "visible";

const Assets = () => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const [assetList, setAssetList] = useState([]);
    const [assetListTest, setAssetListTest] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [selected, setSelected] = useState({});
    const [keyword, setKeyword] = useState("");
    const [type, setType] = useState("");
    const [ready, setReady] = useState(false);
    const { shop, shopname } = useSearchString();
    const [showAssetTypeListModal, setShowAssetTypeListModal] = useState(false);
    const handleShowAssetTypeListModal = () => {
        setShowAssetTypeListModal(true);
    };
    const handleCloseAssetTypeListModal = () =>
        setShowAssetTypeListModal(false);
    const pageTitle = `Trailers ${shopname ? `(${shopname})` : ""}`;

    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState(false);
    const handleShowConfirmRemoveAsset = () => setShowConfirmRemoveAsset(true);
    const handleCloseConfirmRemoveAsset = () =>
        setShowConfirmRemoveAsset(false);
    const [assetToRemove, setAssetToRemove] = useState({});
    const history = useHistory();
    const [showMaintainAsset, setShowMaintainAsset] = useState(false);
    const handleShowMaintainAsset = () => setShowMaintainAsset(true);
    const handleCloseMaintainAsset = () => setShowMaintainAsset(false);
    const [showReserveAsset, setShowReserveAsset] = useState(false);
    const handleShowReserveOnBehalfAsset = () => setShowReserveAsset(true);
    const handleCloseReserveOnBehalfAsset = () => setShowReserveAsset(false);
    const userInfo = useContext(UserInfoContext);
    const [maintenanceOrder, setMaintenanceOrder] = useState();
    const [reserveOnBehalfOrder, setReserveOnBehalfOrder] = useState();
    const [assetTypeList, setAssetTypeList] = useState();
    const [assetList2, setAssetList2] = useState();

    const [isCreatingOrder, setCreatingOrder] = useState(false);

    const location = useLocation();
    const appid = userInfo.app ? userInfo.app.id : null;
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (!selectedMerchant?._id) return;
        setReady(false);
        fetchShops();
        fetchAssets();
        fetchAssetTypes();
    }, [selectedMerchant]);

    const fetchAssets = async () => {
        const resultHandler = (data) => {
            setAssetListTest(data.assets);
            const arr = [];
            data?.assets?.map((i) => {
                if (i?.pos === shop) {
                    arr.push(i);
                }
            });
            setAssetList2(arr);
            if (data && data.status === "accept") {
                if (isMounted) {
                    let assets = data.assets;
                    if (shop) {
                        assets = assets.filter((asset) => asset.pos === shop);
                    }
                    setAssetList(assets);
                    setReady(true);
                }
            } else {
                NotificationManager.error("Error");
                setAssetList([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList([]);
            setReady(true);
        };
        getAssets(
            {
                merchant: selectedMerchant?._id,
                // query_properties: {
                //     shop: { eq: shop },
                // },
                query_pos: shop,
                page: 0,
                query_count: 100,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const fetchShops = async (page) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setShopList(data.shops);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        if (isMounted) {
            getAdminShopList(
                {
                    page: 0,
                    query_count: 100,
                    merchant: MERCHANT_ID,
                },
                errorHandler
            ).then((data) => {
                return resultHandler(data);
            });
        }
    };
    const displayedAssets = useMemo(() => {
        const typifiedMerchants = searchFilterIfConsist(
            shop ? assetList2 : assetListTest,
            colsToSearch,
            keyword
        );
        return typifyIfEquals(typifiedMerchants, typedCol, type);
    }, [shop, assetList2, assetListTest, keyword, type]);

    const onDeleteClick = (id, name) => {
        setAssetToRemove({ id, name });
        handleShowConfirmRemoveAsset();
    };

    const onMainteinClick = (id, name, license_number, POS, reservable) => {
        setMaintenanceOrder({
            title: `Maintain Trailer ${name}`,
            status: "on_maintenance",
            description: "",
            model: "maintenance",
            pos: POS,
            point_of_sales: POS,
            shop_id: POS,
            merchant: MERCHANT_ID,
            order_type: reservable ? "Reservation" : "",
            products: [],
            custom_fields: {
                asset: id,
                asset_name: name,
                asset_license_number: license_number,
            },
        });
        handleShowMaintainAsset();
    };

    const onReserveOnBehalfOfCustomer = (
        id,
        name,
        license_number,
        POS,
        reservable
    ) => {
        // TODO: Create order here using `reserved_behalf_of_customer` order model
        setReserveOnBehalfOrder({
            title: `Reserve on behalf of consumer\nTrailer "${name}"`,
            status: "initialized",
            description: "",
            model: "reserved_behalf_of_customer",
            pos: POS,
            point_of_sales: POS,
            shop_id: POS,
            merchant: MERCHANT_ID,
            order_type: reservable ? "Reservation" : "",
            products: [],
            custom_fields: {
                asset: id,
                asset_name: name,
                asset_license_number: license_number,
            },
        });
        handleShowReserveOnBehalfAsset();
    };

    const deleteSelectedAsset = () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Trailer removed", "Success", 4000);
                handleCloseConfirmRemoveAsset();
                fetchAssets();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        deleteAsset(assetToRemove.id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveAsset);
    };

    const checkDates = (start_date, end_date, max) => {
        // Allow reservation for `max` days maximum if `max` is set
        try {
            const dt1 = moment(start_date);
            const dt2 = moment(end_date);
            const diffDays = dt2.diff(dt1, "days");
            if (diffDays < 0) {
                NotificationManager.error(
                    "You specified negative range of dates",
                    "Error",
                    4000
                );
                return false;
            }
            if (max && diffDays > max) {
                NotificationManager.error(
                    "Reservation on behalf is available for 4 or less days only",
                    "Error",
                    4000
                );
                return false;
            }
            return true;
        } catch (error) {
            NotificationManager.error(
                "Error comparing start_date and end_date",
                "Error",
                4000
            );
            console.log(`Error comparing start_date and end_date`, error);
            return false;
        }
    };

    const submitMaintainOrder = async (formData) => {
        setCreatingOrder(true);
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newOrder = prepareToPublishMaintenanceOrder(
            maintenanceOrder,
            formData
        );

        const datesCorrect = checkDates(
            newOrder?.start_date,
            newOrder?.end_date
        );
        if (!datesCorrect) {
            setCreatingOrder(false);
            return;
        }

        const res_check = await reservationCheck({
            query_asset_id: newOrder?.custom_fields?.asset,
            query_start_date: newOrder?.start_date,
            query_end_date: newOrder?.end_date,
        });

        if (res_check?.status === "error") {
            NotificationManager.error(
                res_check?.message || "Already reserved. Try another date",
                "Error",
                4000
            );
            setCreatingOrder(false);
            return;
        }

        const res = await orderCreate(newOrder, errorHandler);
        if (res.status === "accept") {
            NotificationManager.success("Saved", "Success", 4000);
            setMaintenanceOrder();

            const res2 = await reservationCreate(
                { order_id: res.order._id },
                errorHandler
            );
            setCreatingOrder(false);
            if (res2.status === "accept") {
                NotificationManager.success(
                    "Reservation created",
                    "Success",
                    4000
                );
            } else {
                NotificationManager.success(
                    "Failed to create reservation",
                    "Error",
                    4000
                );
            }
        } else {
            NotificationManager.error(
                "Failed to create an order",
                "Error",
                4000
            );
            setCreatingOrder(false);
            setMaintenanceOrder();
        }
    };

    const submitReserveOnBehalfOrder = async (formData) => {
        setCreatingOrder(true);
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setCreatingOrder(false);
        };

        const newOrder = prepareToPublishReservationOnBehalfOrder(
            reserveOnBehalfOrder,
            formData
        );

        const datesCorrect = checkDates(
            newOrder?.start_date,
            newOrder?.end_date,
            4
        );
        if (!datesCorrect) {
            setCreatingOrder(false);
            return;
        }

        const res_check = await reservationCheck({
            query_asset_id: newOrder?.custom_fields?.asset,
            query_start_date: newOrder?.start_date,
            query_end_date: newOrder?.end_date,
        });

        if (res_check?.status === "error") {
            NotificationManager.error(
                res_check?.message || "Already reserved. Try another date",
                "Error",
                4000
            );
            setCreatingOrder(false);
            return;
        }

        const res = await reserveOnBehalf(newOrder, errorHandler);
        if (res.status === "accept") {
            NotificationManager.success("Saved", "Success", 4000);
            setReserveOnBehalfOrder();

            const res2 = await reservationCreate(
                { order_id: res.order._id },
                errorHandler
            );
            setCreatingOrder(false);
            if (res2.status === "accept") {
                NotificationManager.success(
                    "Reservation created",
                    "Success",
                    4000
                );
            } else {
                NotificationManager.success(
                    "Failed to create reservation",
                    "Error",
                    4000
                );
            }
        } else {
            NotificationManager.error(
                "Failed to create an order",
                "Error",
                4000
            );
            setCreatingOrder(false);
            setReserveOnBehalfOrder();
        }
    };

    const fetchAssetTypes = async () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (isMounted) {
                    setAssetTypeList(
                        data.asset_types.reduce(
                            (types, item) => [
                                ...types,
                                { name: item.name, value: item.name },
                            ],
                            []
                        )
                    );
                    setAssetTypeList(data.asset_types);
                }
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetTypeList([]);
        };

        getAssetTypeList({ query_application: appid }, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
        return () => {
            isMounted = false;
        };
    };
    const onAssetTypeSelected = (assetType) => {
        if (shop) {
            history.push(
                `/assets/create?assetType=${assetType._id}&shopname=${shopname}&shop_id=${shop}`,
                { data: location?.state?.data, shopId: shop }
            );
        } else {
            history.push(`/assets/create?assetType=${assetType._id}`, {
                data: location?.state?.data,
            });
        }
    };

    return (
        <div className="w-100 h-100">
            <NotificationContainer />
            <div className="container-fluid">
                <h2>{pageTitle}</h2>

                <Link
                    onClick={() => handleShowAssetTypeListModal()}
                    className="btn btn-outline-primary mt-1 mb-3"
                >
                    <i className="bi-plus-square ml-1 mr-2 " /> Create trailer
                </Link>

                <div className="row">
                    <div>
                        <AnySelector
                            label="Visibility"
                            items={[
                                { name: "Visible", value: "trueBool" },
                                { name: "Not visible", value: "falseBool" },
                            ]}
                            resetItem={{ name: "All", value: "" }}
                            nameSelector="name"
                            valueSelector="value"
                            setItem={setType}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {selectedMerchant?._id ? (
                            <AssetList
                                data={displayedAssets}
                                shops={shopList}
                                ready={ready}
                                selectItem={setSelected}
                                id={selected.id}
                                onDeleteClick={onDeleteClick}
                                onMainteinClick={onMainteinClick}
                                onReserveClick={onReserveOnBehalfOfCustomer}
                                emptyText={"The asset list is empty"}
                                shopname={shopname}
                                shopId={shop}
                            />
                        ) : (
                            <span>Please, select merchant</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="h-50 w-100">
                <Map
                    defaultCenter={{
                        lat: selected?.latitude ?? 0,
                        lng: selected?.latitude ?? 0,
                    }}
                />
            </div>
            <AssetTypesListModal
                assetTypeList={assetTypeList}
                show={showAssetTypeListModal}
                onAssetTypeSelected={onAssetTypeSelected}
                onHide={handleCloseAssetTypeListModal}
            ></AssetTypesListModal>
            {/* <div className="h-50 w-100">
                <Map
                    defaultCenter={{
                        lat: selected?.latitude ?? 0,
                        lng: selected?.latitude ?? 0,
                    }}
                />
            </div> */}
            <Modal
                show={showConfirmRemoveAsset}
                onHide={handleCloseConfirmRemoveAsset}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove trailer{" "}
                        <b>{assetToRemove ? assetToRemove.name : ""}</b>?{" "}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveAsset}
                    >
                        Close
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => deleteSelectedAsset()}
                    >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
            {maintenanceOrder && (
                <MaintenanceModal
                    show={showMaintainAsset}
                    onHide={handleCloseMaintainAsset}
                    order={maintenanceOrder}
                    onSubmit={submitMaintainOrder}
                    loading={isCreatingOrder}
                />
            )}
            {reserveOnBehalfOrder && (
                <ReserveOnBehalfModal
                    show={showReserveAsset}
                    onHide={handleCloseReserveOnBehalfAsset}
                    order={reserveOnBehalfOrder}
                    onSubmit={submitReserveOnBehalfOrder}
                    loading={isCreatingOrder}
                />
            )}
        </div>
    );
};

export default Assets;
