import Honkio from "../Honkio";

const setMerchantUserInvite = (
    { merchantId, role_id, email, message, ...rest },
    errorHandler = () => {}
) => {
    const params = {
        merchant: merchantId,
        role_id: role_id,
        email: email,
        message: message,
        ...rest,
        version: 3,
        action: "create",
    };
    return Honkio().mainShop.userFetch("consumerinvite", params, errorHandler);
};
export default setMerchantUserInvite;
