import { once } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import MerchantEditForm from './MerchantEditForm';
import {merchantCreate} from '../../api/utils/setMerchant';
import { NotificationManager, NotificationContainer } from "react-notifications";
import useSearchString from '../../utils/useSearchString';

const MerchantCreate = () => {
    const userInfo = useContext(UserInfoContext);
    const selectedAppId = userInfo.app?.id
    const history = useHistory();
    const {parent = null} = useSearchString();

    const onCreate = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Successfully saved")
                history.goBack();
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        data.parent = parent
        merchantCreate(selectedAppId, data, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    };

    return (
        <div>
            <MerchantEditForm onSubmit={onCreate} />
        </div>
    );
};

export default MerchantCreate;
