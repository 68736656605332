import Honkio from "../Honkio";

const getAssetList = (options = {}, errorHandler = {}) => {
    const {
        query_skip,
        query_count,
        merchant,
        debug,
        appid,
        query_search_latitude,
        query_search_longitude,
        query_distance,
        query_pickup_date,
        query_return_date,
        query_properties,
        role = "123",
        query_visible,
        query_reservable,
        query_pos,
        merchant_api_key,
    } = options;
    return Honkio().mainShop.userFetch(
        "assetlist",
        {
            query_skip,
            query_count,
            debug,
            query_application: appid,
            query_search_latitude,
            query_search_longitude,
            query_distance,
            query_merchant: merchant,
            query_pos,
            query_pickup_date,
            query_return_date,
            query_properties,
            role,
            query_visible,
            query_reservable,
            version: 3,
            action: "read",
            merchant_api_key,
        },
        errorHandler
    );
};

export { getAssetList };
