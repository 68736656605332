import Honkio from '../Honkio';
//replaced by getAdminRolesList
//    getAdminRole({ id: role_id })
//generally not used
const getMerchantRoles = (options = {}, errorHandler = {}) => {
  const {
    appid,
    query_translations = false,
    merchant,
    query_id,
    debug,
  } = options;
  return Honkio().mainShop.userFetch('getmerchantroles', {
    debug,
    appid,
    query_id,
    query_translations,
    query_access: 'all',
    merchant,
  }, errorHandler);
};
export default getMerchantRoles;
