import { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import appParse from "./helpers/appParse";
import getServerAppList from "../../api/utils/getServerAppList";
import AppTable from "./AppTable";
import useSearchString from "../../utils/useSearchString";
import appCompare from "./helpers/appCompare";
import InlineSearch from "../UI/InlineSearch";
import searchFilterIfConsist from "../UI/helpers/searchFilterIfConsist";
import typifyIfEquals from "../UI/helpers/typifyIfEquals";
import AnySelector from "../UI/AnySelector";
import UserInfoContext from "../../contexts/UserInfoContext";
import { useHistory } from "react-router-dom";
import ApplicationModal from "./ApplicationModal";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";

const AppSelector = () => {
    const [appList, setAppList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [type, setType] = useState("true");
    const { merchant = null, merchant_name = "" } = useSearchString();
    const userInfo = useContext(UserInfoContext);

    const history = useHistory();

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const merchantName = merchant
        ? merchant_name
        : userInfo.merchant
        ? userInfo.merchant.name
        : "";
    const pageTitle = `Applications ${merchantName ? `(${merchantName})` : ""}`;

    const [showApplicationPreview, setShowApplicationPreview] = useState(false);
    const handleShowApplicationPreview = () => setShowApplicationPreview(true);
    const handleCloseApplicationPreview = () =>
        setShowApplicationPreview(false);
    const [currentApplication, setCurrentApplication] = useState();

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            setAppList(data);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAppList([]);
        };
        getServerAppList(
            { query_all: true, merchant: merchantId, debug: true },
            errorHandler
        )
            .then(({ applications }) => applications.reduce(appParse, []))
            .then((applications) => {
                applications.sort(appCompare);
                return applications;
            })
            .then(resultHandler);
        //.catch(console.error);
    }, [merchantId, setAppList]);

    const onApplicationSelected = useCallback(
        ({ id, name }) => {
            if (id !== userInfo.app.id) {
                userInfo.setInfo({ app: { id, name }, merchant: {} });
                history.push(`/merchants?appid=${id}&appname=${name}`);
            }
        },
        [userInfo.app]
    );

    let onRowClick = (row) => {
        setCurrentApplication(row);
        handleShowApplicationPreview();
    };

    return (
        <div className="container-fluid">
            <h2>{pageTitle}</h2>
            <Link
                to={`/app/create`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create application
            </Link>
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
                <div>
                    <AnySelector
                        label="Filter by state"
                        items={[
                            { name: "Active only", value: "true" },
                            { name: "Disabled only", value: "false" },
                        ]}
                        resetItem={{ name: "Show all apps", value: "" }}
                        nameSelector="name"
                        valueSelector="value"
                        defaultValue={"true"}
                        setItem={setType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <AppTable
                        apps={appList}
                        selected
                        keyword={keyword}
                        type={type}
                        filterSearch={searchFilterIfConsist}
                        filterType={typifyIfEquals}
                        onApplicationSelected={onApplicationSelected}
                        onRowClicked={onRowClick}
                    />
                    {currentApplication && (
                        <ApplicationModal
                            applicationId={currentApplication.id}
                            show={showApplicationPreview}
                            onHide={handleCloseApplicationPreview}
                        ></ApplicationModal>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppSelector;
