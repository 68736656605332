import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { formDataToOptions } from './helpers/form';
import CommonProperties from './elements/CommonProperties';
import setAdminRole from './../../api/utils/setAdminRole';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Permissions from './elements/Permissions';
import { Modal, Button } from 'react-bootstrap';

const [privateGroupName, publicGroupName] = ['__private', '__public'];

const Render = ({ defaultValues, role, appid, language }) => {
    const history = useHistory();
    const [permissions, setPermissions] = useState();

    const [showConfirmRemoveRole, setShowConfirmRemoveRole] = useState(false)
    const handleShowConfirmRemoveRole = () => setShowConfirmRemoveRole(true);
    const handleCloseConfirmRemoveRole = () => setShowConfirmRemoveRole(false);
    const showConfirmDialog = () => {
        handleShowConfirmRemoveRole()
    }

    const { register, handleSubmit, control, reset } = useForm({
        defaultValues,
    });
    const {
        fields: fieldsPublic,
        append: appendPublic,
        remove: removePublic,
    } = useFieldArray({
        control,
        name: publicGroupName,
    });
    const {
        fields: fieldsPrivate,
        append: appendPrivate,
        remove: removePrivate,
    } = useFieldArray({
        control,
        name: privateGroupName,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                /*if (isMounted) {
                    setStructureList(data.assets.reduce(
                        (assetstructure, item) => [
                            ...assetstructure,
                            { name: item.name, value: item.id },
                        ],
                        []
                    ))
                }*/
                NotificationManager.success("Role saved")
                history.push( `/merchant/role`);
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        const multiLangCommonProperties = language
            ? Object.entries({ ...role })
                .filter(([key]) => {
                    return ['display_name', 'description'].includes(key.split('__')[0])
                }
                )
                .reduce((acc, [key, value]) => {
                    if (isArray(acc)) {
                        return { [acc[0]]: acc[1] }
                    }
                    else {
                        return ({ ...acc, [key]: value })
                    }
                })
            : {};
        const {
            description,
            display_name,
            name,
            group_code,
            property,
        } = data;
        const public_properties = formDataToOptions(data[publicGroupName]);
        const private_properties = formDataToOptions(data[privateGroupName]);
        const localLangCommonProperties = {};
        if (language) {
            localLangCommonProperties['display_name__' + language] = display_name;
            localLangCommonProperties['description__' + language] = description;
        } else {
            localLangCommonProperties['display_name'] = display_name;
            localLangCommonProperties['description'] = description;
        }
        const request = Object.assign(
            {},
            multiLangCommonProperties,
            localLangCommonProperties,
            {
                id: role._id,
                merchant: role.merchant,
                application: appid,
                name,
                property,
                object_access: permissions,
                group_code,
                public_properties,
                private_properties,
            }
        );
        function isArray(obj) {
            return !!obj && obj.constructor === Array;
        }
        setAdminRole(request, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };

    const onUpdatePermissions = (permissions) => {
        setPermissions(permissions)
    };

    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Role deleted")
                history.push( `/merchant/role`);
            } else {
                NotificationManager.error("Error")
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        setAdminRole({ id: role._id, delete: true }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };

    return (<div>
        <NotificationContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
            <CommonProperties
                header="Properties"
                register={register}
                className="col-md-6 col-lg-3"
            />
            <Permissions
                register={register}
                access={defaultValues.access}
                updatePermissions={onUpdatePermissions}
            />
            {/*             {!adminTag && (
                <PropertyTable
                    header="Public properties"
                    group={publicGroupName}
                    register={register}
                    fieldSet={fieldsPublic}
                    actions={[appendPublic, removePublic]}
                />
            )}
            {!adminTag && (
                <PropertyTable
                    header="Private properties"
                    group={privateGroupName}
                    register={register}
                    fieldSet={fieldsPrivate}
                    actions={[appendPrivate, removePrivate]}
                />
            )}
            */}
            <button type="submit" className="btn btn-primary">
                Submit
            </button>
            <button type="button" className="btn btn-danger disabled ml-3"
                onClick={() => {
                    showConfirmDialog()
                }}>
                Delete
            </button>
        </form>
        <Modal show={showConfirmRemoveRole} onHide={handleCloseConfirmRemoveRole}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to remove user group {role.name} ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseConfirmRemoveRole}>Close</Button>
                <Button variant="danger" onClick={() => onDelete()}>Remove</Button>
            </Modal.Footer>
        </Modal>
    </div>
    );
};

export default Render;
