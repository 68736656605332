const mapDataToGeoJSON = (assetData = []) => {
  return assetData.map((item) => {
    const { _id } = item;
    const {
        coordinates: [lat, lng],
    } = item;
    return {
      type: 'Feature',
      properties: { cluster: false, id: _id },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(lng), parseFloat(lat)],
      },
    };
  });
};

export default mapDataToGeoJSON;
