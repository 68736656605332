import { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import SmartForm from "./../../Form/SmartForm";
import InputFieldWithValidation from "./../../Form/elements/InputFieldWithValidation";

const OperatorReservationModal = ({ order, show, onHide, onSubmit }) => {
  const buttonRef = useRef(null);

  const handleSubmitClick = () => {
    buttonRef.current.click();
  };

  const onClickHide = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Set up information for Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SmartForm onSubmit={onSubmit} defaultValues={order}>
          <label className="multiline">{order.title}</label>
          <InputFieldWithValidation
            className="col-md-12"
            name="description"
            label="Reservation info"
            type="text"
          />
          <input
            onclick="return false"
            name="photoId"
            type="checkbox"
            checked={true}
          />
          <label className="font-weight-bold mr-3">Photo Id</label>
          <InputFieldWithValidation
            className="col-md-12"
            name="custom_fields.user_email"
            label="Customer email"
            required={true}
            type="email"
          />
          <InputFieldWithValidation
            className="col-md-12"
            name="custom_fields.person_id"
            label="Person Id"
            type="text"
          />
          <InputFieldWithValidation
            className="col-md-12"
            name="custom_fields.firstName"
            label="First Name"
            type="text"
          />

          <InputFieldWithValidation
            className="col-md-12"
            name="custom_fields.lastName"
            label="Last Name"
            type="text"
          />
          <InputFieldWithValidation
            className="col-md-12"
            name="custom_fields.phoneNo"
            label="Phone no"
            type="text"
          />
          <InputFieldWithValidation
            className="col-md-12"
            name="start_date"
            label="Start reservation"
            required={true}
            type="date"
            additional="this date is required"
          />
          <InputFieldWithValidation
            className="col-md-12"
            name="end_date"
            label="End reservation"
            type="date"
            required={true}
            additional="this date is required"
          />
          <button
            color="secondary"
            type="submit"
            ref={buttonRef}
            style={{ display: "none" }}
          ></button>
        </SmartForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSubmitClick}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OperatorReservationModal;
