// import i18n from 'i18next';
// import k from "./../../i18n/keys";
import React, { Component } from 'react';
import { defaultStyles, FileIcon } from "react-file-icon";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class FileBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fromDataToItems = this.fromDataToItems.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data !== undefined && nextProps.data !== null) {
      this.fromDataToItems(nextProps.data);
    }
  }

  fromDataToItems(data) {
    let that = this;
    let items = [];
    let imgExtensions = ['jpg', 'png']
    if (data.length > 0) {
      for (const [index, file] of data.entries()) {

        let size = (parseInt(file.metadata.size ? file.metadata.size : 0) / 1024).toFixed(2);
        size = size.length < 7 ? size + "Kb" : (size / 1024).toFixed(2) + "Mb";
        let title = `${file.display_name}.${file.extension}`;
        let uploaded = file.timestamp;
        let ext = file.extension;
        let filePreview = <FileIcon extension={ext} {...defaultStyles[ext]} />
        if (imgExtensions.indexOf(ext) >= 0 && this.props.preview) {
          filePreview = <img className="mb-0 preview" src={file.url} alt="loading..." onClick={() => that.props.onPreviewClick(file)} />
        }
        items.push(
        <div className="col-lg-3 col-xl-2">
                        <div className="file-man-box zoom-in">
                            <a href={null} onClick={() => that.props.onDelete(file)} className="file-close"><i className="fa fa-times-circle" /></a>
                            <div className="file-img-box">
                                {filePreview}
                            </div>
                            <a href="#" onClick={() => that.props.onDownload(file)} className="file-download"><i className="fa fa-download" /></a>
                            <div className="file-man-title">
                                <p id="title" data-text={title} className="mb-0 text-overflow file-man-text photo-man-text">{title}</p>
                                {/* <p id="size" className="mb-0"><small>{size}</small></p> */}
                                <p id="date" className="mb-0"><small>{uploaded}</small></p>
                            </div>
                        </div>
                    </div>);

      }
      this.setState({ 'items': items });
      return 0;
    }
    // const onUploadClick2 = () => {
    //   console.log
    //   that.props.onUploadClick()
    // }
    items.push(
    <div className="text-center container align-middle">
                <div className="jumbotron">
                    <h5 className="display-4 text-muted">THERE IS NO PICTURES</h5>
                    <p className="lead">WHEN YOU UPLOAD A IMAGE IT</p>
                    <hr className="my-4" />
                        <p className="lead">
                            <button className="btn btn-outline-primary btn-lg" onClick={() =>  that.props.onUploadClick()} role="button" data-bcup-haslogintext="no">UPLOAD IMAGE</button>
                        </p>
                </div>
            </div>);


    this.setState({ "items": items });
    return 0;
  }

  render() {
    let main = <div style={{ marginLeft: '45%' }}>
                       <CircularProgress size={75} />
                   </div>;

    if (this.state.items) {
      main = this.state.items;
    }

    return (
      <div className="row mx-auto">
                {main}
            </div>);

  }}