import HonkioAPI from "../Honkio";

const setOrderStatus = async function (Oid, status, Des, errorHandler) {
    let anonymous;
    if (status === "email_violation") {
        anonymous = true;
    }

    return HonkioAPI().mainShop.userFetch(
        "orderstatusset",
        {
            command: "orderstatusset",
            id: Oid,
            status: status,
            description: Des,
            anonymous: anonymous,
            version: 3,
            action: "update",
        },
        errorHandler
    );
};
export default setOrderStatus;
