import * as d3 from 'd3';

const RevenueByMusicFormat = (data, { node, height, width, margin }) => {
  const svg = d3.select(node);

  const colors = new Map([
    ['LP/EP', '#2A5784'],
    ['Vinyl Single', '#43719F'],
    ['8 - Track', '#5B8DB8'],
    ['Cassette', '#7AAAD0'],
    ['Cassette Single', '#9BC7E4'],
    ['Other Tapes', '#BADDF1'],
    ['Kiosk', '#E1575A'],
    ['CD', '#EE7423'],
    ['CD Single', '#F59D3D'],
    ['SACD', '#FFC686'],
    ['DVD Audio', '#9D7760'],
    ['Music Video (Physical)', '#F1CF63'],
    ['Download Album', '#7C4D79'],
    ['Download Single', '#9B6A97'],
    ['Ringtones & Ringbacks', '#BE89AC'],
    ['Download Music Video', '#D5A5C4'],
    ['Other Digital', '#EFC9E6'],
    ['Synchronization', '#BBB1AC'],
    ['Paid Subscription', '#24693D'],
    ['On-Demand Streaming (Ad-Supported)', '#398949'],
    ['Other Ad-Supported Streaming', '#61AA57'],
    ['SoundExchange Distributions', '#7DC470'],
    ['Limited Tier Paid Subscription', '#B4E0A7'],
  ]);

  const series = d3
    .stack()
    .keys(colors.keys())
    .value((group, key) => group.get(key).value)
    .order(d3.stackOrderReverse)(
      d3
        .rollup(
          data,
          ([d]) => d,
          (d) => d.year,
          (d) => d.name
        )
        .values()
    )
    .map(
      (s) => (
        s.forEach(
          (d) => (d.data = d.data.get(s.key))
          // eslint-disable-next-line no-sequences
        ),
        s
      )
    );

  const color = d3.scaleOrdinal().domain(colors.keys()).range(colors.values());

  const xAxis = (g) =>
    g.attr('transform', `translate(0,${height - margin.bottom})`).call(
      d3
        .axisBottom(x)
        .tickValues(d3.ticks(...d3.extent(x.domain()), width / 80))
        .tickSizeOuter(0)
    );

  const yAxis = (g) =>
    g
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickFormat((x) => (x / 1e9).toFixed(0)))
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .select('.tick:last-of-type text')
          .clone()
          .attr('x', 3)
          .attr('text-anchor', 'start')
          .attr('font-weight', 'bold')
          .text(data.y)
      );

  const x = d3
    .scaleBand()
    .domain(data.map((d) => d.year))
    .rangeRound([margin.left, width - margin.right]);

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
    .nice()
    .range([height - margin.bottom, margin.top]);

  const formatRevenue = (x) =>
    +(x / 1e9).toFixed(2) >= 1
      ? `${(x / 1e9).toFixed(2)}B`
      : `${(x / 1e6).toFixed(0)}M`;

  svg
    .append('g')
    .selectAll('g')
    .data(series)
    .join('g')
    .attr('fill', ({ key }) => color(key))
    .call((g) =>
      g
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('x', (d) => x(d.data.year))
        .attr('y', (d) => y(d[1]))
        .attr('width', x.bandwidth() - 1)
        .attr('height', (d) => y(d[0]) - y(d[1]))
        .append('title')
        .text(
          (d) => `${d.data.name}, ${d.data.year}
  ${formatRevenue(d.data.value)}`
        )
    );

  svg.append('g').call(xAxis);

  svg.append('g').call(yAxis);
};

export default RevenueByMusicFormat;
