import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import { NavDropdown } from "react-bootstrap";

const SidebarLinkWithChildren = (props) => {
    const { to, title, children } = props;
    return (
        <NavDropdown title={title}>
            {children.map((child, index) => (
                <NavDropdown.Item key={index}>{child}</NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

const SidebarLinkWithoutChildren = (props) => {
    const { to, title, children } = props;
    return (
        <li className="nav-item">
            <NavLink
                className={clsx(["nav-link", "w-100"])}
                activeClassName="bg-white shadow-sm"
                to={to}
                onClick={props.onClick}
            >
                {title}
            </NavLink>
        </li>
    );
};

const SidebarLink = (props) => {
    const { to, children, title } = props;
    if (children instanceof Array) {
        return (
            <SidebarLinkWithChildren to={to} title={title}>
                {children}
            </SidebarLinkWithChildren>
        );
    } else {
        return (
            <SidebarLinkWithoutChildren
                to={to}
                title={title}
            ></SidebarLinkWithoutChildren>
        );
    }
};

export default SidebarLink;
