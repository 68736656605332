import { useEffect, useState } from "react";
import useSearchString from "../../utils/useSearchString";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAdminOrder } from "./../../api/utils/getOrders";
import OrderMaintenanceForm from "./subcomponents/OrderMaintenanceForm";
import {
    ORDER_MODEL_RESERVE_BY_CUSTOMER,
    ORDER_MODEL_RESERVE_BY_OPERATOR,
    ORDER_MODEL_MAINTENANCE,
} from "../../config";
import OrderReservedByOperatorForm from "./subcomponents/OrderReservedByOperatorForm";
import { Link } from "react-router-dom";
import getAdminShopList from "../../api/utils/getAdminShopList";
import { MERCHANT_ID } from "../../config";

const OrderEdit = () => {
    const [orderInfo, setOrderInfo] = useState();
    const [shopList, setShopList] = useState([]);

    const { order } = useSearchString();

    const fetchOrder = async () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setOrderInfo(data.order);
                fetchShops(data.order);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        const params = {
            id: order,
        };
        getAdminOrder(params, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };
    const fetchShops = async (Item) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                const datas = data?.shops.find(
                    (shp) =>
                        shp?._id === Item?.shop_id ||
                        shp?.id === Item?.point_of_sales
                );
                setShopList(datas);
            } else {
                NotificationManager.error("Error");
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        getAdminShopList(
            {
                page: 0,
                query_count: 100,
                merchant: MERCHANT_ID,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };
    useEffect(() => {
        fetchOrder();
    }, [order]);

    const renderOrderContent = (model) => {
        switch (model) {
            case ORDER_MODEL_MAINTENANCE:
                return (
                    <OrderMaintenanceForm
                        order={orderInfo}
                        id={order}
                        shop={shopList}
                    />
                );
            case ORDER_MODEL_RESERVE_BY_OPERATOR:
                return (
                    <OrderReservedByOperatorForm
                        order={orderInfo}
                        id={order}
                        shop={shopList}
                    />
                );
            case ORDER_MODEL_RESERVE_BY_CUSTOMER:
                return (
                    <OrderReservedByOperatorForm
                        order={orderInfo}
                        id={order}
                        shop={shopList}
                    />
                );
            default:
                return "";
        }
    };
    // Name, lastname, phone, email, person id,
    const renderAssetContent = () => {
        return (
            <div>
                <Row className="pt-2 pb-2">
                    <Col md={3}>
                        <b>Trailer: </b>
                    </Col>
                    <Col md={9}>
                        <Link
                            to={`/assets/edit?asset=${orderInfo?.custom_fields?.asset}`}
                        >
                            {orderInfo?.custom_fields?.asset_name}
                        </Link>
                    </Col>
                </Row>
                <Row className="pt-2 pb-2">
                    <Col md={3}>
                        <b>License: </b>
                    </Col>
                    <Col md={9}>
                        {orderInfo?.custom_fields?.asset_license_number}
                    </Col>
                </Row>
            </div>
        );
    };
    return (
        <div>
            <NotificationContainer />
            {orderInfo?.custom_fields?.asset && renderAssetContent()}
            {orderInfo && renderOrderContent(orderInfo.model)}
        </div>
    );
};

export default OrderEdit;
