import HonkioAPI from "./../Honkio";

const reserveOnBehalf = (options = {}, errorHandler = {}) => {
    return HonkioAPI().mainShop.userFetch(
        "orderonbehalf",
        {
            ...options,
        },
        errorHandler
    );
};

export default reserveOnBehalf;
