import { useEffect, useState, useContext } from "react";
import useSearchString from "../../utils/useSearchString";
import prepareToPublish from "./helpers/prepareToPublish";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import UserInfoContext from "../../contexts/UserInfoContext";
import { createAsset } from "../../api/utils/setAssetData";
import AssetEditForm from "./AssetEditForm";
import getAssetType from "../../api/utils/getAssetType";
import useLoader from "../UI/helpers/useLoader";
import LoadingBar from "../UI/LoadingBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import getAdminShop from "../../api/utils/getAdminShop";

const AssetCreate = (props) => {
    const [selectedAssetType, setSelectedAssetType] = useState([]);
    const [assetInfo, setAssetInfo] = useState({});
    // const { assetType } = useSearchString();
    const history = useHistory();
    const [progress, done] = useLoader([assetInfo, selectedAssetType]);
    const { assetType, shopname, shop_id } = useSearchString();
    const userInfo = useContext(UserInfoContext);
    const merchantId = userInfo.merchant ? userInfo.merchant.id : null;
    const appid = userInfo.app ? userInfo.app.id : null;

    const [shopInfo, setShopInfo] = useState(null);

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (shop_id) {
            fetchShopInfo(shop_id);
        }
    }, [shop_id]);

    const fetchShopInfo = async (id) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data?.status === "accept" && data?.shop) {
                    setShopInfo(data.shop);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const res = await getAdminShop({ id }, errorHandler);
        if (res && res.status == "accept") {
            resultHandler(res);
        }
    };

    const onSave = (data) => {
        if (location?.state?.data) {
            const pos = location?.state?.data?._id;
            const co = location?.state?.data?.settings.coordinates;

            data.coordinates = co;
            data.pos = pos;
        }
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Asset saved", "Success", 4000);

                if (location?.state?.shopId && shopname) {
                    history.push(
                        `/assets?shop=${location?.state?.shopId}&shopname=${shopname}`
                    );
                } else {
                    history.push(`/assets`);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const assetData = prepareToPublish(assetInfo, data);

        createAsset(assetData.asset, merchantId, appid, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };
    useEffect(() => {
        if (assetType) {
            fetchAssetType();
        }
    }, [assetType]);

    const fetchAssetType = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept" && data.asset_type) {
                    setSelectedAssetType(data.asset_type);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAssetType({ id: assetType }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        setAssetInfo({
            type: selectedAssetType.name,
        });
    }, [selectedAssetType]);

    return (
        <div>
            <NotificationContainer />
            <h2>Create trailer for {shopname}</h2>
            {done ? (
                assetInfo ? (
                    <AssetEditForm
                        onSubmit={onSave}
                        defaultValues={assetInfo}
                        assetType={selectedAssetType}
                    />
                ) : (
                    "not found"
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default AssetCreate;
