const mapEntryToState = ({
  max_len,
  min_digits,
  min_len,
  min_lower,
  min_sym,
  min_upper,
}) => ({
  characters: {
    digits: min_digits,
    lowercase: min_lower,
    uppercase: min_upper,
    symbols: min_sym,
  },
  length: {
    min: min_len,
    max: max_len,
  },
});

const mapResponseToState = (response = {}) =>
  Object.entries(response).reduce(
    (carry, [key, value]) => ({ ...carry, [key]: mapEntryToState(value) }),
    {}
  );

export default mapResponseToState;
