const prepareInfo = (merchant, id, formData) => {
    const { settings, custom_fields } = formData;
    const shop = {
        id,
        merchant,
        settings,
        custom_fields,
    };
    return { ...shop };
};

export default prepareInfo;
