import { useFormContext } from 'react-hook-form';
import genPasswordByPolicy from '../../utils/genPassword';
import DangerAction from './DangerAction';

const PasswordChange = ({ setState, ...props }) => {
  const { reset } = useFormContext();
  const onClick = () => {
    setState((state) => {
      state.settings['str_password'] = genPasswordByPolicy({
        length: { min: 40 },
        characters: { digits: 4, uppercase: 4, symbols: 4 },
      });
      reset(state);
      return state;
    });
  };
  return <DangerAction {...props} onClick={onClick} />;
};

export default PasswordChange;
