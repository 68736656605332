import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Wait from "../UI/Wait";
import { Link } from "react-router-dom";
import Icon from "../UI/Icon";
import { MERCHANT_ID, APPLICATION_ID } from "../../config";
import ReactTooltip from "react-tooltip";
import { getAssets } from "../../api/utils/getAssets";
import { NotificationManager } from "react-notifications";

const ShopList = ({
    loading,
    data,
    rowsCountPerPage,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    onRowClicked,
    onDeleteClick = () => {},
    perPageChoices,
}) => {
    const { appid } = { APPLICATION_ID, MERCHANT_ID };

    const [assetListTot, setAssetListTot] = useState([]);

    useEffect(() => {
        fetchAssets();
    }, []);

    const fetchAssets = () => {
        const resultHandler = (data) => {
            setAssetListTot(data);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        getAssets(
            {
                merchant: MERCHANT_ID,
                query_properties: {
                    shop: {},
                },
                page: 0,
                query_count: 100,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };
    const columns = useMemo(
        () => [
            {
                name: "ID",
                grow: 2,
                cell: (row, index) => row?._id,
            },
            {
                name: "Name",
                sortable: true,
                selector: (row) => row.str_name,
                grow: 3,
                cell: ({ str_name }) => str_name,
            },
            {
                name: "Total Trailers",
                sortable: true,
                grow: 0,
                cell: (row) => {
                    return row?.query_assets_count || 0;
                },
            },
            {
                name: "Actions",
                right: true,
                grow: 2,
                cell: (row, _index, _column) => (
                    <div>
                        <Link
                            to={`/shop/edit?merchant=${row.merchant}&shop=${row._id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="btn bi-pen" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/shop/opentime?shop=${row._id}`}
                            data-tip
                            data-for="openTimeTip"
                        >
                            <Icon iconName="btn bi-clock" />
                            <ReactTooltip
                                id="openTimeTip"
                                place="top"
                                effect="solid"
                            >
                                Open time
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/shop/closedays?shop=${row._id}`}
                            data-tip
                            data-for="closeDaysTip"
                        >
                            <Icon iconName="bi bi-calendar4-week" />
                            <ReactTooltip
                                id="closeDaysTip"
                                place="top"
                                effect="solid"
                            >
                                Non-working days
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/shop/findtrailers?shop=${row._id}`}
                            data-tip
                            data-for="orderAdmin"
                        >
                            <Icon iconName="bi bi-cart" />
                            <ReactTooltip
                                id="orderAdmin"
                                place="top"
                                effect="solid"
                            >
                                Place order
                            </ReactTooltip>
                        </Link>
                        <span
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onDeleteClick(row._id);
                            }}
                        >
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        [appid]
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            paginationServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={onRowClicked}
            paginationTotalRows={totalRows}
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default ShopList;
