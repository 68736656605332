import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '../../config';
import Icon from '../UI/Icon';

const Marker = () => (
  <div className="position-absolute" style={{ top: -14, right: -10 }}>
    <Icon iconName="bi-plus-circle-dotted" />
  </div>
);

const Map = ({
  defaultCenter = {},
  defaultZoom = 1,
  marker = defaultCenter,
}) => {
  const { lng, lat } = marker;

  /* IMPORTANT 
    We should not display map in case we have lng and lat equal zeroes,
    because it means we have no location data.
  */
  return lng && lat ? (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
    >
      <Marker lng={lng} lat={lat} text="Hello" />
    </GoogleMapReact>
  ) : null
};

export default Map;
