import moment from 'moment';

const prepareToPublishReservationOrder = (order, formData) => {
  const {
    description,
    start_date,
    end_date,
    custom_fields
  } = formData;
  const result = {
    ...order,
    start_date: start_date ? moment(start_date).format('YYYY-MM-DD hh:mm:ss').toLocaleString() : '',
    end_date: end_date ? moment(new Date(end_date)).format('YYYY-MM-DD hh:mm:ss').toLocaleString() : '',
    description,
    custom_fields: {
        ...order.custom_fields,
        custom_fields
    }
  };
 
  return result;
};

export default prepareToPublishReservationOrder;
