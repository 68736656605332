import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import getPermissions from '../../api/utils/getPermissions';
import getServiceKey from '../../api/utils/getServiceKey';

const ServiceKeys = () => {
  const [permissions, setPermissions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [key, setKey] = useState();
  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      { name: 'Name', grow: 1, cell: (row) => row },
    ],
    []
  );

  useEffect(() => {
    setSelectedRows(permissions);
  }, [permissions]);

  const onRowSelect = (state) => {
    console.log(state);
    setSelectedRows(state.selectedRows);
  };

  const onCreate = () => {
    getServiceKey({ query_access: selectedRows })
      .then(({ key: { key } }) => key)
      .then(setKey)
      .catch(console.error);
  };

  useEffect(() => {
    getPermissions()
      .then(({ permissions }) => permissions)
      .then(setPermissions)
      .catch(console.error);
  }, []);

  return (
    <div className="container p-3">
      <DataTable
        dense
        striped
        highlightOnHover
        data={permissions}
        columns={columns}
        selectableRows={true}
        onSelectedRowsChange={onRowSelect}
        selectableRowSelected={(row) => selectedRows.includes(row)}
      />
      {key && (
        <div className="alert alert-primary text-break my-3" role="alert">
          {key}
        </div>
      )}
      <button className="btn btn-primary btn-block mt-3" onClick={onCreate}>
        Create
      </button>
    </div>
  );
};

export default ServiceKeys;
