import Honkio from "../Honkio";

const deleteReservation = (order_id, errorHandler = {}) => {
    return Honkio().mainShop.userFetch(
        `orderreservationset`,
        { order_id, delete: true },
        errorHandler
    );
};
export default deleteReservation;
