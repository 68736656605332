import Honkio from '../Honkio';

const getAppInfo = (options = {}, errorHandler = {}) => {
  const { appid, debug } = options;
  return Honkio().mainShop.userFetch('adminappget', {
    debug,
    id: appid,
  }, errorHandler );
};
export default getAppInfo;
