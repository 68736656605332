import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import {
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import Wait from '../UI/Wait';
import ReactTooltip from "react-tooltip";

const colsToSearch = ['name'];
const typedCol = 'active';

const AppTable = ({
    apps = [],
    filterSearch = (i) => i,
    filterType = (i) => i,
    keyword,
    type,
    onApplicationSelected = () => { },
    onRowClicked = () => { }
}) => {
    const [ready, setReady] = useState(false);
    const { app } = useContext(UserInfoContext);
    const displayedApps = useMemo(() => {
        const typifiedApps = filterType(apps, typedCol, type);
        return filterSearch(typifiedApps, colsToSearch, keyword);
    }, [apps, filterSearch, filterType, keyword, type]);

    const conditionalRowStyles = useMemo(
        () => [
            {
                when: ({ active }) => active === 'false',
                style: {
                    color: 'gray',
                },
            },
            {
                when: ({ id }) => id === app.id,
                style: {
                    backgroundColor: 'antiquewhite',
                },
            },
        ],
        [app.id]
    );

    const columns = useMemo(
        () => [
            { name: '#', grow: 0, cell: (_, index) => index + 1 },
            {
                name: 'Select',
                grow: 0,
                cell: (row, _index, _column, id) => {
                    const iconName = row.id === app.id ? 'bi-check2-square' : 'bi-square'
                    return <div onClick={() => {
                        onApplicationSelected(row)
                    }} data-tip data-for="selectTip">
                        <Icon iconName={`btn ${iconName}`} />
                        <ReactTooltip id="selectTip" place="top" effect="solid">Select application</ReactTooltip>
                    </div>
                }
            },
            {
                name: 'Active',
                grow: 0,
                cell: ({ active }) => (active === 'true' ? 'Yes' : 'No'),
            },
            {
                name: 'Logo',
                grow: 0,
                cell: ({ logo }) => (
                    <img
                        src={logo || './logo192.png'}
                        alt="Application logo"
                        className="img-fluid"
                        style={{ maxWidth: '100%', maxHeight: '32px' }}
                    />
                ),
            },
            { name: 'Name', grow: 1, cell: ({ name }) => name },
            { name: 'Description', grow: 1, cell: () => 'No description yen' },
            {
                name: 'Actions',
                grow: 1,
                cell: ({ id, name }) => (
                    <div>
                        <Link to={`/app/roles?appid=${id}&appname=${name}`} data-tip data-for="rolesTip">
                            <Icon iconName="bi-journal-check" />
                            <ReactTooltip id="rolesTip" place="top" effect="solid">Roles</ReactTooltip>
                        </Link>
                        <Link to={`/merchants?appid=${id}&appname=${name}`} data-tip data-for="merchantsTip">
                            <Icon iconName="bi-briefcase" />
                            <ReactTooltip id="merchantsTip" place="top" effect="solid">Merchants</ReactTooltip>
                        </Link>
                        <Link to={`/app/messages?appid=${id}&appname=${name}`} data-tip data-for="messagesTip">
                            <Icon iconName="bi-megaphone" />
                            <ReactTooltip id="messagesTip" place="top" effect="solid">Messages</ReactTooltip>
                        </Link>
                        <Link to={`/ordermodel?appid=${id}&appname=${name}`} data-tip data-for="orderModelTip">
                            <Icon iconName="bi-diagram-3" />
                            <ReactTooltip id="orderModelTip" place="top" effect="solid">Order models</ReactTooltip>
                        </Link>
                        <Link to={`/scripts?appid=${id}&appname=${name}`} data-tip data-for="scriptsTip">
                            <Icon iconName="bi-code-square" />
                            <ReactTooltip id="scriptsTip" place="top" effect="solid">Scripts</ReactTooltip>
                        </Link>
                        <Link to={`/app/edit?appid=${id}&appname=${name}`} data-tip data-for="editTip">
                            <Icon iconName="bi-pen" />
                            <ReactTooltip id="editTip" place="top" effect="solid">Edit</ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        []
    );

    useLayoutEffect(() => {
        if (displayedApps.length) {
            setReady(true);
        }
    }, [displayedApps]);
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={displayedApps}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={onRowClicked}
            progressPending={!ready}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default AppTable;
