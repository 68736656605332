import { useEffect, useState } from "react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {
    ButtonActionForm,
    CheckboxSwitch,
    InputFieldWithValidation,
} from "../Form/elements/formElements";
import SmartForm from "../Form/SmartForm";
import UploadFileWithPreview from "../Form/elements/UploadFileWithPreview";
import SelectFieldWithValidation from "../Form/elements/SelectFieldWithValidation";
import { setAssetOrderDetailsUpdateMsg } from "../../api/utils/setAssetOrderDetailsUpdateMsg";
import getShopList from "../../api/utils/getShopList";
import { MERCHANT_ID } from "../../config";

const AssetEditForm = ({ defaultValues, onSubmit, assetType }) => {
    const [assetTypePropertiesElements, setAssetTypePropertiesElements] =
        useState([]);

    const [shopsOptions, setShopsOptions] = useState([]);
    const [selectedShop, setSelectedShop] = useState("");
    const [optVal, setOptVal] = useState({
        name: "Not selected",
        value: null,
        id: null,
        coordinates: [0, 0],
    });

    useEffect(() => {
        fetchShopsList();
    }, []);

    const fetchShopsList = async () => {
        const resultHandler = (data) => {
            if (data?.status === "accept" && data?.shops?.length) {
                const opts = getPosOptions(data.shops);

                setShopsOptions(opts);
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const res = await getShopList(
            {
                page: 0,
                query_count: 100,
                merchant: MERCHANT_ID,
            },
            errorHandler
        );
        if (res && res.status == "accept") {
            resultHandler(res);
        }
    };

    const getPosOptions = (shops) => {
        const opts = shops.map((i) => ({
            name: i?.settings?.str_name,
            value: i?.settings?.str_name,
            id: i?._id,
            coordinates: i?.settings.coordinates,
        }));
        opts.push({
            name: "Not selected",
            value: null,
            id: null,
            coordinates: [0, 0],
        });
        return opts;
    };

    useEffect(() => {
        if (defaultValues) {
            const currentShop = shopsOptions.find(
                (item) =>
                    item?._id === defaultValues?.pos ||
                    item?._id === defaultValues?.properties?.pos
            );

            if (currentShop) {
                const newOptVal = {
                    name: currentShop?.settings?.str_name,
                    value: currentShop?.settings?.str_name,
                    id: currentShop?._id,
                    coordinates: currentShop?.settings?.coordinates,
                };
                setOptVal(newOptVal);
                setSelectedShop(newOptVal);
            }
        }
    }, [defaultValues, shopsOptions]);

    const handleChange = (e) => {
        if (e?.target?.name === "properties.keybox_code") {
            setAssetOrderDetailsUpdateMsg();
            const options = {};
            const resultHandler = (data) => {
                if (data && data?.status === "accept") {
                    // NotificationManager.success("key code updated ")
                } else {
                    NotificationManager?.error("Error");
                }
            };

            const errorHandler = (error) => {
                NotificationManager?.error(error.description, "Error", 4000);
            };
            setAssetOrderDetailsUpdateMsg(options, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        }
    };

    useEffect(() => {
        if (assetType) {
            if (assetType.properties && assetType.properties.length > 0) {
                const elements = assetType.properties.map((e) => {
                    {
                        if (e.property_type === "float") {
                            return (
                                <InputFieldWithValidation
                                    key={`property-${e.property_name}-${e.property_type}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${e.property_description}(${e.property_type})`}
                                    type="number"
                                    step="0.1"
                                />
                            );
                        }
                        if (e.property_type === "int") {
                            return (
                                <InputFieldWithValidation
                                    key={`property-${e.property_name}-${e.property_type}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${e.property_description}(${e.property_type})`}
                                    type="number"
                                    step="1"
                                />
                            );
                        }
                        if (
                            e.property_type === "str" &&
                            e.property_name !== "point_of_sales"
                        ) {
                            return (
                                <InputFieldWithValidation
                                    key={`property-${e.property_name}-${e.property_type}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${e.property_description}(${e.property_type})`}
                                    type="text"
                                    step="1"
                                />
                            );
                        }
                        if (e.property_type === "bool") {
                            return (
                                <CheckboxSwitch
                                    key={`property-${e.property_name}-${e.property_type}`}
                                    name={`properties.${e.property_name}`}
                                    label={e.property_description}
                                />
                            );
                        }
                        if (e.property_type === "image") {
                            return (
                                <UploadFileWithPreview
                                    key={`property-${e.property_name}-${e.property_type}`}
                                    className="col-md-6 mb-3"
                                    name={`properties.${e.property_name}`}
                                    type="text"
                                />
                            );
                        }
                    }
                });
                setAssetTypePropertiesElements(elements);
            }
        }
    }, [assetType]);

    return (
        <div>
            <NotificationContainer />
            <h4> Type: {`${defaultValues.type}`}</h4>
            <hr />
            <SmartForm onSubmit={onSubmit} defaultValues={defaultValues}>
                <InputFieldWithValidation
                    className="mb-3"
                    name="name"
                    label="Name"
                    type="text"
                />
                <CheckboxSwitch name="visible" label="Visible" />
                <InputFieldWithValidation
                    className="mb-3"
                    name="description"
                    label="Description"
                    type="text"
                    required={true}
                />
                {shopsOptions?.length ? (
                    <SelectFieldWithValidation
                        className="mb-3"
                        name="pos"
                        label="point of sales"
                        type="text"
                        step="1"
                        value={selectedShop}
                        valueSelector="id"
                        defaultValue={optVal}
                        options={shopsOptions}
                        defaultdata={defaultValues}
                        onChange={(event) => setSelectedShop(event)}
                    />
                ) : null}

                {assetTypePropertiesElements}

                <ButtonActionForm
                    label="Save changes"
                    name="active"
                    value={true}
                    type="submit"
                />
            </SmartForm>
        </div>
    );
};

export default AssetEditForm;
