const prepareToPublish = (assetInfo, formData) => {
    const {
        // latitude,
        // longitude,
        name,
        description,
        // type,
        visible,
        coordinates,
        pos,
        properties,
    } = formData;
    const asset = {
        id: assetInfo._id,
        type: assetInfo.type,
        // latitude,
        // longitude,
        name,
        description,
        visible,
        coordinates,
        pos,
        properties,
        // 'merchant': "6221dafa07a82cc26c04cf73"
    };
    return { asset };
};

export default prepareToPublish;
