import { useContext } from "react";
import UserInfoContext from "../../contexts/UserInfoContext";
import AnySelector from "../UI/AnySelector";
import languages from "../../__fixtures__/languages";

const LanguageSelector = () => {
    const { language, setInfo } = useContext(UserInfoContext);
    return (
        <div className="ml-auto">
            <AnySelector
                setItem={setInfo}
                items={languages}
                valueSelector="value"
                nameSelector="name"
                defaultValue={language ?? "en"}
            />
        </div>
    );
};

export default LanguageSelector;
