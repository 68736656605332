import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Wait from "../UI/Wait";
import OrderModal from "./OrderModal";
import {
    getOrderModelName,
    getOrderStatusName,
} from "./../../api/helpers/orderHelper";
import moment from "moment";

const OrderList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    onRowClick,
}) => {
    const [showOrderPreview, setShowOrderPreview] = useState(false);
    const handleShowOrderPreview = () => setShowOrderPreview(true);
    const handleCloseOrderPreview = () => setShowOrderPreview(false);
    const [currenOrder, setCurrentOrder] = useState();

    const renderFullname = (order) => {
        if (order?.custom_fields?.customer_fullname)
            return order?.custom_fields?.customer_fullname;

        let fullname = "";
        if (order?.custom_fields?.customer_firstname)
            fullname += order?.custom_fields?.customer_firstname;
        if (order?.custom_fields?.customer_lastname)
            fullname = fullname + " " + order?.custom_fields?.customer_lastname;

        if (!fullname.trim()) fullname = "Anonymous";
        return fullname.trim();
    };

    const columns = useMemo(
        () => [
            {
                name: "Licence",
                grow: 2,
                cell: (row) => row.custom_fields?.asset_license_number,
            },
            {
                name: "Start",
                grow: 2,
                cell: ({ start_date }) =>
                    moment(start_date).format("YYYY-MM-DD"),
            },
            {
                name: "End",
                grow: 2,
                cell: ({ end_date }) => moment(end_date).format("YYYY-MM-DD"),
            },
            {
                name: "Order Created",
                grow: 2,
                cell: ({ creation_date }) =>
                    moment(creation_date).format("YYYY-MM-DD"),
            },
            {
                name: "Consumer Name",
                grow: 2,
                cell: (row) => renderFullname(row),
            },
            { name: "Status", grow: 1, cell: (row) => row?.status },
            // { name: 'Status', grow: 1, cell: ({ model, status }) => getOrderStatusName(model,status) },
            { name: "Order type", grow: 1, cell: ({ model }) => model },
        ],
        [loading]
    );

    const conditionalRowStyles = [
        {
            when: (row) => {
                return row.model === "maintenance";
            },
            style: {
                backgroundColor: "var(--bs-green)",
                color: "var(--bs-white)",
            },
        },
        {
            when: (row) => {
                return row.model === "reserved_behalf_of_customer";
            },
            style: {
                backgroundColor: "var(--bs-blue)",
                color: "var(--bs-white)",
            },
        },
    ];

    return (
        <div>
            <DataTable
                dense
                striped
                highlightOnHover
                data={data}
                columns={columns}
                pagination={true}
                paginationPerPage={rowsCountPerPage}
                paginationRowsPerPageOptions={[rowsCountPerPage]}
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
                noDataComponent={emptyText}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
                onRowClicked={onRowClick}
                conditionalRowStyles={conditionalRowStyles}
            />
            {currenOrder && (
                <OrderModal
                    orderId={currenOrder._id}
                    show={showOrderPreview}
                    onHide={handleCloseOrderPreview}
                ></OrderModal>
            )}
        </div>
    );
};

export default OrderList;
