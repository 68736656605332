import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '../UI/Icon';
import DataTable from 'react-data-table-component';
import { useLayoutEffect, useMemo, useState } from 'react';
import Wait from '../UI/Wait';
import ReactTooltip from "react-tooltip";

const colsToSearch = ['str_firstname', 'str_lastname', 'email'];
const typedCol = 'active';

const UsersTable = ({
    data,
    filterSearch = (i) => i,
    filterType = (i) => i,
    keyword,
    type,
    loading,
    onRowClicked
}) => {
    const [ready, setReady] = useState(false);
    const displayedData = useMemo(() => {
        const typifiedUsers = filterType(data, typedCol, type);
        return filterSearch(typifiedUsers, colsToSearch, keyword);
    }, [data, filterSearch, filterType, keyword, type]);
    useLayoutEffect(() => {
        if (data) {
            setReady(true);
        }
    }, [data]);
    const columns = useMemo(
        () => [
            { name: '#', grow: 0, cell: (_, index) => index + 1 },
            {
                name: 'First name',
                grow: 1,
                cell: ({ str_firstname: firstname }) => firstname,
            },
            {
                name: 'Last name',
                grow: 1,
                cell: ({ str_lastname: lastname }) => lastname,
            },
            { name: 'Email', grow: 1, cell: ({ email }) => email ?? 'n/a' },
            {
                name: 'Active',
                grow: 0,
                cell: ({ active }) => (active === 'true' ? 'yes' : 'no'),
            },
            {
                name: 'Action',
                grow: 1,
                cell: ({ id }) => (
                    <div>
                        <Link to={`/consumer/edit?id=${id}`} data-tip data-for="editTip">
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip id="editTip" place="top" effect="solid">Edit</ReactTooltip>
                        </Link>
                        <Link to={`/user/transactions?consumerId=${id}`} data-tip data-for="transactionsTip">
                            <Icon iconName="bi-card-checklist" />
                            <ReactTooltip id="transactionsTip" place="top" effect="solid">Transactions</ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        []
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={displayedData}
            columns={columns}
            pagination={true}
            paginationPerPage={100}
            paginationRowsPerPageOptions={[50, 100, 500]}
            progressPending={loading}
            onRowClicked={onRowClicked}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default UsersTable;
