import Honkio from "../Honkio";

const adminShopSet = async function (options, errorHandler) {
    return Honkio().mainShop.userFetch(
        "adminshopset",
        {
            ...options,
            version: 3,
        },
        errorHandler
    );
};

const adminUpdateShop = (shop, errorHandler) => {
    return adminShopSet(
        {
            ...shop,
            action: "update",
        },
        errorHandler
    );
};

const adminCreateShop = (shop, errorHandler) => {
    return adminShopSet(
        {
            ...shop,
            action: "create",
        },
        errorHandler
    );
};

const adminDeleteShop = ({ id }, errorHandler) => {
    return adminShopSet(
        {
            id,
            action: "delete",
        },
        errorHandler
    );
};

export { adminDeleteShop, adminCreateShop, adminUpdateShop };
