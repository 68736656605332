import useDashboard from "./helpers/useDashboard";
import Stub from "../UI/Stub";
import Grid from "./Grid";

const loadingScreen = () => (
    <Stub className="bg-warning text-dark">Loading</Stub>
);

const options = { rows: 6, cols: 6 };
const mainScreen = ({ clientHeight: height, clientWidth: width }) => (
    <Grid
        rows={options.rows}
        cols={options.cols}
        width={width}
        height={height}
    />
);

const Dashboard = () => {
    const [nodeRef, screen] = useDashboard(mainScreen, loadingScreen);
    return (
        // <div ref={nodeRef} className="container-fluid p-0 h-100">
        //     {screen}
        // </div>
        <div>Dashboard</div>
    );
};

export default Dashboard;
