import Honkio from '../Honkio';

const getAsset = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch('assetget', {
    debug,
    id,
  }, errorHandler);
};
export default getAsset;
