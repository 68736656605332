import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import ButtonSignInOut from "./ButtonSignInOut";
import { useSelector } from "react-redux";

const AuthPanel = ({ className }) => {
    const { selectedRole } = useSelector((state) => state.roles);
    const { isAuth } = useContext(AuthContext);
    return (
        <div className={className}>
            <ButtonSignInOut />
            {/* {!isAuth && ( */}
            {!selectedRole && (
                <button className="btn btn-outline-secondary">Sign up</button>
            )}
        </div>
    );
};

export default AuthPanel;
