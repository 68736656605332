import Honkio from "../Honkio";

const getAsset = (options = {}, errorHandler = {}) => {
    const { id, debug } = options;
    return Honkio().mainShop.userFetch(
        "adminassetget",
        {
            debug,
            id: id,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};

const getAssets = (options = {}, errorHandler = {}) => {
    const {
        merchant,
        id,
        query_parent,
        debug,
        appid,
        query_properties,
        query_pos,
        page = 0,
        query_count = 0,
    } = options;
    return Honkio().mainShop.userFetch(
        "assetlist",
        {
            debug,
            query_application: appid,
            query_id: id,
            query_parent,
            query_merchant: merchant,
            query_merchant_details: true,
            query_properties,
            query_pos,
            query_skip: +page * query_count,
            query_count,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};

export { getAssets, getAsset };
