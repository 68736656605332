import clsx from 'clsx';
import { useEffect, useReducer } from 'react';
import { useFormContext } from 'react-hook-form';
import HiddenCheckboxes from './HiddenCheckboxes';

const init = (initialItems) => ({
    addMode: false,
    items: initialItems,
});

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'add':
            return state.addMode
                ? state.items.includes(payload.trim())
                    ? state
                    : { ...state, items: [...state.items, payload.trim()] }
                : { ...state, addMode: true };
        case 'delete':
            return {
                ...state,
                items: state.items.filter((item) => item !== payload),
                selected: null,
            };
        case 'select':
            return { ...state, selected: payload, addMode: false };
        case 'input':
            return { ...state, input: payload };
        case 'init':
            return init(payload);
        default:
            return state;
    }
};

const itemClass = (selected) =>
    clsx('list-group-item list-group-item-action py-1', selected && 'active');

const DynamicList = ({ label = 'List group', items = [], name,  onListChange = {}  }) => {
    const [state, dispatch] = useReducer(reducer, items, init);
    const { register } = useFormContext();

    useEffect(() => {
        items.length && dispatch({ type: 'init', payload: items });
    }, [items]);
    
    useEffect(() => {
        onListChange(state.items)
    }, [state.items]);

    if (!state.items) {
        return <div></div>
    }
    
    return (
        <div>
            <HiddenCheckboxes 
            items={state.items} 
            name={name} 
            register={register} />
            <div className="my-1">{label}</div>
            <ul className="list-group overflow-auto">
                {state.items.map((item, index) => (
                    <li
                        key={index}
                        className={itemClass(item === state.selected)}
                        onClick={() => {
                            dispatch({ type: 'select', payload: item });
                        }}
                    >
                        {item}
                    </li>
                ))}
            </ul>
            <div className="d-flex my-2">
                <input
                    type="text"
                    className="flex-grow-1"
                    hidden={!state.addMode}
                    onBlur={(e) => {
                        dispatch({ type: 'input', payload: e.target.value });
                        
                    }}
                />
                <button
                    type="button"
                    className={clsx(
                        ['btn btn-primary'],
                        state.addMode ? 'flex-shrink-1' : 'flex-grow-1'
                    )}
                    onClick={() => {
                        dispatch({ type: 'add', payload: state.input });
                        onListChange(state.items)
                    }}
                >
                    Add
                </button>
                <button
                    type="button"
                    className="btn btn-danger flex-grow-1"
                    disabled={!state.selected}
                    hidden={state.addMode}
                    onClick={() => {
                        dispatch({ type: 'delete', payload: state.selected });
                        onListChange(state.items)
                    }}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default DynamicList;
