import { useState, useEffect, useContext } from "react";
import DropdownButton from "../DropdownButton";
import Honkio from "../../api/Honkio";
import AuthContext from "../../contexts/AuthContext";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setAppSelectedMerchant } from "../../store/actions/appMerchantActions";
import { MERCHANT_ID } from "../../config";

const UserWidget = () => {
    const dispatch = useDispatch();
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const { roles, selectedRole } = useSelector((state) => state.roles);
    const [info, setInfo] = useState("");
    const [isOk, setOk] = useState(true);
    const { signOut } = useContext(AuthContext);
    const handleAuthError = ({ status }) => {
        console.log(`Status: ${status}. You are about to logout.`);
        setOk(false);
    };
    useEffect(() => {
        const options = {};
        Honkio()
            .mainShop.userFetch("consumerget", options, handleAuthError)
            .then(
                (
                    {
                        consumer_str_firstname: firstname,
                        consumer_str_lastname: lastname,
                    } = {
                        consumer_str_firstname: "Unknown",
                        consumer_str_lastname: "user",
                    }
                ) => setInfo(`${firstname} ${lastname}`)
            );
    }, []);

    useEffect(() => {
        !isOk && signOut();
    });

    const handleSelectMerchant = (selected) => {
        dispatch(setAppSelectedMerchant(selected));
    };

    const renderMerchantItems = () => {
        if (!appMerchants?.length) return null;
        return appMerchants.map((item) => (
            <Dropdown.Item
                href=""
                key={item?._id}
                onClick={(e) => handleSelectMerchant(item)}
                active={selectedMerchant?._id === item?._id ? true : false}
            >
                {item?.settings?.str_name}
            </Dropdown.Item>
        ));
    };

    useEffect(() => {
        if (!checkIfSuperadmin() && appMerchants?.length) {
            // Set selectedMerchant to hardcoded in config file
            const currentAppMerchant = appMerchants.find(
                (item) => item?._id === MERCHANT_ID
            );
            if (currentAppMerchant) {
                dispatch(setAppSelectedMerchant(currentAppMerchant));
            } else {
                console.log("current app merchant not found...");
            }
        }
    }, [roles, selectedRole, appMerchants]);

    /**
     * @returns current role `name` string for a user
     */
    const getSelectedRoleName = () => {
        if (roles?.length && selectedRole) {
            const currentRole = roles.find((item) => item?.id === selectedRole);
            if (currentRole) {
                return currentRole?.name || "";
            }
            return "";
        }
        return "";
    };

    /**
     * @returns `true` if superadmin
     */
    const checkIfSuperadmin = () => {
        return getSelectedRoleName().toLowerCase() === "superadmin";
    };

    return checkIfSuperadmin() ? (
        <Dropdown>
            <Dropdown.Toggle
                variant={selectedMerchant ? "primary" : "danger"}
                id="dropdown-basic"
            >
                {info}{" "}
                {selectedMerchant
                    ? `(${selectedMerchant?.settings?.str_name})`
                    : "(select merchant)"}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ zIndex: 1025 }}>
                <Dropdown.ItemText className="text-muted">
                    Select merchant
                </Dropdown.ItemText>
                {renderMerchantItems()}
                <Dropdown.Divider />
                <Dropdown.Item href="" onClick={signOut}>
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    ) : (
        <Dropdown>
            <Dropdown.Toggle
                variant={selectedMerchant ? "primary" : "danger"}
                id="dropdown-basic"
            >
                {info}{" "}
                {selectedMerchant
                    ? `(${selectedMerchant?.settings?.str_name})`
                    : "(no merchant)"}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ zIndex: 1025 }}>
                {/* <Dropdown.Divider /> */}
                <Dropdown.Item href="" onClick={signOut}>
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserWidget;
