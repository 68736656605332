import Honkio from '../Honkio';

const getMerchantInvoices = (options = {}, errorHandler = {}) => {
  const { merchant, query_skip = 0, query_limit = 0 } = options;
  return Honkio().mainShop.userFetch('merchantpaymentinvoicelist', {
    query_merchant: merchant,
    query_info: true,
    query_sort: [{ due_date: -1 }],
    query_skip,
    query_limit,
    query_type: 'subscription',
    version: 1,
  }, errorHandler);
};

export default getMerchantInvoices;
