import { useEffect, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getAppInfo from './../../api/utils/getAppInfo';

const ApplicationModal = ({ applicationId, show, onHide }) => {
    const [application, setApplication] = useState();
    const [progress, done] = useLoader([applicationId, application]);

    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        if (applicationId) {
            getAppInfo({ appid: applicationId })
                .then(({ application }) => application)
                .then(setApplication);
        }
    }, [applicationId]);

    const container = <div className="container">
        <div className="row">
            <div className="col-3">
                <img
                    className="d-inline-block img-fluid m-2 p-1 shadow rounded"
                    src={application?.settings?.str_logo_small ? application?.settings?.str_logo_small : './logo192.png'}
                    alt="App_Photo"
                    style={{ maxHeight: '100px' }}
                />
            </div>
            <div className="col-10">
                <label className="font-weight-bold mr-2" >Package (class)</label>
                <label>{application?.class}</label>
                <br />
                <label className="font-weight-bold mr-2" >Name</label>
                <label>{application?.name || '-'}</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.active}
                />
                <label className="font-weight-bold mr-3" >active</label>
                <br />
                <label className="font-weight-bold mr-3" >GDRP Support Email</label>
                <label>{application?.settings?.str_gdpr_support_email || '-'}</label>
                <br />
                <label className="font-weight-bold mr-3" >Minimum versions (IOS/Android)</label>
                <label>{`${application?.settings?.dict_min_version.ios} / ${application?.settings?.dict_min_version.android}`}</label>
                <br />
                <hr/>
                <h4>OAuth Settings</h4>
                <br />
                <label className="font-weight-bold mr-3" >OAuth ID</label>
                <label>{application?.settings?.str_oauth_id || '-'}</label>
                <br />
                <label className="font-weight-bold mr-3" >OAuth Secret</label>
                <label>{application?.settings?.str_oauth_secret || '-'}</label>
                <br />
                <label className="font-weight-bold mr-3" >OAuth Redirect URI list</label>
                <label>{application?.settings?.list_oauth_redirect_uri.join(' ')}</label>
                <br />
                <label className="font-weight-bold mr-3" >OAuth allowed URI list</label>
                <label>{application?.settings?.list_oauth_allowed_uri.join(' ')}</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.shopbool_oauth_sms_verify}
                />
                <label className="font-weight-bold mr-3" >OAuth SMS Verify</label>
                <hr/>
                <label className="font-weight-bold mr-3" >File Storage</label>
                <label>{application?.settings?.str_upload_url}</label>
                <h4>Plugins</h4>
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.plugins?.telia}
                />
                <label className="font-weight-bold mr-3" >telia plugin</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.plugins?.deposit}
                />
                <label className="font-weight-bold mr-3" >Deposit API</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.plugins?.merchant_manual_validation}
                />
                <label className="font-weight-bold mr-3" >merchant manual verification</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.plugins?.bool_plugin_passport_verify}
                />
                <label className="font-weight-bold mr-3" >passport verification plugin</label>
                <br />
                <input
                    onclick="return false"
                    type="checkbox"
                    checked={application?.settings?.plugins?.bool_plugin_tou}
                />
                <label className="font-weight-bold mr-3" >TOU plugin</label>
                <hr/>
                <label className="font-weight-bold mr-3" >TOU version</label>
                <label>{application?.settings?.str_tou_version || '-'}</label>
                <br />
                <label className="font-weight-bold mr-3" >TOU URL</label>
                <label>{application?.settings?.str_tou_url || '-'}</label>
                <br />
            </div>
        </div>
    </div>

    const onClickHide = () => {
        onHide()
    }

    return done ? (
        <Modal
            show={show}
            onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>{application?.name || 'Application'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {application ? container : 'not found'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    )
};

export default ApplicationModal