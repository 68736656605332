import { useState, useEffect, useCallback } from 'react';
import getCanvas from './getCanvas';

const useD3 = (data, presentation, options) => {
  const [node, setNode] = useState(null);
  const prepare = useCallback(
    (node, data, options) => {
      presentation(data, getCanvas(node, options ?? {}));
    },
    [presentation]
  );

  const nodeRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  useEffect(() => {
    node && prepare(node, data, options);
  }, [data, node, prepare, options]);
  return nodeRef;
};

export default useD3;
