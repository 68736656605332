import Honkio from "../Honkio";

const getMerchantList = (options = {}, errorHandler = {}) => {
    const {
        page = 0,
        query_count = 50,
        appid,
        debug,
        parent,
        query_application,
    } = options;
    return Honkio().mainShop.userFetch(
        "merchantlist",
        {
            debug,
            appid,
            parent,
            query_info: true,
            query_skip: +page * query_count,
            query_count,
            query_application,
            version: 3,
            action: "read",
        },
        errorHandler,
        true
    );
};

export default getMerchantList;
