
import { useEffect, useState } from 'react';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from './../../../utils/useSearchString';
import getAdminShop from './../../../api/utils/getAdminShop';
import prepareToPublish from './../helpers/prepareToPublish';
import { adminUpdateShop } from '../../../api/utils/setAdminShop';
import useLoader from './../../UI/helpers/useLoader';
import LoadingBar from './../../UI/LoadingBar';
import MultySelectYearCalendar from './MultySelectYearCalendar';
import moment from 'moment'
// import FullCalendarComponent from './FullCalendarComponent';

const ShopCloseDays = () => {
    const history = useHistory();

    const [shopInfo, setShopInfo] = useState(null);
    const [progress, done] = useLoader([shopInfo]);
    const { shop = null } = useSearchString();

    const [currentCloseDays, setCurrentCloseDays] = useState([])


    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data?.status === 'accept' && (data?.shop)) {
                    setShopInfo(data.shop)
                } else {
                    NotificationManager.error("Error")
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        getAdminShop({ id: shop }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }, [shop]);

    useEffect(() => {
        if (shopInfo != null) {
            const closeDays = shopInfo.settings?.nonworking_days
            if (closeDays && closeDays.length > 0) {
                setCurrentCloseDays(closeDays.map((e, i) => ({
                    id: i,
                    startDate: moment(e).toDate(),
                    endDate: moment(e).toDate(),
                    color:'pink'
                }
                )))
            } else {
                setCurrentCloseDays([])
            }
        }
    }, [shopInfo]);

    const saveShop = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Saved")
            } else {
                NotificationManager.error("Error")
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
        const settings = {
            nonworking_days: data
        }
        adminUpdateShop({ ...prepareToPublish(shopInfo.merchant, shop, { settings }) }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }

    const onSubmit = (data) => {
        let dataToSave = data.map(e => moment(e.startDate).format('YYYY-MM-DD'))
        saveShop(dataToSave)
    }

    if (done && shop && shopInfo != null) {
        return (
            <div>
                <h4>Point of sales {shopInfo.settings.str_name} ({shopInfo.settings.str_address})</h4>
                <NotificationContainer />
                <label className='mb-3'>Close days</label>
                <MultySelectYearCalendar
                    onSubmit={onSubmit}
                    closeDaysEvents={currentCloseDays} />
            </div>)
    } else {
        if (!done) {
            return <LoadingBar progress={progress} />
        } else {
            return "Shop is not found"
        }

    }
};

export default ShopCloseDays;
