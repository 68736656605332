import Honkio from '../Honkio';

const getAdminConsumer = (options = {}, errorHandler = {}) => {
    const { id, accesses = false } = options;
    return Honkio().mainShop.userFetch('adminconsumerget', {
        id,
        accesses,
    }, errorHandler);
};

export default getAdminConsumer;
