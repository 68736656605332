import React from "react";
import { useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

const AssetTypesListModal = ({
    assetTypeList,
    show,
    onHide,
    onAssetTypeSelected,
}) => {
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortable: true,
        },
        {
            name: "Description",
            selector: "description",
            sortable: true,
        },
    ]);

    const container = (
        <div className="container">
            <div className="col-13">
                <DataTable
                    dense
                    striped
                    noHeader
                    noDataComponent="no permissions"
                    highlightOnHover
                    onRowClicked={onAssetTypeSelected}
                    data={assetTypeList}
                    columns={columns}
                />
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Please select asset type from list</Modal.Title>
            </Modal.Header>
            <Modal.Body>{assetTypeList ? container : "not found"}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssetTypesListModal;
