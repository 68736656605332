import getMerchantRoles from '../../api/utils/getMerchantRoles';
import RoleForm from './RoleForm';
import { useEffect, useState } from 'react';
import { APPLICATION_ID } from '../../config';
//not used
const RoleSelector = ({ roles, onChange = () => { } }) => {
    return roles ? (
        <select onChange={onChange} name="role">
            <option style={{ display: 'none' }}>Select role</option>
            {roles.map(({ role_id: id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </select>
    ) : null;
};

const LanguageSelector = ({ languages, onChange = () => { } }) => {
    return languages ? (
        <select onChange={onChange} name="language">
            <option style={{ display: 'none' }}>Select language</option>
            {languages.map((language, index) => (
                <option key={index} value={index}>
                    {language}
                </option>
            ))}
        </select>
    ) : null;
};

const LanguageModeSelector = ({ onChange }) => {
    return (
        <div>
            <input type="checkbox" onChange={onChange} checked />
        </div>
    );
};

const languages = ['en', 'fi', 'da', 'no', 'sv'];

const MerchantRole = () => {
    const [multuLanguageMode, setMultiLanguageMode] = useState(true);
    const [role, setRole] = useState(null);
    const [roles, setRoles] = useState(null);
    const [language, setLanguage] = useState(null);
    const appid = APPLICATION_ID;

    useEffect(() => {
        console.log('make request');
        getMerchantRoles({ query_translations: multuLanguageMode, appid }).then(
            ({ roles }) => {
                roles.sort((a, b) => (a.role_id > b.role_id ? -1 : 1));
                setRoles(roles);
            }
        );
    }, [setRoles, multuLanguageMode, appid]);

    const selectRole = ({ target: { value } }) => {
        const role = roles.find(({ role_id: id }) => value === id);
        setRole(role);
    };

    const selectLanguage = ({ target: { value } }) => {
        const language = languages[value];
        console.log(language);
        setLanguage(language);
    };

    const changeLanguaheMode = ({ target: { checked } }) => {
        console.log(checked);
        setMultiLanguageMode(checked);
    };

    return (
        <>
            <LanguageModeSelector onChange={changeLanguaheMode} />
            <RoleSelector roles={roles} onChange={selectRole} />
            <LanguageSelector languages={languages} onChange={selectLanguage} />
            <RoleForm role={role} language={language} />
        </>
    );
};

export default MerchantRole;
