import { useFormContext } from 'react-hook-form';

const InputGroupWithValidation = ({
  className,
  name,
  label,
  type,
  defaultValue,
  required,
  prepend,
  append,
  error,
  min,
  max,
  step,
}) => {
  const { register } = useFormContext();
  return (
    <div className={className}>
      <label htmlFor={name}>{label}</label>
      <div className="input-group">
        {prepend && <div className="input-group-prepend">{prepend}</div>}
        <input
          ref={register}
          type={type}
          className="form-control"
          id={name}
          name={name}
          defaultValue={defaultValue}
          required={required}
          min={min}
          max={max}
          step={step}
        />
        {append && <div className="input-group-append">{append}</div>}
        {error && (
          <div className="invalid-feedback">Error message: $message</div>
        )}
      </div>
    </div>
  );
};

export default InputGroupWithValidation;
