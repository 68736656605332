import { SET_AUTH_INFO } from "../types";

const initialState = {
    access_token: null,
    expires_in: null,
    refresh_token: null,
    scope: "email",
    token_type: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_INFO:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default authReducer;
