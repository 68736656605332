import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import getPasswordPolicy from '../../api/utils/getPasswordPolicy';
import setPasswordPolicy from '../../api/utils/setPasswordPolicy';
import AnySelector from '../UI/AnySelector';
import mapResponseToState from './helpers/mapResponseToState';
import mapStateToRequest from './helpers/mapStateToRequest';
import PasswordPolicyEdit from './PasswordPolicyEdit';
import { init, initialState, reducer } from './reducer';

const PasswordPolicy = () => {
  const [settings, setSettings] = useState({});
  const [group, setGroup] = useState();
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const groups = useMemo(() => Object.keys(settings), [settings]);

  useEffect(() => {
    getPasswordPolicy()
      .then(({ settings }) => settings)
      .then(mapResponseToState)
      .then(setSettings)
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (settings && group) {
      dispatch({ type: 'reset', payload: settings[group] });
    }
  }, [group, settings]);

  useEffect(() => {
    const coercedLength = Object.values(state.characters).reduce(
      (sum, item) => sum + item
    );
    if (state.length.min < coercedLength) {
      dispatch({ type: 'length', payload: { min: coercedLength } });
    }
  }, [state.characters, state.length.min]);

  const onChange = useCallback(
    (type, name) => ({ target: { value } }) => {
      dispatch({ type: type, payload: { [name]: parseInt(value) } });
    },
    []
  );

  const onSubmit = useCallback(
    (data) => setPasswordPolicy({ group, settings: mapStateToRequest(data) }),
    [group]
  );
  return (
    <div className="container p-3">
      <div className="h2 d-flex align-items-center">
        <span className="mr-3">Password Policy for</span>
        <AnySelector
          label="select for whom"
          items={groups}
          nameSelector="name"
          valueSelector="value"
          setItem={setGroup}
        />
      </div>
      <PasswordPolicyEdit
        state={state}
        group={group}
        onChange={onChange}
        onSubmit={onSubmit}
        readyToSubmin={!!group && state.length.min}
        className="m-2"
      />
    </div>
  );
};

export default PasswordPolicy;
