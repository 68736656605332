import HonkioAPI from "../Honkio";

/**
 * Send email notifications about order details change to each order's with order_id owner
 *
 * @param {*} options - {message_id: string, order_ids: string[] }
 * @param {*} errorHandler - (error) => void
 * @returns response
 */
export const setAssetOrderDetailsUpdateMsg = async function (
    options,
    errorHandler
) {
    return HonkioAPI().mainShop.userFetch(
        "assetorderdetailsupdatemessage",
        {
            ...options,
        },
        errorHandler
    );
};
