import ButtonActionForm from "../Form/elements/ButtonActionForm";
import InputFieldWithValidation from "../Form/elements/InputFieldWithValidation";

import SmartForm from "../Form/SmartForm";
import prepareToEdit from "./helpers/prepareToEdit";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Map from "../Map";
import { GOOGLE_API_KEY } from "./../../config";
import AddressTableModal from "./AddressTableModal";
import { setAssetOrderDetailsUpdateMsg } from "../../api/utils/setAssetOrderDetailsUpdateMsg";
import { MERCHANT_ID } from "../../config";
import getReservationList from "../../api/utils/getReservationList";
import { getAssets } from "../../api/utils/getAssets";
import { useHistory } from "react-router-dom";

const baseGeoCodeURL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=`;

const MAX_MSGS_SENT = 10;
// TODO: Move this id to .env or config and set for carryPelican branch also
const DEFAULT_MESSAGE_ID = "6364c8584334c41e47f98b6c";

const ShopInfoForm = ({ saveShop, shopInfo, merchantId }) => {
    const history = useHistory();
    const [shopId, setShopId] = useState(shopInfo?.id);
    const [dataToSave, setDataToSave] = useState();
    let isMounted = true;
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();
    const [lat, setLate] = useState();
    const [long, setLong] = useState();
    const [reservation, setReservation] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [assetList, setAssetList] = useState([]);

    const defaultValues = React.useMemo(
        () => prepareToEdit(shopInfo),
        [shopInfo]
    );

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        fetchAssets();
    }, [MERCHANT_ID, shopInfo]);

    // Logging
    /*
    useEffect(() => {
        console.log("*** assetList", assetList);
        // console.log("** fetching reservations for these assets...");
    }, [assetList]);
    useEffect(() => {
        console.log(`*** shopInfo`, shopInfo);
    }, [shopInfo]);
    useEffect(() => {
        console.log(`*** MERCHANT_ID`, MERCHANT_ID);
    }, [MERCHANT_ID]);
    */

    const handleSendMsgs = async (order_id) => {
        const options = {
            message_id: DEFAULT_MESSAGE_ID,
            order_ids: [order_id],
        };

        const errorHandler = (error) => {
            console.log("handleSendMsgs: error", error);
            // TODO: Incorrect response from controller - not formatted like others
            // if (error)
            //     NotificationManager?.error(error.description, "Error", 4000);
            return null;
        };

        const data = await setAssetOrderDetailsUpdateMsg(options, errorHandler);
        return data;
    };

    const fetchAssets = async () => {
        const resultHandler = (data) => {
            const arr = [];
            data?.assets?.map((i) => {
                if (i?.properties?.pos === shopInfo._id) {
                    arr.push(i);
                }
            });
            setAssetList(arr);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            console.log("errorHandler, setting assetList to []");
            setAssetList([]);
        };
        if (shopInfo._id)
            getAssets(
                {
                    merchant: MERCHANT_ID,
                    query_properties: {
                        shop: { eq: shopInfo._id },
                    },
                    page: 0,
                    query_count: 100,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
    };

    // Gets reservations for asset with assetId
    const getOrderIdsFromReservations = async (assetId) => {
        const orderIdsList = [];

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            console.log(`assetReservationlist: errorHandler: error`, error);
            return [];
        };

        const data = await getReservationList(null, assetId, errorHandler);
        if (data?.reservation?.length) {
            data.reservation.forEach((rs) => {
                if (rs?.reservations?.length) {
                    rs.reservations.forEach((r) => {
                        if (r?.order_id) orderIdsList.push(r?.order_id);
                    });
                }
            });
            return [...new Set(orderIdsList)];
        }
        return [];
    };

    const handleNotifyCustomers = async () => {
        let count = 0;

        if (assetList?.length) {
            for (const i in assetList) {
                try {
                    const order = assetList[i];
                    const orderIds = await getOrderIdsFromReservations(
                        order._id
                    );

                    // Now orderIds fetched - send emails to order owners
                    if (count < MAX_MSGS_SENT) {
                        for (const j in orderIds) {
                            const isSent = await handleSendMsgs(orderIds[j]);
                            if (isSent) {
                                count++;
                                NotificationManager.success(
                                    `Email sent to owner of order ${orderIds[j]}; Total sent ${count}`
                                );
                            }
                        }
                    } else {
                        // Stop requesting reservations from server
                        break;
                    }
                } catch (error) {
                    console.log(`Error in handleNotifyCustomers: `, error);
                    continue;
                }
            }
        }
    };

    const onSubmit = async (data) => {
        const address = data.settings?.str_address;

        if (!address || address.trim() === "") {
            NotificationManager.warning("Address is empty", "Warning", 5000);
            if (data.settings && data.settings.coordinates) {
                data.settings.coordinates = {};
            }
            await saveShop(data);
        } else {
            const addrChanged = address !== shopInfo.settings.str_address;
            if (addrChanged) {
                //setLoading(true)
                const requestOptions = {
                    method: "POST",
                };
                const adr = `${baseGeoCodeURL}"${address}"`;
                const response = await fetch(adr, requestOptions);
                const result = await response.json();
                //const result = ADDRESS_LIST
                if (result.status === "OK" && result.results.length > 0) {
                    if (result.results.length > 1) {
                        setAddressList(result.results);
                        setDataToSave(data);
                        handleShowAddressListModal();
                    } else {
                        data.settings.coordinates = [
                            result.results[0].geometry.location.lat,
                            result.results[0].geometry.location.lng,
                        ];
                        data.settings.str_address =
                            result.results[0].formatted_address;

                        await saveShop(data);
                    }
                } else {
                    if (data.settings && data.settings.coordinates) {
                        data.settings.coordinates = {};
                    }
                    await saveShop(data);
                    //setLoading(false)
                    NotificationManager.warning(
                        result.error_message || "Geocode was not successful",
                        "Geocode error",
                        5000
                    );
                }
            } else {
                await saveShop(data);
                //setLoading(false)
            }

            // Shop is saved - notify customers about gate_code change
            if (
                data?.custom_fields.gate_code !==
                shopInfo?.custom_fields?.gate_code
            ) {
                // `gate_code` changed - need to send emails to 10 customers
                await handleNotifyCustomers();
            }

            history.goBack();
        }
    };

    const handleBlur = (e) => {
        const adr = `${baseGeoCodeURL}"${e.target.value}"`;
        // var v1;
        fetch(`${adr}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "OK" && data.results.length > 0) {
                    setLate(data.results[0].geometry.location.lat);
                    setLong(data.results[0].geometry.location.lng);
                }
            });
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        let data = { ...dataToSave };
        data.settings.coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        data.settings.str_address = address.formatted_address;
        saveShop(data);
        setDataToSave({});
    }

    return (
        shopInfo && (
            <div>
                <SmartForm
                    onValid={() => {}}
                    onSubmit={onSubmit}
                    defaultValues={defaultValues}
                >
                    <h4>Point of sales info (ID#{shopId})</h4>
                    <div className="form-row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputFieldWithValidation
                                    className="mb-3"
                                    name="settings.str_name"
                                    label=" Point of sales's name"
                                    type="text"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    name="custom_fields.gate_code"
                                    label="Gate PIN-code"
                                    type="text"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    name="settings.str_email"
                                    label="Email"
                                    type="email"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    name="settings.str_address"
                                    label="Address"
                                    type="text"
                                    onBlur={handleBlur}
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    name="settings.str_description"
                                    label="Description"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Sijainti</h4>
                        {defaultValues.settings.coordinates ?? null ? (
                            <Map
                                latitude={defaultValues.settings.coordinates[0]}
                                longitude={
                                    defaultValues.settings.coordinates[1]
                                }
                            />
                        ) : lat ? (
                            <Map latitude={lat} longitude={long} />
                        ) : (
                            <div className="text-muted">
                                'no location provided'
                            </div>
                        )}
                    </div>
                    <hr className="mb-4" />
                    <ButtonActionForm
                        label="Save changes"
                        name="active"
                        value={true}
                        type="submit"
                    />
                    {false && (
                        <button
                            type="button"
                            className="btn btn-danger btn-block"
                        >
                            Delete
                        </button>
                    )}
                </SmartForm>
                <AddressTableModal
                    addressList={addressList}
                    show={showAddressListModal}
                    onAddressSelected={onAddressSelected}
                    onHide={handleCloseAddressListModal}
                ></AddressTableModal>
            </div>
        )
    );
};

export default ShopInfoForm;
