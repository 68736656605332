
import Honkio from '../Honkio';

const merchantSet = async function (options, errorHandler) {
    return Honkio()
        .mainShop.userFetch('adminmerchantset', {
            ...options,
        }, errorHandler)
};

const merchantCreate = (appid, merchant, errorHandler) => {
    return merchantSet({
        ...merchant,
        application: appid,
    }, errorHandler)
};

const merchantCrrreate = (appid, merchant, errorHandler) => {
    return merchantSet({
        ...merchant,
        application: appid,
    }, errorHandler)
};

export {merchantCreate, merchantCrrreate};