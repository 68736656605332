import { SET_AUTH_INFO, SET_SELECTED_ROLE, SET_USER_ROLES } from "../types";

const initialState = {
    roles: [],
    selectedRole: null,
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_ROLES:
            return { ...state, roles: action.payload };
        case SET_SELECTED_ROLE:
            return { ...state, selectedRole: action.payload };
        default:
            return state;
    }
};

export default rolesReducer;
