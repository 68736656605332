import Honkio from '../Honkio';

const getAssetTypeList = (options = {}, errorHandler = {}) => {
  const {  query_applications } = options;
  const command = "adminassettypelist";
  const version= 1
  return Honkio().mainShop.userFetch('adminassettypelist', {
    query_applications,command,version,
  }, errorHandler);
};
export default getAssetTypeList;