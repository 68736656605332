import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const InputFieldWithValidation = ({
    className,
    labelClassName,
    name,
    label,
    type,
    defaultValue,
    required,
    additional,
    error,
    disabled,
    hidden,
    min,
    step,
    max,
    pattern,
    onChange,
    placeholderText,
    onFocusOut,
    onBlur,
    readOnly
}) => {
    const { register } = useFormContext();
    return (
        <div className={clsx(className, { 'd-none': hidden })}>
            {label && <label className={labelClassName} htmlFor={name}>{label}</label>}
            <input
                ref={register}
                type={type}
                className="form-control ml-2"
                id={name}
                name={name}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                min={min}
                step={step}
                max={max}
                pattern={pattern}
                onChange={onChange}
                placeholder ={placeholderText}
                onFocus={onFocusOut}
                onBlur={onBlur}
                readOnly={readOnly}
            />
            {additional && <small className="text-muted">{additional}</small>}
            {error && <div className="invalid-feedback">Error message: $message</div>}
        </div>
    );
};

export default InputFieldWithValidation;
