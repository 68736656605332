import logo from '../../../images/logo.png';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const ThankYou = () => {
    const theme = createTheme();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static" sx={{ background: '#8dc73f' }}>
                <Toolbar>
                    <Container maxWidth="xl">
                        <Typography variant="h6" href="/" component="a">
                            <img src={logo} alt='logo' />
                        </Typography>
                    </Container>
                </Toolbar>
            </AppBar>
            <main className="page-content d-flex align-items-center justify-content-center">
                <Box sx={{ pt: 8, pb: 8 }}>
                    <Container component="main">
                        <div className="d-flex flex-column align-items-center justify-content-center text-center max-w-650">
                            <Typography variant="h4" component="h1" className="fw-normal mb-4">
                                KIITOS VARAUKSESTASI
                            </Typography>
                            <Typography variant="p" className="fw-normal mb-3">
                                Varmistathan viela, eta bpostiisi tulee varausvahvistus. Tarkista varmuuden vuoksi mos roskapostilaatikkosi
                            </Typography>
                            <Typography variant="p" className="fw-normal mb-3">
                                Mikali et 5 minuutin kuluessa saa sähköpostivahvistusta, kokeile tehdä varaus uudestaan Jos sekaan ei onnistu, ole yhteydessa Pelican toimipisteeseen
                            </Typography>
                            <Typography variant="p" className="fw-normal">
                                0207 007 700 (ark. 10-18, la 10-15)
                                <br />
                                tai
                                <br />
                                info@fiksuveto.fi
                            </Typography>
                        </div>
                    </Container>
                </Box>
            </main>
            <Box sx={{ background: '#8dc73f' }} component="footer">
                <Typography variant="h6" component="p" align="center">
                    Powered by Fiksuveto Oy
                </Typography>
            </Box>
        </ThemeProvider >
    );
};
export default ThankYou;