import Honkio from "../Honkio";

const getAdminRolesList = (options = {}, errorHandler = {}) => {
    const { appid, merchant, debug, id, query_skip, query_count } = options;
    return Honkio().mainShop.userFetch(
        "adminrolelist",
        {
            debug,
            query_application: appid,
            query_merchant: merchant,
            id,
            query_skip,
            query_count,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};
export default getAdminRolesList;
