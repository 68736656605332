import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import UserWidget from "./UserWidget";
import { useSelector } from "react-redux";

const ButtonSignInOut = () => {
    const { selectedRole } = useSelector((state) => state.roles);

    const { isAuth, authURL } = useContext(AuthContext);
    // const component = isAuth ? (
    const component = !!selectedRole ? (
        <UserWidget />
    ) : (
        <a href={authURL} className="btn btn-primary">
            Sign in
        </a>
    );
    return component;
};

export default ButtonSignInOut;
