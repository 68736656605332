import { useContext, useEffect, useState, useMemo, useCallback  } from 'react';
import { useHistory } from 'react-router-dom';
import useSearchString from '../../utils/useSearchString';
import getMerchantList from '../../api/utils/getMerchantList';
import MerchantList from './MerchantList';
import InlineSearch from '../UI/InlineSearch';
import AnySelector from '../UI/AnySelector';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import { Link } from 'react-router-dom';
import UserInfoContext from '../../contexts/UserInfoContext';
import { NotificationManager, NotificationContainer } from "react-notifications";
import MerchantModal from './MerchantModal';

const colsToSearch = ['str_name'];
const typedCol = 'payment';

const Merchants = () => {
    const { setInfo } = useContext(UserInfoContext);
    const userInfo = useContext(UserInfoContext);
    const { appid = null, appname = null } = useSearchString();

    const history = useHistory();

    const [merchantsList, setMerchantsList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('');
    let isMounted = true;
    const selectedAppId = appid ? appid : (userInfo.app?.id ? userInfo.app.id : null)

    const [showMerchantPreview, setShowMerchantPreview] = useState(false);
    const handleShowMerchantPreview = () => setShowMerchantPreview(true);
    const handleCloseMerchantPreview = () => setShowMerchantPreview(false);
    const [currentMerchant, setCurrentMerchant] = useState();
    const { parent = null } = useSearchString();

    const pageTitle = appname ? `Merchants (${appname})` : (appid ? 'Merchants' : (userInfo.app?.name ? `Merchants (${userInfo.app.name})` : 'Merchants'))
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
            setLoading(true);
            fetchMerchants();
    }, [selectedAppId]);

    const fetchMerchants = async (newParent = null) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setMerchantsList(
                        data.merchants.reduce(
                            (carry, item) => [
                                ...carry,
                                { id: item.id, str_name: item.merchant.str_name, payment: item.lastpayment.due_date, active: item.merchant.active },
                            ],
                            []
                        )
                    );
                    setLoading(false);
                } else {
                    NotificationManager.error("Error")
                    setLoading(false);
                    setMerchantsList([])
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setLoading(false);
            setMerchantsList([])
        }

        const options = { appid: selectedAppId, parent: newParent }
        getMerchantList(options, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }
    
    const onMerchantSelected = () => ({ id, str_name: name }) => {
        setInfo({ merchant: { id, name } });
    }

    const onMerchantUnselected = () => () => {
        setInfo({ merchant: {} });
    }

    const displayedMerchants = useMemo(() => {
        const typifiedMerchants = searchFilterIfConsist(
            merchantsList,
            colsToSearch,
            keyword
        );
        return typifyIfEquals(typifiedMerchants, typedCol, type);
    }, [merchantsList, keyword, type]);

    let onRowClick = (row) => {
            history.push(`/merchants?parent=${row.id}`);
            fetchMerchants(row.id);
        };

    // let onRowClick = (row) => {
    //     // setCurrentMerchant(row)
    //     // handleShowMerchantPreview()
    //     // history.push(`/merchants?appid=${appid}&appname=${appname}&parent=${row.id}`);
    // }

    

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            <Link
                to={`/merchant/create?appid=${appid}&parent=${parent}`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create merhant
            </Link>
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
                <div>
                    <AnySelector
                        label="Filter payments"
                        items={[{ name: 'N/A', value: 'n/a' }]}
                        resetItem={{ name: 'Show all payments', value: '' }}
                        nameSelector="name"
                        valueSelector="value"
                        setItem={setType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <MerchantList
                        loading={loading}
                        onRowClicked={onRowClick}
                        onMerchantSelected={onMerchantSelected()}
                        onMerchantUnselected={onMerchantUnselected()}
                        data={displayedMerchants}
                    />
                    {currentMerchant && <MerchantModal
                        merchantId={currentMerchant.id}
                        show={showMerchantPreview}
                        onHide={handleCloseMerchantPreview}>
                    </MerchantModal>
                    }
                </div>
            </div>
        </div>
    );
};

export default Merchants;
