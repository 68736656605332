import { useEffect, useRef, useState } from 'react';

const DangerAction = ({
  keyword = 'I do confirm',
  label = 'Danger action',
  actionDescription = 'Perform Dangerous Action',
  onClick = () => {},
  time = 5000,
  name,
  className,
}) => {
  const [allowed, setAllowed] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const timer = useRef();

  useEffect(() => {
    if (allowed) {
      timer.current = setTimeout(() => setAllowed(false), time);
      setInputValue('');
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [time, allowed]);

  useEffect(() => {
    if (inputValue === keyword) {
      setAllowed(true);
    }
  }, [keyword, inputValue]);

  const onChange = ({ target: { value } }) => setInputValue(value);
  return (
    <div className="bg-warning rounded p-2">
      <div className={className}>
        <label htmlFor={name}>
          <strong>{label}</strong>
        </label>
        {allowed ? (
          <button
            type="button"
            className="btn btn-danger btn-block"
            onClick={onClick}
          >
            {actionDescription}
          </button>
        ) : (
          <input
            type="text"
            className="form-control"
            id={name}
            name={name}
            value={inputValue}
            onChange={onChange}
            placeholder={`type "${keyword}" to confirm your intention`}
          />
        )}
      </div>
    </div>
  );
};

export default DangerAction;
