import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import Icon from '../UI/Icon';
import useSearchString from '../../utils/useSearchString';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import ReactTooltip from "react-tooltip";

const conditionalRowStyles = (merchant) => [
    {
        when: (row) => row.id === merchant.id,
        style: {
            backgroundColor: 'AntiqueWhite',
        },
    },
];

const MerchantList = (props) => {
    const { data, onRowClicked = () => { }, onMerchantSelected = () => { }, onMerchantUnselected = () => {}} = props;
    const { merchant } = useContext(UserInfoContext);
    const { appid } = useSearchString();
    //const [ready, setReady] = useState(false);

    const columns = useMemo(
        () => [
            {
                name: '#',
                grow: 0,
                cell: (_, index) => index + 1,
            },
            {
                name: 'Select',
                grow: 0,
                cell: (row, _index, _column, id) => {
                    const iconName = row.id === merchant.id ? 'bi-check2-square' : 'bi-square'
                    const action = row.id === merchant.id ? onMerchantUnselected : onMerchantSelected
                    return <div onClick={() => {
                        action(row)
                    }} data-tip data-for="selectTip">
                        <Icon iconName={`btn ${iconName}`} />
                        <ReactTooltip id="selectTip" place="top" effect="solid">Select Merchant</ReactTooltip>
                    </div>
                }
            },
            {
                name: 'Name',
                sortable: true,
                selector: row => row.str_name,
                grow: 2,
                cell: ({ str_name }) => str_name,
            },
            {
                name: 'Active',
                sortable: true,
                grow: 0,
                selector: row => row.active,
                cell: (row) => <span>{row.active ? 'yes' : 'no'}</span>,
            },
            {
                name: 'Last payment',
                grow: 1,
                sortable: true,
                selector: row => row.payment,
                cell: (row) => <span>{row.payment ? row.payment : 'no data'}</span>,
            },
            {
                name: 'Actions',
                right: true,
                grow: 2,
                cell: (row, _index, _column, id) => (
                    <div>
                        <Link to={`/merchant/edit?merchant=${id}`} data-tip data-for="merchantTip">
                            <Icon iconName="btn bi-pen" />
                            <ReactTooltip id="merchantTip" place="top" effect="solid">Edit Merchant</ReactTooltip>
                        </Link>
                        <Link to={`/merchant/shops?merchant=${row.id}&merchant_name=${row.str_name}&appid=${appid}`} data-tip data-for="shopsTip">
                            <Icon iconName="btn bi-shop" />
                            <ReactTooltip id="shopsTip" place="top" effect="solid">Merchant Shops</ReactTooltip>
                        </Link>
                        <Link to={`/merchant/role?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="rolesTip">
                            <Icon iconName="bi-person-lines-fill" />
                            <ReactTooltip id="rolesTip" place="top" effect="solid">Roles</ReactTooltip>
                        </Link>
                        <Link to={`/merchant/payments?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="paymentsTip">
                            <Icon iconName="bi-card-checklist" />
                            <ReactTooltip id="paymentsTip" place="top" effect="solid">Payments</ReactTooltip>
                        </Link>
                        <Link to={`/messages?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="messagesTip">
                            <Icon iconName="bi-megaphone" />
                            <ReactTooltip id="messagesTip" place="top" effect="solid">Messages</ReactTooltip>
                        </Link>
                        <Link to={`/consumers?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="consumersTip">
                            <Icon iconName="bi-people-fill" />
                            <ReactTooltip id="consumersTip" place="top" effect="solid">Consumers</ReactTooltip>
                        </Link>
                        <Link to={`/apps?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="applicationsTip">
                            <Icon iconName="bi-app" />
                            <ReactTooltip id="applicationsTip" place="top" effect="solid">Applications</ReactTooltip>
                        </Link>
                        <Link to={`/assets?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="assetsTip">
                            <Icon iconName="bi-trophy" />
                            <ReactTooltip id="assetsTip" place="top" effect="solid">Assets</ReactTooltip>
                        </Link>
                        <Link to={`/products?merchant=${row.id}&merchant_name=${row.str_name}`} data-tip data-for="productsTip">
                            <Icon iconName="bi-basket" />
                            <ReactTooltip id="productsTip" place="top" effect="solid">Merchant Products</ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        [appid, merchant]
    );

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles(merchant)}
            onRowClicked={onRowClicked}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100]}
            progressPending={props.loading}
            emptyText={'The list is empty'}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default MerchantList;
