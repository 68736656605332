import { useEffect, useState } from "react";
import ShopList from "./ShopList";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { Link } from "react-router-dom";
import getAdminShopList from "./../../api/utils/getAdminShopList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MERCHANT_ID, APPLICATION_ID } from "../../config";
import DeleteConfirmModel from "../Model/DeleteConfirmModel";
import deletePointOfSales from "../../api/utils/deletePointOfSales";
import { getAssets } from "../../api/utils/getAssets";
import { useSelector } from "react-redux";
import { adminDeleteShop } from "../../api/utils/setAdminShop";

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Shops = () => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const appid = APPLICATION_ID;
    const [shopsList, setShopsList] = useState([]);
    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [assetListTot, setAssetListTot] = useState();
    const [ready, setReady] = useState();

    const handleShowConfirmModal = () => setShowConfirmModal(true);
    let isMounted = true;
    const history = useHistory();

    useEffect(() => {
        setReady(false);
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchShops = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setShopsList(
                        data.shops?.map(
                            (shop) =>
                                ({
                                    ...shop,
                                    str_email: shop.settings.str_email,
                                    str_name: shop.settings.str_name,
                                    shop_type: shop.settings.shop_type,
                                    service_type: shop.settings.service_type,
                                } ?? [])
                        )
                    );
                    setTotalRows(data.total);
                } else {
                    NotificationManager.error("Error");
                }
                setLoading(false);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setLoading(false);
        };
        setLoading(true);
        if (isMounted) {
            getAdminShopList(
                {
                    page: page - 1,
                    query_count: perPage,
                    appid: appid,
                    merchant: selectedMerchant
                        ? selectedMerchant?._id
                        : MERCHANT_ID,
                    query_asset_type_count: "Trailer",
                },
                errorHandler
            ).then((data) => {
                return resultHandler(data);
            });
        }
    };
    useEffect(() => {
        setReady(false);
        fetchAssets();
        if (selectedMerchant) {
            fetchShops(1);
        }
    }, [selectedMerchant, perPage]);

    const fetchAssets = async (page = 1) => {
        const resultHandler = (data) => {
            setAssetListTot(data.assets);
            setReady(true);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);

            setReady(true);
        };
        getAssets(
            {
                merchant: selectedMerchant
                    ? selectedMerchant?._id
                    : MERCHANT_ID,
                // query_skip: (+page - 1) * perPage,
                // query_count: perPage,
                query_skip: 0,
                query_count: 10000,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };
    const onDeleteClick = (id) => {
        handleShowConfirmModal();
        setDeleteId(id);
    };
    const handleCloseConfirmDelete = () => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        adminDeleteShop({ id: deleteId }, errorHandler);
        setShowConfirmModal(false);
        fetchShops(1);
    };
    const handlePageChange = (page) => {
        fetchShops(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const onRowClicked = (row) => {
        history.push(
            `/assets?shop=${row._id}&shopname=${row.settings.str_name}`,
            { data: row }
        );
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            {selectedMerchant?._id ? (
                <div>
                    <Link
                        to={`/shop/create?appid=${appid}`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create a
                        point of sales
                    </Link>
                    <div className="row">
                        <div className="col">
                            <ShopList
                                data={shopsList}
                                trailerList={assetListTot}
                                onRowClicked={onRowClicked}
                                rowsCountPerPage={perPage}
                                ready={ready}
                                totalRows={totalRows}
                                handlePerRowsChange={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                                loading={loading}
                                onDeleteClick={onDeleteClick}
                                perPageChoices={PER_PAGE_CHOICES}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                "Select merchant first"
            )}
            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => {
                    setShowConfirmModal(false);
                }}
                handleCloseConfirmDelete={handleCloseConfirmDelete}
            />
        </div>
    );
};

export default Shops;
