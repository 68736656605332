import { useEffect, useState } from 'react';
import { timesObject, marksObject, maxTimeIntervals } from './helpers/timeHelper';
import CheckboxSwitch from './../../Form/elements/CheckboxSwitch';
import { Slider } from '@mui/material';

const OpenDaysElement = ({ defaValues, dayIndex, dayName, defaActive, onChange = () => { } }) => {
    const getIndexByTime = (value) => {
        const ind = marks.findIndex((mark) => {
            //return mark.value === Math.round(value * maxTimeIntervals / 100)
            return mark.time === value
        })
        return ind;
    };
    const getIndexByValue = (value) => {
        const ind = marks.findIndex((mark) => {
            return mark.value === Math.round(value * maxTimeIntervals / 100)
        })
        return ind;
    };
    const marks1 = marksObject()
    const marks = timesObject()
    const ind1 = getIndexByTime(defaValues[0])
    const ind2 = getIndexByTime(defaValues[1])
    const m1 = marks[ind1]
    const m2 = marks[ind2]
    const [intervalLabel1, setIntervalLabel1] = useState(marks[ind1].label)
    const [intervalLabel2, setIntervalLabel2] = useState(marks[ind2].label)
    const [lbl, setLbl] = useState(dayName)
    const [isActive, setIsActive] = useState(defaActive)
    const [interval1, setInterval1] = useState(marks1[ind1].value)
    const [interval2, setInterval2] = useState(marks1[ind2].value)

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    const onSlide = (event, newValue, activeThumb) => {
        const index1 = getIndexByValue(newValue[0])
        const index2 = getIndexByValue(newValue[1])
        if (index1 !== -1 && index2 !== -1) {
            setIntervalLabel1(marks[index1].label)
            setIntervalLabel2(marks[index2].label)
            const int1 = marks[index1].time
            const int2 = marks[index2].time
            setInterval1(marks1[index1].value)
            setInterval2(marks1[index2].value)
            onChange(dayName, dayIndex, isActive, int1, int2)
        }
    };

    const onActivate = (event) => {
        setIsActive(event.target.checked)
        const index1 = getIndexByValue(interval1)
        const index2 = getIndexByValue(interval2)
        const int1 = marks[index1].time
        const int2 = marks[index2].time
        onChange(dayName, dayIndex, event.target.checked, int1, int2)
    };

     /* useEffect(() => {
          debugger
          onChange(dayName, dayIndex, isActive, interval1, interval2)
      }, [isActive, interval1, interval2]);*/

    return (<div className="row mb-3">
        <div className="col-md-1">
            <label htmlFor={dayName}>
                {lbl}
            </label>
        </div>
        <div className="col-md-1">
            <CheckboxSwitch
                value={isActive}
                onChange={onActivate}
                name={dayName} />
        </div>
        <div className="col-md-6">
            <Slider
                name={dayName}
                defaultValue={[interval1, interval2]}
                marks={marks1}
                disabled={!isActive}
                onChange={onSlide}
                step={1 * 100 / maxTimeIntervals}
            />
        </div>
        <div className="col-md-4">
            {`${intervalLabel1} - ${intervalLabel2}`}
        </div>
    </div>
    );
};

export default OpenDaysElement;
