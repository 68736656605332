import { useEffect, useState } from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import SmartForm from "./../../Form/SmartForm";
import { AppBar, Button, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GOOGLE_API_KEY, SHOW_LOGIN_BUTTON } from "./../../../config";
import AddressTableModal from "./../../ShopInfo/AddressTableModal";
import { Toolbar } from "@mui/material";
import logo from "../../../images/logo.png";
import Grid from "@mui/material/Grid";
import { checkAuthState } from "../../../auth/authActions";
import moment from "moment";
import InputFieldWithValidation from "../../Form/elements/InputFieldWithValidation";
import { useSelector } from "react-redux";
const baseGeoCodeURL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=`;

const AssetSearch = ({
    address,
    onlyAvailable,
    pickUpDate,
    returnDate,
    latitude,
    longitude,
    onSearch,
}) => {
    const { selectedRole } = useSelector((state) => state?.roles);
    const history = useHistory();

    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();
    const [dataToSave, setDataToSave] = useState();

    const [values, setValues] = useState({});
    const dt = moment(new Date()).format("YYYY-MM-DD").toLocaleString();
    const [date, setDate] = useState({
        date1: dt,
        date2: addDays(dt, 4),
    });

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const is_authenticated = checkAuthState();
        if (is_authenticated) {
            console.log("redirecting to dashboard");
            history.push("/dashboard");
        }
    }, [selectedRole]);

    useEffect(() => {
        setValues({
            address: address,
            date1: pickUpDate,
            date2: returnDate,
            only_available: onlyAvailable,
        });
        return () => {
            isMounted = false;
        };
    }, [address, pickUpDate, returnDate, latitude, longitude, onlyAvailable]);

    const onSubmit = async (data) => {
        const requestOptions = {
            method: "POST",
        };
        const adr = `${baseGeoCodeURL}"${data.address}"`;
        const response = await fetch(adr, requestOptions);
        const result = await response.json();
        if (result.status === "OK" && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                setDataToSave(data);
                handleShowAddressListModal();
            } else {
                data.latitude = result.results[0].geometry.location.lat;
                data.longitude = result.results[0].geometry.location.lng;
                data.formatted_address = result.results[0].formatted_address;
                openResultPage(data);
            }
        } else {
            NotificationManager.warning(
                result.error_message || "Geocode was not successful",
                "Geocode error",
                5000
            );
        }
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        let data = { ...dataToSave };
        data.latitude = address.geometry.location.lat;
        data.longitude = address.geometry.location.lng;
        data.formatted_address = address.formatted_address;
        openResultPage(data);
        setDataToSave({});
    }

    const openResultPage = (data) => {
        if (onSearch) {
            onSearch(data);
        } else {
            window.open(
                `/asset/search_results?latitude=${data.latitude}&longitude=${
                    data.longitude
                }&address=${data.formatted_address}&date1=${data.date1}&date2=${
                    data.date2
                }&available=${data.only_available ? true : false}`,
                "_blank"
            );
            // TODO: Refactor to use history.push instead of new page open
            // history.push(`/asset/search_results?latitude=${data.latitude}&longitude=${data.longitude}&address=${data.formatted_address}&date1=${data.date1}&date2=${data.date2}&available=${data.only_available? true : false}`)
        }
    };

    const openLoginPage = () => {
        history.push(`/adminlogin`);
    };

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return moment(result).format("YYYY-MM-DD").toLocaleString();
    }

    return (
        <div className="w-100">
            <NotificationContainer />
            <div>
                <SmartForm onSubmit={onSubmit} defaultValues={values}>
                    <AppBar
                        sx={{ background: "rgb(140, 198, 63)" }}
                        position="static"
                    >
                        <div className="c03143 column">
                            {!checkAuthState() && (
                                <Toolbar className="c03142">
                                    <img
                                        alt="logo"
                                        className="logo-small m-1"
                                        src={logo}
                                    />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ flexGrow: 1 }}
                                    ></Typography>
                                    {SHOW_LOGIN_BUTTON && (
                                        <Button
                                            color="inherit"
                                            onClick={openLoginPage}
                                        >
                                            Login
                                        </Button>
                                    )}
                                </Toolbar>
                            )}
                        </div>
                        <Toolbar variant="dense">
                            <Stack className="w-100">
                                <Grid container spacing={{ xs: 3, md: 5 }}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <InputFieldWithValidation
                                            labelClassName="font-style"
                                            name="address"
                                            label="ETSI PERÄKÄRRYJÄ"
                                            type="search"
                                            placeholderText="Yrjönkatu 24, 00100 Helsinki"
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <InputFieldWithValidation
                                            labelClassName="font-style"
                                            name="date1"
                                            label="NOUTO"
                                            type="date"
                                            required={true}
                                            onChange={(e) =>
                                                setDate({
                                                    date1: e.target.value,
                                                    date2: addDays(
                                                        e.target.value,
                                                        4
                                                    ),
                                                })
                                            }
                                            min={dt}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <InputFieldWithValidation
                                            labelClassName="font-style"
                                            name="date2"
                                            label="PALAUTUS"
                                            min={date.date1}
                                            max={date.date2}
                                            type="date"
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <label className="font-style d-block">
                                            &nbsp;
                                        </label>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: "200px",
                                                height: "40px",
                                                borderRadius: "22.5px",
                                            }}
                                            type="submit"
                                        >
                                            Etsi
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Stack
                                    className="my-3"
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="flex-end"
                                    spacing={2}
                                    marginBottom="6px"
                                >
                                    {/* <CheckboxMUI
                                        labelClassName='font-style-small'
                                        label="Näytä vain saatavilla olevat kärryt"
                                        name="only_available"
                                    /> */}
                                </Stack>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                </SmartForm>
                <AddressTableModal
                    addressList={addressList}
                    show={showAddressListModal}
                    onAddressSelected={onAddressSelected}
                    onHide={handleCloseAddressListModal}
                ></AddressTableModal>
            </div>
        </div>
    );
};

export default AssetSearch;
