import * as React from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import getShop from "./../../../../api/utils/getShop";
import { useEffect, useState, useMemo, useContext } from "react";
import Wait from "./../../../UI/Wait";
import useSearchString from "./../../../../utils/useSearchString";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import { Button, Stack } from "@mui/material";
import AssetSearchResultList from "./AssetSearchResultList";
import { MERCHANT_ID } from "./../../../../config";
import { getAssetList } from "../../../../api/utils/getAssetList";
import prepareToPublishReservationOrder from "./../../../Orders/helpers/prepareToPublishReservationOrder";
import orderCreate from "./../../../../api/utils/orderCreate";
import OperatorReservationModal from "../../../Orders/modals/OperatorReservationModal";
import { useSelector } from "react-redux";

const AssetSearch = () => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const { shop } = useSearchString();
    const [shopInfo, setShopInfo] = useState();
    const [assetList, setAssetList] = useState();
    const [loadingList, setLoadingList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([null, null]);

    const [showReserveOrder, setShowReserveOrder] = useState(false);
    const handleShowReserveOrder = () => setShowReserveOrder(true);
    const handleCloseReserveOrder = () => setShowReserveOrder(false);

    const [reservationOrder, setReservationOrder] = useState();

    let isMounted = true;
    useEffect(() => {
        fetchShop();
        return () => {
            isMounted = false;
        };
    }, []);
    const fetchShop = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                setLoading(false);
                if (data && data.status === "accept") {
                    setShopInfo(data.shop);
                } else {
                }
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, "Error", 4000);
        };

        getShop({ id: shop }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const onReserveClick = (asset) => {
        setReservationOrder({
            title: `Trailer reservation ,  ${asset.name}`,
            status: "initialized",
            description: "",
            model: "reserved_behalf_of_customer",
            pos: asset?.properties?.pos,
            merchant: MERCHANT_ID,
            order_type:
                asset?.properties?.reservable === true ? "Reservation" : "",
            start_date: dateRange.from,
            end_date: dateRange.to,
            products: [],
            custom_fields: {
                asset: asset?._id,
                asset_name: asset?.name,
                asset_license_number: asset?.properties?.license_number,
            },
            shop_id: asset?.pos,
            point_of_sales: asset?.pos,
        });
        handleShowReserveOrder();
    };

    function submitReservationOrder(formData) {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data.status === "accept") {
                    if (data) {
                        NotificationManager.success("Saved", 4000);
                        setReservationOrder();
                    } else {
                    }
                } else {
                    NotificationManager.error("Error", 4000);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        const newOrder = prepareToPublishReservationOrder(
            reservationOrder,
            formData
        );
        orderCreate(newOrder, errorHandler).then((data) => {
            return resultHandler(data);
        });
    }

    const handleDayClick = (day) => {
        const from = dateRange.from
            ? moment(dateRange.from).toDate()
            : undefined;
        const to = dateRange.to ? moment(dateRange.to).toDate() : undefined;
        const range = DateUtils.addDayToRange(day, { from: from, to: to });
        const formattedStartDate = range.from
            ? moment(range.from).format("YYYY-MM-DD").toLocaleString()
            : "";
        const formattedEndDate = range.to
            ? moment(range.to).format("YYYY-MM-DD").toLocaleString()
            : "";
        setDateRange({ from: formattedStartDate, to: formattedEndDate });
    };

    const handleResetClick = () => {
        setDateRange({ from: undefined, to: undefined });
    };

    const fetchAssets = async () => {
        setLoadingList(true);
        const resultHandler = (data) => {
            setLoadingList(false);
            if (data && data.status === "accept") {
                if (isMounted) {
                    const assets = data.assets;
                    if (assets && assets.length > 0) {
                        setAssetList(assets);
                    } else {
                        setAssetList([]);
                    }
                }
            } else {
                NotificationManager.error("Error");
                setAssetList([]);
            }
        };

        const errorHandler = (error) => {
            setLoadingList(false);
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList([]);
        };
        getAssetList(
            {
                merchant: selectedMerchant
                    ? selectedMerchant?._id
                    : MERCHANT_ID,
                query_pickup_date: dateRange.from,
                query_return_date: dateRange.to,
                query_reservable: true,
                query_pos: shop,
                role: "123",
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const from = dateRange.from ? moment(dateRange.from).toDate() : undefined;
    const to = dateRange.to ? moment(dateRange.to).toDate() : undefined;
    const modifiers = { start: from, end: to };

    const rangeStr = (
        <p>
            {!from && !to && "Select first day"}
            {from && !to && "Select last day"}
            {from &&
                to &&
                `Selected from ${from.toLocaleDateString()} to
          ${to.toLocaleDateString()}`}{" "}
            {from && to && (
                <Button
                    variant="outlined"
                    className=""
                    onClick={() => handleResetClick()}
                >
                    Reset
                </Button>
            )}
        </p>
    );

    return (
        <div className="container-fluid">
            <NotificationContainer />
            {!loading ? (
                shopInfo ? (
                    <div>
                        <h2>{`Search available trailers (${shopInfo?.settings?.str_name})`}</h2>
                        <DayPicker
                            className="Selectable"
                            numberOfMonths={2}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={handleDayClick}
                            min={1}
                            max={5}
                        />
                        {rangeStr}
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    width: "200px",
                                    height: "40px",
                                }}
                                onClick={fetchAssets}
                            >
                                Search
                            </Button>
                        </Stack>
                        {assetList ? (
                            <AssetSearchResultList
                                data={assetList}
                                loading={loadingList}
                                shop={shop}
                                onReserveClick={onReserveClick}
                                emptyText={"The trailer list is empty"}
                            />
                        ) : (
                            ""
                        )}
                        {reservationOrder && (
                            <OperatorReservationModal
                                show={showReserveOrder}
                                onHide={handleCloseReserveOrder}
                                order={reservationOrder}
                                onSubmit={submitReservationOrder}
                            />
                        )}
                    </div>
                ) : (
                    "Point of sales not found"
                )
            ) : (
                <div className="p-5">
                    <Wait />
                </div>
            )}
        </div>
    );
};

export default AssetSearch;
