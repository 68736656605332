import Honkio from "../Honkio";

const getOrders = (options, errorHandler = {}) => {
    const {
        query_shop,
        id,
        page = 0,
        query_count = 0,
        query_child_merchants,
        query_merchant,
        query_application,
        query_exclude_expired,
        query_status,
        query_asset,
        query_owner,
        query_third_person,
        query_model,
        query_models_statuses,
        query_product_details,
        query_unread_messages,
        query_only_count,
        query_parent_order_id,
        query_sort,
        query_date,
        query_unwrap,
        query_search_latitude,
        query_search_longitude,
        query_distance,
        query_custom,
    } = options;
    return Honkio().mainShop.userFetch(
        "adminorderlist",
        {
            query_shop,
            query_id: id,
            query_skip: +page * query_count,
            query_count,
            query_merchant,
            query_application,
            query_exclude_expired,
            query_status,
            query_asset,
            query_owner,
            query_third_person,
            query_model,
            query_models_statuses,
            query_product_details,
            query_unread_messages,
            query_only_count,
            query_child_merchants,
            query_parent_order_id,
            query_sort,
            query_date,
            query_unwrap,
            query_search_latitude,
            query_search_longitude,
            query_distance,
            query_custom,
        },
        errorHandler
    );
};

const getOrder = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        "orderget",
        {
            id: id,
        },
        errorHandler
    );
};

const getAdminOrder = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        "adminorderget",
        {
            id: id,
            version: 3,
            action: "read",
        },
        errorHandler
    );
};

export { getOrders, getOrder, getAdminOrder };
