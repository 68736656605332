import { useState } from 'react';
import ShopInfoForm from './ShopInfoForm';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { adminCreateShop } from '../../api/utils/setAdminShop';
import { MERCHANT_ID } from '../../config';

const ShopCreate = () => {
    const merchantId = MERCHANT_ID

    const [shopInfo, setShopInfo] = useState({
        settings:
        {
            str_name: "In new test shop"
        },
        coordinates:[60.197739, 24.939511]

    });

    const onCreate = (shop) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Saved")
                //history.goBack();
            } else {
                NotificationManager.error("Error")
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
        //todo replace with new command
        const req_data = {...shop, merchant: merchantId}
        adminCreateShop(req_data, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }
    return (
        <div>
            <NotificationContainer />
            <ShopInfoForm
                saveShop={onCreate}
                shopInfo={shopInfo} />
        </div>
    );
};

export default ShopCreate;