import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Icon from "../UI/Icon";
import ReactTooltip from "react-tooltip";
import Wait from "./../UI/Wait";

const Selector = ({
    items,
    selected,
    appid,
    language,
    loading,
    onRowClicked = () => {},
    onDeleteClick = () => {},
    rowsCountPerPage,
    handleRowsPerPage,
    perPageChoices = [25],
    totalRows,
    handlePageChange = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: "ID",
                cell: ({ _id }, index) => _id,
            },
            {
                name: "Name",
                cell: (c) => {
                    const text = <span className="text-muted">-</span>;
                    const langText = c["display_name__" + language];
                    if (langText) {
                        return langText;
                    } else if (c?.name) {
                        return c.name;
                    } else {
                        return c.display_name ?? text;
                    }
                },
            },
            {
                name: "Description",
                cell: (c) => {
                    const text = (
                        <span className="text-muted">No description yet</span>
                    );
                    const langText = c["description__" + language];
                    if (langText) {
                        return langText;
                    } else {
                        return c.description ?? text;
                    }
                },
            },
            // {
            //     name: 'Actions',
            //     cell: (row) => {
            //         return (
            //             <div className="d-flex">
            //                 {/* <Link to={`/role/edit?role_id=${row._id}${appid ? `&appid=${appid}` : ''}`} data-tip data-for="editTip">
            //                     <Icon iconName="bi-pen" />
            //                     <ReactTooltip id="editTip" place="top" effect="solid">Edit</ReactTooltip>
            //                 </Link> */}
            //                 <Link to={`/merchant/users?role=${row._id}&title=${row.name}`} data-tip data-for="usersTip">
            //                     <Icon iconName="bi-people-fill" />
            //                     <ReactTooltip id="usersTip" place="top" effect="solid">Users</ReactTooltip>
            //                 </Link>
            //                 {/* <div onClick={() => {
            //                     onDeleteClick(row._id, row.name)
            //                 }} data-tip data-for="deleteTip">
            //                     <Icon iconName="btn bi-trash" />
            //                     <ReactTooltip id="deleteTip" place="top" effect="solid">Delete</ReactTooltip>
            //                 </div> */}
            //             </div>
            //         )
            //     },
            // },
        ],
        [appid]
    );

    const conditionalRowStyles = useMemo(
        () => [
            {
                when: (row) => row._id === selected?._id,
                style: {
                    backgroundColor: "AntiqueWhite",
                },
            },
        ],
        [selected]
    );

    return (
        <DataTable
            striped
            dense
            highlightOnHover
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            data={items ?? []}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={onRowClicked}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
        />
    );
};

export default Selector;
