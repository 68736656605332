const defaultAppInfoIds = ['address','zip','country', 'phone', 'first_name', 'last_name','passport_image','other_document'];

const defaultAppInfoRegistrationRequirements = [
    {
        id: 'address',
        required: false,
        name: 'Address',
        mandatory: false,
        type: 'str'
    }, {
        id: 'zip',
        required: false,
        name: 'Zip',
        mandatory: false,
        type: 'str'
    }, {
        id: 'country',
        required: false,
        name: 'Country',
        mandatory: false,
        type: 'str'
    }, {
        id: 'phone',
        required: false,
        name: 'Phone',
        mandatory: false,
        type: 'str'
    }, {
        id: 'first_name',
        required: true,
        name: 'First name',
        mandatory: false,
        type: 'str'
    }, {
        id: 'last_name',
        required: true,
        name: 'Last name',
        mandatory: false,
        type: 'str'
    }, {
        id: 'passport_image',
        required: false,
        name: 'Passport image',
        mandatory: false,
        type: 'image'
    }, {
        id: 'other_document',
        required: false,
        name: 'Other document',
        mandatory: false,
        type: 'document'
    },
];

const convertToKeyValueDict = arrayObj => {
    const val = {}
    arrayObj.forEach(ob => {
        val[ob.id] = ob
    })
    return val
}

const updateOrMerge = (a1, a2) => {
    const ob1 = convertToKeyValueDict(a1)
    const ob2 = convertToKeyValueDict(a2)
    const merged_obj = { ...ob1, ...ob2 }
    const val = Object.entries(merged_obj)
    return val.map(obj => ({ ...obj[1] }))
}

const fillAppInfoRegistrationRequirements = (app) => {
    const defaultRR = Array.isArray(app.settings?.merchant_registration_requirements) ? app.settings?.merchant_registration_requirements.filter(e => {
        return defaultAppInfoIds.includes(e.id)
    }) : []
    
    const customRR = Array.isArray(app.settings?.merchant_registration_requirements) ? app.settings?.merchant_registration_requirements.filter(e => {
        return !defaultAppInfoIds.includes(e.id)
    }) : []
    const merchant_registration_requirements = updateOrMerge(defaultAppInfoRegistrationRequirements, defaultRR)
    const fields = customRR.map( e => {
        return {
            name: e.name,
            mandatory: e.mandatory ? 'yes' : 'no',
            id: e.id,
            type: e.type
        }
    })
    const appInfo = Object.assign({}, app, {
        fields:fields,
        settings: {
            ...app.settings,
            merchant_registration_requirements
        }
    });
    return appInfo
}

export default fillAppInfoRegistrationRequirements;
