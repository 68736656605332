import Honkio from '../Honkio';

const getServerInfo = (options = {}, errorHandler = {}) => {
  const { debug } = options;
  return Honkio().mainShop.userFetch('serverinfo', {
    debug,
  }, errorHandler);
};

export default getServerInfo;
