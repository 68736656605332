import { useFormContext } from 'react-hook-form';

const SelectFieldWithValidation = ({
  className,
  name,
  label,
  defaultValue,
  required,
  options = [],
  disabled,
  nameSelector = 'name',
  valueSelector = 'value',
  error,
  onChange
}) => {
  const { register } = useFormContext();

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <select
        ref={register}
        className="custom-select d-block w-100 ml-2"
        id={name}
        name={name}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
        onChange={onChange}
      >
        {options.map((opt, index) =>
          typeof opt === 'object' ? (
            <option key={index} value={opt[valueSelector]}>
              {opt[nameSelector]}
            </option>
          ) : (
            <option key={index}>{opt}</option>
          )
        )}
      </select>
      {error && <div className="invalid-feedback">Error message: $message</div>}
    </div>
  );
};

export default SelectFieldWithValidation;
