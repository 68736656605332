import Honkio from "../Honkio";

export const setAssetData = async function (
    asset,
    merchantId = null,
    appid = null,
    errorHandler = {}
) {
    return Honkio().mainShop.userFetch(
        "adminassetset",
        {
            ...asset,
            merchant: merchantId,
            application: appid,
            version: 3,
        },
        errorHandler
    );
};

export const createAsset = (
    asset,
    merchantId,
    appid,
    errorHandler = () => {}
) => {
    return setAssetData(
        { ...asset, action: "create" },
        merchantId,
        appid,
        errorHandler
    );
};

export const updateAsset = (
    asset,
    merchantId,
    appid,
    errorHandler = () => {}
) => {
    return setAssetData(
        { ...asset, action: "update" },
        merchantId,
        appid,
        errorHandler
    );
};

export const deleteAsset = (id, errorHandler) => {
    return Honkio().mainShop.userFetch(
        "adminassetset",
        {
            id,
            version: 3,
            action: "delete",
        },
        errorHandler
    );
};
