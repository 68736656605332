import HonkioAPI from "./../Honkio";

const reservationCreate = (options = {}, errorHandler = {}) => {
    return HonkioAPI().mainShop.userFetch(
        "orderreservationset",
        {
            ...options,
        },
        errorHandler
    );
};

export default reservationCreate;
