import moment from "moment";

const prepareToPublishMaintenanceOrder = (order, formData) => {
    const { description, pickUpDate, returnDate, start_date, end_date } =
        formData;
    const result = {
        ...order,
        start_date: start_date
            ? moment(start_date).format("YYYY-MM-DD").toLocaleString()
            : "",
        end_date: end_date
            ? moment(new Date(end_date)).format("YYYY-MM-DD").toLocaleString()
            : "",
        description,
    };
    return result;
};

export default prepareToPublishMaintenanceOrder;
