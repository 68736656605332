import { useEffect, useState, useContext } from "react";
import { getOrders } from "../../api/utils/getOrders";
import OrderList from "./OrderList";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { MERCHANT_ID } from "./../../config";
import { APPLICATION_ID } from "../../config";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Orders = () => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const history = useHistory();
    const [orderList = [], setOrderList] = useState([]);
    const [selected, setSelected] = useState({});

    const [loading, setLoading] = useState(false);
    let isMounted = true;
    const [perPage, setPerPage] = useState(25);
    const [totalRows, setTotalRows] = useState();

    useEffect(() => {
        fetchOrders(1);
        return () => {
            isMounted = false;
        };
    }, [selectedMerchant]);

    const fetchOrders = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (isMounted) {
                    setOrderList(data.orders);
                    setTotalRows(data.total);
                    setLoading(false);
                }
            } else {
                NotificationManager.error("Error");
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setOrderList([]);
            setTotalRows(0);
            setLoading(false);
        };
        if (isMounted) {
            getOrders(
                {
                    // query_merchant: MERCHANT_ID,
                    query_merchant: selectedMerchant?._id,
                    query_application: APPLICATION_ID,
                    page: page - 1,
                    query_count: perPage,
                    query_child_merchants: true,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.log);
        }
    };

    const handlePageChange = (page) => {
        fetchOrders(page);
    };

    const openOrderDetails = (order) => {
        history.push(`order/edit?order=${order._id}`);
    };

    return (
        <div className="w-100 h-100">
            <NotificationContainer />
            <div className="container-fluid">
                <h2>Orders</h2>
                <div className="row">
                    <div className="col">
                        {selectedMerchant ? (
                            <OrderList
                                data={orderList}
                                selectItem={setSelected}
                                id={selected.id}
                                loading={loading}
                                totalRows={totalRows}
                                handlePageChange={handlePageChange}
                                rowsCountPerPage={perPage}
                                emptyText={"The order list is empty"}
                                onRowClick={openOrderDetails}
                            />
                        ) : (
                            <span>Please, selecte the merchant</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Orders;
