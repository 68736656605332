
import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NotificationManager, NotificationContainer } from "react-notifications";
import AddIcon from '@material-ui/icons/Add';
import useSearchString from './../../utils/useSearchString';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import CardWrapperComponent from '../../utils/CardWrapperComponent';
import LoadingBar from '../UI/LoadingBar';
import FileBoxComponent from '../../utils/FileBoxComponent';
import { getAsset } from '../../api/utils/getAssets';
import { getFileList } from '../../api/utils/getFileList';
import { deleteFile } from '../../api/utils/setFile';
import DocumentUploadModalComponent from '../areas/documents/DocumentUploadModalComponent';

const AssetDocuments = () => {
    const { id: assetId } = useSearchString();
    const [assetInfo, setAssetInfo] = useState({});
    const [fileList, setFileList] = useState({});
    const [progress, done] = useLoader([assetInfo, fileList]);
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const handleShowAddFileModal = () => setShowAddFileModal(true);
    const handleCloseAddFileModal = () => setShowAddFileModal(false);

    const [showConfirmRemoveDocument, setShowConfirmRemoveDocument] = useState({ visible: false, document: undefined });
    const handleShowConfirmRemoveDocument = (document) => setShowConfirmRemoveDocument({ visible: true, document: document });
    const handleCloseConfirmRemoveDocument = () => setShowConfirmRemoveDocument({ visible: false, document: undefined });

    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        fetchAsset();
        fetchAssetFiles()
    }, [assetId]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setAssetInfo(data.asset)
                } else {
                    NotificationManager.error("Error")
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setAssetInfo()
        }
        if (assetId) {
            getAsset({ id: assetId }, errorHandler)
                .then(data => {
                    return resultHandler(data)
                })
                .catch(console.error);
        }
        else {
            setAssetInfo();
        }
    }

    const fetchAssetFiles = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setFileList(data.files)
                } else {
                    NotificationManager.error("Error")
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setFileList([])
        }
        if (assetId) {
            getFileList({ object: assetId, type: "document" }, errorHandler)
                .then(data => {
                    return resultHandler(data)
                })
                .catch(console.error);
        } else {
            setFileList([])
        }
    }

    const deleteDocument = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("File deleted")
                onFileRemoved()
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
debugger
        deleteFile(showConfirmRemoveDocument.document._id, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };

 const onFileRemoved = () => {
        fetchAssetFiles()
        handleCloseConfirmRemoveDocument()
    }

    const forceDownload = (blob, filename) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    const onLoadFile = (file) => {
        NotificationManager.success("SUCCESS", "DOCUMENT_DOWNLOADING_STARTED");
        forceDownload(file?.url, `${file?.display_name}.${file?.extension}`);
    }

    return <div>
        <NotificationContainer />
        <br />
        <div>
            <CardWrapperComponent add_class="mb-5"
                header={true}
                // card_title={done ? (assetInfo ? `Documents: ${assetInfo.name}` :"NOT_FOUND") : "LOADING"}
                >
                {/* {done ? (
                    assetInfo ? ( */}
                        <div className="col-lg-12 justify-content-center align-items-center">
                            <Button className="ml-3 mb-4" theme="info"
                                onClick={handleShowAddFileModal}><AddIcon />
                                UPLOAD IMAGE
                            </Button>
                            <FileBoxComponent
                                data={fileList}
                                onDelete={file => handleShowConfirmRemoveDocument(file)}
                                onDownload={file => onLoadFile(file)}
                                onUploadClick={() => handleShowAddFileModal()} />
                        </div>
                    {/* ) : "not found"
                ) :
                    (
                        <LoadingBar progress={progress} />
                    )
                } */}
            </CardWrapperComponent>
            <DocumentUploadModalComponent
                visible={showAddFileModal}
                toggle={handleCloseAddFileModal}
                onRequestClose={handleCloseAddFileModal}
                objectId={assetId}
                objectType={'asset'}
                onMediaUploaded={() => {
                    fetchAssetFiles()
                    handleCloseAddFileModal()
                }} />
             {showConfirmRemoveDocument.document && <Modal show={showConfirmRemoveDocument} onHide={handleCloseConfirmRemoveDocument}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to remove document <b>{showConfirmRemoveDocument.document.display_name}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmRemoveDocument}>CLOSE</Button>
                    <Button variant="danger" onClick={() => deleteDocument()}>REMOVE</Button>
                </Modal.Footer>
            </Modal>}
            
        </div>
    </div>
}
export default AssetDocuments;