import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import appMerchantsReducer from "./appMerchantsReducer";
import authReducer from "./authReducer";
import rolesReducer from "./rolesReducer";

const appReducer = combineReducers({
    auth: authReducer,
    roles: rolesReducer,
    merchant: appMerchantsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        storage.removeItem("persist:root");
        storage.removeItem("root");
        return appReducer(state, action);
    }
    return appReducer(state, action);
};
export default rootReducer;
