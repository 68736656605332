const payments = {
  payments: [
    { name: 'First payment', date: '2021-02-25', amount: '600' },
    { name: 'Second payment', date: '2021-02-25', amount: '400' },
    { name: 'Third payment', date: '2021-02-25', amount: '250' },
    {
      name: 'Discount',
      date: '2021-02-25',
      amount: '200',
      discount: true,
    },
  ],
  currency: 'USD',
  sign: '$',
  total: '1050',
};

export default payments;
