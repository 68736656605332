import ButtonActionForm from '../Form/elements/ButtonActionForm';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import InputGroupWithValidation from '../Form/elements/InputGroupWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';

import moment from 'moment-timezone'
import PaymentsOverview from './PaymentsOverview';
import SmartForm from '../Form/SmartForm';
import getServerAppList from '../../api/utils/getServerAppList';
import setMerchantData from '../../api/utils/setMerchantData';
import updateMerchantData from '../../api/utils/updateMerchantData';
import getServerInfo from '../../api/utils/getServerInfo';
import { useContext, useEffect, useState } from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { City } from 'country-state-city';

const MerchantInfoForm = (props) => {
    let timezones = moment.tz.names()
    const { payments, merchantInfo } = props;
    const {
        app: { id: appid },
    } = useContext(UserInfoContext);
    const history = useHistory();
    const [countries, setCountryList] = useState([]);
    const [country, setCountry] = useState();
    const [cities, setCitiesList] = useState([]);
    const [merchantMap, setMerchantMap] = useState(merchantInfo);
    const [merchantId, setMerchantId] = useState(merchantInfo?._id);
    const [appList, setAppList] = useState([]);

    const getCountryISO2 = require("country-iso-3-to-2");

    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        /* debugger 
         setMerchantMap({
           ...merchantMap,
           application_id:"615232553048ae6249372e59" 
         }) */
        getServerInfo()
            .then((data) => {
                if (isMounted) {
                    setCountryList(
                        data.server.country.reduce(
                            (country, item) => [
                                ...country,
                                { name: item.name, value: item.iso },
                            ],
                            []
                        )
                    );
                }
            })
    }, [])

    useEffect(() => {
        getServerAppList({ query_all: true, merchant: merchantId, debug: true })
            .then(({ applications }) => applications, [])
            .then((data) => {
                if (isMounted) {
                    setAppList(
                        data.reduce(
                            (apps, item) => [
                                ...apps,
                                { name: item.name, value: item._id },
                            ],
                            []
                        )
                    );
                }
            })
            .catch(console.error);
    }, [merchantId, setAppList]);

    useEffect(() => {
        let cities = City.getCitiesOfCountry(getCountryISO2(country))
        setCitiesList(cities)
    }, [country]);

    useEffect(() => {
        let cities = City.getCitiesOfCountry(getCountryISO2(merchantMap.str_country))
        setCitiesList(cities)
    }, [merchantMap]);

    const handleCountryChange = (event) => {
        setCountry(event.target.value)
        // setMerchantMap((prevState) => ({
        //   ...prevState,
        //   str_country: event.target.value
        // }))
    }

    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Successfully saved")
                history.goBack();
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        updateMerchantData(Object.assign(merchantMap, data), errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    };

    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Successfully deleted")
                history.goBack();
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        setMerchantData({ id: merchantId, delete: true }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }

    const handleAppChange = (event) => {
        setMerchantMap((prevState) => ({
            ...prevState,
            application_id: event.target.value
        }))
    }

    return (
        <div className="container">
            <NotificationContainer />
            <div className="row">
                {payments ? <PaymentsOverview {...payments} /> : null}
                <div className="col-md-8 order-md-1">
                    <h4>Merchant info (ID#{merchantId})</h4>
                    <SmartForm onSubmit={onSubmit} defaultValues={merchantMap}>
                        <div className="row">
                            <div className="col-md-6">
                                <CheckboxSwitch name="active" label="Active" />
                                <SelectFieldWithValidation
                                    label="Application"
                                    options={appList}
                                    name="application_id"
                                    onChange={event => handleAppChange(event)}
                                />
                                <CheckboxSwitch name="is_default" label="Is Default?" />
                                <InputFieldWithValidation
                                    name="settings.str_name"
                                    label="Merchant's name"
                                    type="text"
                                />
                                <InputFieldWithValidation
                                    name="settings.str_businessid"
                                    label="Business ID"
                                    type="text"
                                />
                                <InputFieldWithValidation
                                    name="settings.str_vatnumber"
                                    label="VAT ID"
                                    type="text"
                                />
                                <InputGroupWithValidation
                                    name="settings.str_telephone"
                                    label="Phone number"
                                    type="text"
                                    defaultValue=""
                                    required={true}
                                    prepend={<span className="input-group-text">№</span>}
                                />
                                <InputFieldWithValidation
                                    name="settings.str_email"
                                    label="Email"
                                    type="text"
                                    required={true}
                                />
                            </div>
                            <UploadFileWithPreview
                                className="col-md-6 mb-3"
                                name="settings.str_logo"
                                type="text"
                            />
                        </div>
                        <InputFieldWithValidation
                            className="mb-3"
                            name="settings.str_address1"
                            label="Address"
                            type="text"
                        />
                        <InputFieldWithValidation
                            className="mb-3"
                            name="settings.str_address2"
                            label={
                                <>
                                    {'Address 2 '}
                                    <span className="text-muted">(Optional)</span>
                                </>
                            }
                            type="text"
                        />
                        <div className="row">
                            <SelectFieldWithValidation
                                className="col-md-3 mb-3"
                                name="settings.str_country"
                                label="Country"
                                options={countries}
                                onChange={event => handleCountryChange(event)}
                            />
                            <SelectFieldWithValidation
                                className="col-md-3 mb-3"
                                name="settings.str_city"
                                label="City"
                                options={cities}
                            />
                            <SelectFieldWithValidation
                                className="col-md-3 mb-3"
                                name="settings.str_timezone"
                                label="Timezone"
                                options={timezones} />
                        </div>
                        <hr className="mb-4" />
                        <div className="row mb-3">
                            <div className="col-md-6">
                                API Key:
                            </div>
                            <div className="col-md-6">
                                {merchantMap.api_key}
                            </div>
                        </div>
                        <hr className="mb-4" />
                        <h4 className="mb-3">Controls</h4>
                        <div className="row">
                            <InputFieldWithValidation
                                className="col-md-6 mb-3"
                                name="billing_start_date"
                                label="Billing start date"
                                type="date"
                                additional="100 days ahead"
                            />
                            <InputFieldWithValidation
                                className="col-md-6 mb-3"
                                name="disruptive_project"
                                label="Disruptive project ID"
                                type="text"
                            />
                        </div>

                        <hr className="mb-4" />

                        <h4>Telia bank authentication</h4>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Aktia authentication"
                                name="settings.telia_authentication_banks.aktia"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Alandsbanken authentication"
                                name="settings.telia_authentication_banks.alandsbanken"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Danskebank authentication"
                                name="settings.telia_authentication_banks.danskebank"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Handelsbanken authentication"
                                name="settings.telia_authentication_banks.handelsbanken"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Nordea authentication"
                                name="settings.telia_authentication_banks.nordea"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Omasp authentication"
                                name="settings.telia_authentication_banks.omasp"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Pop authentication"
                                name="settings.telia_authentication_banks.pop"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable SP authentication"
                                name="settings.telia_authentication_banks.sp"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable Spankki authentication"
                                name="settings.telia_authentication_banks.spankki"
                            />
                        </div>
                        <div className="form-row row-cols-1">
                            <CheckboxSwitch
                                label="Enable OP authentication"
                                name="settings.telia_authentication_banks.op"
                            />
                        </div>
                        <hr className="mb-4" />
                        <ButtonActionForm
                            label="Save changes"
                            name="active"
                            value={true}
                            type="submit"
                        />
                        {merchantId && <button
                            type="button"
                            className="btn btn-danger btn-block"
                            onClick={onDelete}
                        >
                            Delete
                        </button>}
                    </SmartForm>
                </div>
            </div>
        </div>
    );
};

export default MerchantInfoForm;
