import { useState } from "react";
import NavigationContext from "../../contexts/NavigationContext";
import Navbar from "./Navbar";

import Sidebar from "./Sidebar";
import MainScreen from "./MainScreen";

import routes from "./routes";

const AdminView = () => {
    const [location, setLocation] = useState(0);
    const navigationContext = {
        location,
        setLocation,
    };
    return (
        <div className="container-fluid vh-100 d-flex flex-column">
            <NavigationContext.Provider value={navigationContext}>
                <Navbar />
                <div className="d-flex flex-grow-1">
                    <div className="">
                        <Sidebar config={routes} />
                    </div>
                    <div className="flex-grow-1 p-3">
                        <MainScreen config={routes} />
                    </div>
                </div>
            </NavigationContext.Provider>
        </div>
    );
};

export default AdminView;
