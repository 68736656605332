import Honkio from '../Honkio';

const getAssetType = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch('adminassettypeget', {
    debug,
    id,
  }, errorHandler);
};
export default getAssetType;
