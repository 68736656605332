import Honkio from '../Honkio';

const getAdminRole = (options = {}, errorHandler = {}) => {
  const {
    id,
  } = options;
  return Honkio().mainShop.userFetch('adminroleget', {
    id
  }, errorHandler);
};
export default getAdminRole;