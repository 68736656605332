import { useForm } from 'react-hook-form';
import CommonProperties from './elements/CommonProperties';
import nullFilter from '../../api/helpers/nullFilter';
import useSearchString from '../../utils/useSearchString';
import setAdminRole from '../../api/utils/setAdminRole';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useState } from 'react';
import Permissions from './elements/Permissions';
import { useHistory } from 'react-router-dom';

const RoleCreate = ({ role = { property: "private" }, language = 'en' }) => {
    const history = useHistory();
    const { appid, merchant } = useSearchString();
    const [newRole, setNewRole] = useState(role);
    const [permissions, setPermissions] = useState();
    const { register, handleSubmit, reset } = useForm({});
    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setNewRole(data.role)
                NotificationManager.success("Role saved")
                history.push( `/role/edit?role_id=${data.role._id}`);
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
        const multiLangCommonProperties = language && Object.keys(role).length > 0
            ? Object.entries({ ...role })
                .filter(([key]) =>
                    ['display_name', 'description'].includes(key.split('__')[0])
                )
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), [])
            : {};
        const {
            description,
            display_name,
            name,
            group_code,
            property,
        } = data;
        const localLangCommonProperties = {};
        if (language) {
            localLangCommonProperties['display_name__' + language] = display_name;
            localLangCommonProperties['description__' + language] = description;
        } else {
            localLangCommonProperties['display_name'] = display_name;
            localLangCommonProperties['description'] = description;
        }
        const request = nullFilter(
            Object.assign({}, multiLangCommonProperties, localLangCommonProperties, {
                id: newRole._id,
                merchant: role.merchant || merchant,
                application: appid,
                name,
                property,
                object_access: permissions,
                group_code,
            })
        );

        console.log(request);
        setAdminRole(request, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };
    
    const onUpdatePermissions = (permissions) => {
        setPermissions(permissions)
    };

    return (<div>
        <NotificationContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
                <CommonProperties
                    header="Properties"
                    register={register}
                    className="col-md-6 col-lg-3"
                    role={role}
                />
                <Permissions
                    register={register}
                    updatePermissions={onUpdatePermissions} />
            </div>
            <button type="submit" className="btn btn-primary">
                {newRole._id ? 'Update role' : 'Create role'}
            </button>
        </form>
    </div>
    );
};

export default RoleCreate;
