import { useEffect, useState, useContext, useMemo } from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import useSearchString from "../../utils/useSearchString";
import { Link } from "react-router-dom";
import { CONSUMER_URL, MERCHANT_ID } from "../../config";
import Icon from "../UI/Icon";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import { removeFromGroup } from "../../api/utils/setMerchantUserRole";
import adminConsumerList from "../../api/utils/adminConsumerList";
import { useSelector } from "react-redux";

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const MerchantUsersList = () => {
    const { appMerchants, selectedMerchant } = useSelector(
        (state) => state.merchant
    );
    const [users, setUsers] = useState([]);
    const { role = null, title = "Users" } = useSearchString();
    const [userId, setUserId] = useState(null);
    const [userToRemove, setUserToRemove] = useState(null);
    const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState(false);
    const handleShowConfirmRemoveUser = () => setShowConfirmRemoveUser(true);
    const handleCloseConfirmRemoveUser = () => setShowConfirmRemoveUser(false);

    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        role && fetchRoles(1);
    }, [role, perPage]);

    const showConfirmDialog = (user_id, email) => {
        setUserId(user_id);
        setUserToRemove({ user_id, email });
        handleShowConfirmRemoveUser();
    };

    const deleteUserFromGroup = () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Role removed", "Success", 4000);
                handleCloseConfirmRemoveUser();
                fetchRoles(1);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        removeFromGroup(
            {
                role_id: role,
                user_id: userId,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveUser);
    };

    const fetchRoles = async (page = 1) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        try {
            const result = await adminConsumerList(
                {
                    query_role: role,
                    query_merchant: selectedMerchant?._id || MERCHANT_ID,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            if (result && result?.status === "accept") {
                setUsers(result?.consumers);
                setTotalRows(result?.total);
            }
        } catch (error) {
            console.log("Error gettings roles: ", error);
            errorHandler(error);
        }
    };

    const columns = useMemo(() => [
        {
            name: "ID",
            cell: (row, index) => row._id,
            grow: 2,
            autoWidth: true,
        },
        {
            name: "Avatar",
            sortable: true,
            cell: ({ id }) => (
                <img
                    height="64px"
                    width="64px"
                    src={`${CONSUMER_URL}user_photo/${id}.jpg`}
                    alt="User's avatar"
                    className="d-flex justify-content-center align-items-center"
                />
            ),
        },
        {
            name: "Active",
            sortable: true,
            cell: ({ active }) => (active ? "Yes" : "No"),
        },
        {
            name: "First name",
            sortable: true,
            cell: ({ settings }) => settings?.str_firstname,
        },
        {
            name: "Last name",
            sortable: true,
            cell: ({ settings }) => settings?.str_lastname,
        },
        {
            name: "email",
            sortable: true,
            cell: ({ email }) => email,
        },
        {
            name: "Actions",
            cell: ({ _id, email }) => (
                <div className="flex">
                    <div
                        onClick={() => {
                            showConfirmDialog(_id, email);
                        }}
                        data-tip
                        data-for="deleteTip"
                    >
                        <Icon iconName="btn bi-trash" />
                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                            Delete from group
                        </ReactTooltip>
                    </div>
                </div>
            ),
        },
    ]);

    const handlePageChange = (page) => {
        fetchRoles(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{`Users (${title})`}</h2>
            <div>
                <Link
                    to={`/invitation/create?role_id=${role}&role_name=${title}`}
                    className="btn btn-outline-primary mt-3 mb-3"
                >
                    <i className="bi-plus-square ml-1 mr-2 " /> Invite User
                </Link>

                <DataTable
                    notitle
                    dense
                    striped
                    data={users}
                    columns={columns}
                    pagination={true}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={PER_PAGE_CHOICES}
                    paginationServer
                    onChangeRowsPerPage={handleRowsPerPage}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                />

                <Modal
                    show={showConfirmRemoveUser}
                    onHide={handleCloseConfirmRemoveUser}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Do you want to remove user{" "}
                            <b>{userToRemove ? userToRemove.email : ""}</b> from
                            group <b>{title}</b>? The user will not be deleted
                            from the system
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseConfirmRemoveUser}
                        >
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => deleteUserFromGroup()}
                        >
                            Remove
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default MerchantUsersList;
