import MerchantList from "../Merchants/Merchants";
import MerchantInfo from "../MerchantInfo/MerchantInfo";
import MerchantCreate from "../MerchantInfo/MerchantCreate";
import Grid from "../Dashboard/Dashboard";
import MerchantRoleList from "../Roles/MerchantRoleList";
import AppSelector from "../AppSelector/AppSelector";
import ApplicationCreate from "../Application/ApplicationCreate";
import MerchantRoleEditor from "../Roles/MerchantRoleEditor";
import PaymentsTable from "../PaymentsTable";
import RoleEditor from "../Roles/RoleEditor";
import RoleOverview from "../Roles/RoleOverview";
import ApplicationInfo from "../Application/ApplicationInfo";
import Users from "../Users/Users";
import PasswordPolicy from "../Password/PasswordPolicy";
import Assets from "../Assets/Assets";
import AssetEdit from "../Assets/AssetEdit";
import Scripts from "../Scripts/Scripts";
import ScriptEdit from "../Scripts/ScriptEdit";
import ServiceKeys from "../ServiceKeys/ServiceKeys";
import Settings from "../Settings/Settings";
import Shops from "../Shops/Shops";
import ShopInfo from "../ShopInfo/ShopInfo";
import ShopCreate from "../ShopInfo/ShopCreate";
import Orders from "../Orders/Orders";
import OrderEdit from "../Orders/OrderEdit";
import AssetViewRaw from "../Assets/AssetViewRaw";
import MerchantUsersList from "./../Roles/MerchantUsersList";
import RoleCreate from "./../Roles/RoleCreate";
import ShopOpenTime from "./../ShopInfo/OpenTime/ShopOpenTime";
import ShopCloseDays from "./../ShopInfo/CloseDays/ShopCloseDays";
import AssetSearch from "./../Assets/Admin/Search/AssetSearch";
import InvitationForRoleCreate from "../Invitations/InvitationForRoleCreate";
import AssetCreate from "../Assets/AssetCreate";
import AssetDocuments from "../Assets/AssetDocuments";
import TrailerWorkingDays from "../Assets/TrailerWorkingDays";

const routes = [
    { path: "/dashboard", label: "Dashboard", component: Grid },
    { path: "/apps", label: "Applications", component: AppSelector },
    {
        path: "/merchants",
        label: "Merchants",
        component: MerchantList,
    },
    {
        path: "/childMerchants",
        label: "Merchants",
        component: MerchantList,
    },
    {
        path: "/shops",
        label: "Shops",
        component: Shops,
    },
    {
        path: "/pointsOfSales",
        label: "Shops",
        component: Shops,
    },
    {
        path: "/shop/edit",
        label: "Shop Edit Form",
        component: ShopInfo,
        hidden: true,
    },
    {
        path: "/shop/create",
        label: "Shop Create Form",
        component: ShopCreate,
        hidden: true,
    },
    {
        path: "/shop/opentime",
        label: "Shop Open Time",
        component: ShopOpenTime,
        hidden: true,
    },
    {
        path: "/shop/closedays",
        label: "Shop close days",
        component: ShopCloseDays,
        hidden: true,
    },
    {
        path: "/assets/trailerWorkingDays",
        label: "Trailer working Days",
        component: TrailerWorkingDays,
        hidden: true,
    },
    { path: "/users", label: "Users", component: Users },
    {
        path: "/password",
        label: "Security",
        component: PasswordPolicy,
        hidden: true,
    },
    {
        path: "/servicekeys",
        label: "Service Keys",
        component: ServiceKeys,
        hidden: true,
    },
    {
        path: "/scripts/edit",
        label: "Script edit",
        component: ScriptEdit,
        hidden: true,
    },
    { path: "/scripts", label: "Scripts", component: Scripts, hidden: true },
    {
        path: "/assets/edit",
        label: "Edit assets",
        component: AssetEdit,
        hidden: true,
    },
    {
        path: "/assets/create",
        label: "Create assets",
        component: AssetCreate,
        hidden: true,
    },
    {
        path: "/assets/viewraw",
        label: "View asset",
        component: AssetViewRaw,
        hidden: true,
    },
    {
        path: "/assets",
        label: "Assets",
        component: Assets,
        hidden: false,
    },
    {
        path: "/shop/findtrailers",
        label: "Asset Search",
        component: AssetSearch,
        hidden: false,
    },
    {
        path: "/order/edit",
        label: "Edit order",
        component: OrderEdit,
        hidden: true,
    },
    {
        path: "/orders",
        label: "Orders",
        component: Orders,
        hidden: false,
    },
    {
        path: "/merchant/role/edit", // this route could be useful to test editor itself
        label: "Merchant Role",
        component: MerchantRoleEditor,
        hidden: true,
    },
    {
        path: "/merchant/role/create",
        label: "Role create form",
        component: MerchantRoleList,
        hidden: true,
    },
    {
        path: "/merchant/role",
        label: "Roles",
        component: MerchantRoleList,
        hidden: true,
    },
    {
        path: "/merchant/users",
        label: "Merchant Users",
        component: MerchantUsersList,
        hidden: true,
    },
    {
        path: "/merchant/payments",
        label: "Payments",
        component: PaymentsTable,
        hidden: true,
    },
    {
        path: "/merchant/edit",
        label: "Merchant Edit Form",
        component: MerchantInfo,
        hidden: true,
    },
    {
        path: "/merchant/create",
        label: "Merchant Create Form",
        component: MerchantCreate,
        hidden: true,
    },
    {
        path: "/merchant/shops",
        label: "Merchant's shops list",
        component: Shops,
        hidden: true,
    },
    {
        path: "/app/roles",
        label: "Application roles",
        component: MerchantRoleList,
        hidden: true,
    },
    {
        path: "/app/edit",
        label: "Application Info",
        component: ApplicationInfo,
        hidden: true,
    },
    {
        path: "/app/create",
        label: "Application Info",
        component: ApplicationCreate,
        hidden: true,
    },
    {
        path: "/role/edit",
        label: "Edit Role",
        component: RoleEditor,
        hidden: true,
    },
    {
        path: "/role/create/",
        label: "Create Role w/o acl",
        component: RoleCreate,
        hidden: true,
    },
    {
        path: "/invitation/create",
        label: "Invitation Create Form",
        component: InvitationForRoleCreate,
        hidden: true,
    },
    {
        path: "/asset/documents",
        label: "Asset Documents",
        hidden: true,
        component: AssetDocuments,
    },
    {
        path: "/role/show",
        label: "Role Overview",
        component: RoleOverview,
        hidden: true,
    },
    { path: "/settings", label: "Settings", component: Settings },
    {
        path: "/",
        label: "Miss",
        component: () => {
            console.error("Miss route");
            return null;
        },
        hidden: true,
    },
];

export default routes;
