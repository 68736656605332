import { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
//import { defaultPermissions } from './config_temp';
import getAdminAccesses from './../../../api/utils/getAdminAccesses';
import UserInfoContext from '../../../contexts/UserInfoContext';
import useSearchString from '../../../utils/useSearchString';

const Permissions = ({
    register = () => {
        console.warn(
            'You should pass register function and call it in ref prop like ref={register}'
        );
    },
    access = [],
    updatePermissions,
}) => {
    const userInfo = useContext(UserInfoContext);
    const { app = null } = useSearchString();
    const appid = app ? app : (userInfo.app ? userInfo.app.id : null)

    const [selected, setSelected] = useState(access);
    const [defaultAccesses, setDefaultAccesses] = useState({})
    /*const updateState = useCallback(
        ({ name, selectedRows }) => {
            debugger
            setSelected({[name]:selectedRows})},
        [setSelected]
    );*/
    /*const updateState = useCallback(
        (obj1, obj2) => {
            setSelected({
                ...selected,
                [obj1]:obj2
            })
        },
        [setSelected]
    );*/

    const updateState = (obj1, obj2) => {
        const newAccess = {
            ...selected,
            [obj1]: obj2.map(e => e.name)
        }
        setSelected(newAccess)
        updatePermissions(newAccess)
    }

    const columns = useMemo(
        () => [
            {
                name: 'Access',
                width: '200px',
                cell: (row) => row.name,
            },
            {
                name: 'Description',
                cell: (row) =>
                    row.description_key ?? (
                        <span className="text-muted">'No description yet'</span>
                    ),
            },
        ],
        []
    );

    const permissionsMap = Object.keys(defaultAccesses).map((name, index) => (
        <div key={index} >
            <h4>{name}</h4>
            <DataTable
                dense
                striped
                highlightOnHover
                columns={columns}
                data={defaultAccesses[name]}
                onSelectedRowsChange={(row) => {
                    updateState(name, row.selectedRows)
                }}
                selectableRows={true}
                selectableRowSelected={(row) => selected[name] && selected[name].includes(row.name)}
            />
        </div>
    ))

    useEffect(() => {
        getAdminAccesses({ appid })
            .then((data) => {
                return data.accesses;
            })
            .then(setDefaultAccesses);
    }, []);

    return (
        <div>
            <br />
            <h2>Permissions</h2>
            <hr />
            {permissionsMap}
            <br />
        </div>
    );
};

export default Permissions;
