import Honkio from "../Honkio";
import {
    PAYMENT_FAIL_URL,
    PAYMENT_SUCCESS_URL,
    PAYMENT_THANK_YOU_URL,
} from "../../config";

const getPayerauthenticate = (id, oId, shopId, datas, errorHandler = {}) => {
    return Honkio().mainShop.userFetch(
        "orderstatusupdate",
        {
            shop: shopId,
            merchant_id: id,
            version: 1,
            command: "orderstatusupdate",
            user_info: {
                email: datas.Email,
                phone: datas.phone,
            },
            role: null,
            order_id: oId,
            success_url: PAYMENT_SUCCESS_URL,
            fail_url: PAYMENT_FAIL_URL,
            token: "123",
            redirect_url: PAYMENT_THANK_YOU_URL,
        },
        errorHandler
    );
};

export { getPayerauthenticate };
