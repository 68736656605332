import { Link } from "react-router-dom";
import AuthPanel from "./AuthPanel";
import LanguageSelector from "./LanguageSelector";
import LogoImg from "../../images/logo.png";

const Navbar = () => (
    <nav className="navbar w-100 p-0 shadow-sm">
        <div className="container-fluid">
            <Link className="navbar-brand mr-auto" to="/dashboard">
                <img
                    alt=""
                    src={LogoImg}
                    height={48}
                    className="d-inline-block align-top mr-2 logo-medium bg-primary"
                />
            </Link>
            <LanguageSelector />
            <AuthPanel className="ml-auto" />
        </div>
    </nav>
);

export default Navbar;
