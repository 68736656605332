import SettingsLink from './SettingsLink';

const Settings = () => {
  return (
    <div className="container">
      <div className="row row-cols-2">
        <SettingsLink to="/products/types">Product types</SettingsLink>
        <SettingsLink to="/servicekeys">Service keys</SettingsLink>
        <SettingsLink to="/password">Security</SettingsLink>
      </div>
    </div>
  );
};

export default Settings;
