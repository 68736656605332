const HiddenCheckboxes = ({ name, items, register }) => {
  return items.map((item, index) => (
    <input
      ref={register}
      key={index}
      id={name}
      name={name}
      type="checkbox"
      checked
      value={item}
      readOnly
      hidden
    />
  ));
};
export default HiddenCheckboxes;
