import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getMerchantData from '../../api/utils/getMerchantData';
import MerchantInfoForm from './MerchantInfoForm';
import getMerchantInvoices from '../../api/utils/getMerchantInvoices';
import payments from '../../__fixtures__/payments';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import { NotificationManager, NotificationContainer } from "react-notifications";

import qs from 'qs';

const MerchantInfo = () => {
    const [merchantInfo, setMerchantInfo] = useState(null);
    const { merchant } = qs.parse(useLocation().search, {
        ignoreQueryPrefix: true,
    });
    const [progress, done] = useLoader([merchantInfo]);
    //const [payments, setPayments] = useState({});
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        if (merchant) {
            fetchMerchant()
        }
    }, [merchant]);

    const fetchMerchant = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept' && data.merchant) {
                    setMerchantInfo(data.merchant)
                } else {
                    setMerchantInfo({})
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setMerchantInfo({})
        }

        getMerchantData({ id: merchant }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    }

/*     useEffect(() => {
        getMerchantData({ id: merchant })
            .then(setMerchantInfo);
        getMerchantInvoices({ merchant: merchant })
          .then(({ payment_invoices: payments }) => payments)
          .then(setPayments);
    }, [merchant]); */
    return done ? (
        merchantInfo?._id ?
            <div>
                <MerchantInfoForm payments={payments} merchantInfo={merchantInfo} />
            </div> : 'not found'
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default MerchantInfo;
