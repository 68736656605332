const defaultMargin = { top: 30, right: 30, bottom: 30, left: 30 };

const getCanvas = (node, { width, height, margin = defaultMargin }) => {
  const { clientWidth, clientHeight } = node;
  return {
    node,
    height: height ?? clientHeight,
    width: width ?? clientWidth,
    margin,
  };
};

export default getCanvas;
