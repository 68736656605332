import { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import SmartForm from "./../../Form/SmartForm";
import InputFieldWithValidation from "./../../Form/elements/InputFieldWithValidation";

const MaintenanceModal = ({ order, show, onHide, onSubmit, loading }) => {
    const buttonRef = useRef(null);

    const handleSubmitClick = () => {
        buttonRef.current.click();
    };

    const onClickHide = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Set up information for maintenance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SmartForm onSubmit={onSubmit} defaultValues={order}>
                    <label className="multiline">{order.title}</label>
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="description"
                        label="Describe the maintenance"
                        required={true}
                        type="text"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="start_date"
                        label="Start maintenance"
                        required={true}
                        type="date"
                        additional="this date is required"
                    />
                    <InputFieldWithValidation
                        className="col-md-12"
                        name="end_date"
                        label="Possible end maintenance"
                        type="date"
                        required={true}
                        additional="this date is required"
                    />
                    <button
                        color="secondary"
                        type="submit"
                        ref={buttonRef}
                        style={{ display: "none" }}
                    ></button>
                </SmartForm>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={loading}
                    onClick={handleSubmitClick}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MaintenanceModal;
