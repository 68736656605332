import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Wait from './../../../UI/Wait';
import { Link } from 'react-router-dom';
import Icon from './../../../UI/Icon';
import ReactTooltip from 'react-tooltip';
import { IconButton } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const AssetSearchResultList = ({ data, loading, emptyText, onReserveClick = () => { } }) => {

    const columns = useMemo(
        () => [
            { name: 'Name', grow: 2, cell: ({ name }) => name },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: (row) => (
                    <div>
                        <Link to={`/assets/edit?asset=${row._id}&merchant=${row.merchant}`} data-tip data-for="editTip">
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip id="editTip" place="top" effect="solid">Edit Trailer</ReactTooltip>
                        </Link>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onReserveClick(row)}data-tip data-for="reserveTip" >
                            <ArrowCircleRightIcon />
                            <ReactTooltip id="reserveTip" place="top" effect="solid">Reserve</ReactTooltip>
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100]}
            progressPending={loading}
            noDataComponent={emptyText}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default AssetSearchResultList;
