import * as d3 from 'd3';

const BarChart = (data, { node, height, width, margin }) => {
  const { payments, merchants: allMerchants } = data;

  margin.right = 200;
  const x = d3
    .scaleBand()
    .domain(payments.map(({ timestamp }) => timestamp))
    .rangeRound([margin.left, width - margin.right])
    .padding(0.1);

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(payments.map(({ total }) => total))])
    .range([height - margin.bottom, margin.top]);

  const xAxis = (g) =>
    g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));

  const yAxis = (g) =>
    g
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickFormat((value) => `$${value / 1000}k`))
      .call((g) => g.select('.domain').remove());

  const merchants = [
    ...new Set(payments.flatMap((period) => Object.keys(period))),
  ].filter((merchant) => !['total', 'timestamp'].includes(merchant));

  if (merchants.length === 0) {
    console.group('Barchart');
    console.error('Bad data to display');
    console.log(data);
    return;
  }

  const series = d3.stack().keys(merchants).order(d3.stackOrderDescending)(
    payments
  );
  console.log(series);

  const orderedMerchants = series.reduce((carry, el) => {
    const { index, key } = el;
    carry[index] = key;
    return carry;
  }, []);

  const color = d3
    .scaleOrdinal()
    .domain(orderedMerchants)
    .range(d3.schemeSpectral[series.length])
    .unknown('#ccc');

  const yTitle = (g) =>
    g
      .append('text')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .attr('y', 10)
      .text('↑ Payments');

  const legend = (g) => {
    const l = g
      .selectAll('g')
      .data(orderedMerchants.slice().reverse())
      .join('g')
      .attr(
        'transform',
        (d, i) =>
          `translate(${width - margin.right + 20}, ${20 + margin.top + 18 * i})`
      );
    l.append('rect')
      .attr('y', -10)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', (d) => color(d));
    l.append('text')
      .attr('x', 20)
      .text((d) => allMerchants[d].name);
  };

  const canvas = d3.select(node);
  canvas
    .selectAll('g')
    .data(series)
    .join('g')
    .attr('fill', ({ key }) => color(key))
    .selectAll('rect')
    .data((d) => d)
    .join('rect')
    .attr('x', (d) => x(d.data.timestamp))
    .attr('y', (d) => y(d[1]))
    .attr('width', x.bandwidth())
    .attr('height', (d) => y(d[0]) - y(d[1]));

  canvas.append('g').call(xAxis);

  canvas.append('g').call(yAxis);

  canvas.append('g').call(legend);

  canvas.call(yTitle);
};

export default BarChart;
