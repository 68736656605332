import clsx from 'clsx';
import { forwardRef } from 'react';

const AnySelector = forwardRef(
  (
    {
      label = 'selector',
      name = 'selector',
      items = [],
      setItem = () => {},
      valueSelector = '',
      nameSelector = '',
      defaultValue,
      resetItem,
      value,
      className,
    },
    ref
  ) => {
    return (
      <select
        ref={ref}
        className={clsx('custom-select w-auto', className)}
        name={name}
        onChange={({ target: { value } }) => setItem(value)}
        defaultValue={defaultValue}
        value={value}
      >
        <option className="d-none">{label}</option>
        {resetItem && (
          <option key="reset" value={resetItem.value}>
            {resetItem.name}
          </option>
        )}
        {items.map((item, index) => (
          <option key={index} value={item?.[valueSelector] ?? item}>
            {item?.[nameSelector] ?? item}
          </option>
        ))}
      </select>
    );
  }
);

export default AnySelector;
